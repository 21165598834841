
const DEFAULT_SUCCESS = 'Erfolgreich!'
const DEFAULT_FAIL = 'Der Vorgang konnte nicht ausgeführt werden'

const LOADING = 'Laden...'

export default {
    DEFAULT_SUCCESS,
    DEFAULT_FAIL,
    LOADING
}