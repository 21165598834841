import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { show, setData } from "../../../../features/popup.window.js";
import useSendFeedback from "../../../hooks/sendQuery/sendQuery.jsx";
import { HorizontalMenu } from "../../_menus/HorisontalMenu/HorisontalMenu.jsx";
import { AsideTypesMenu } from "../../_menus/AsideTypesMenu/AsideTypesMenu.jsx";
import { Container } from "../../_layouts/Container/Container.jsx";
import { FeedbackForm } from "../../_forms/FeedbackForm/FeedbackForm.jsx";
import constants from "./constants";
import utils from "../../../utils";
import styles from "./header.module.scss";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sendingResult, submitFunction } = useSendFeedback();
  // const { user } = useSelector((state) => state.user);
  const [menu, setMenu] = useState(constants.MENU);
  const [avatar, setAvatar] = useState(constants.MENU[1].icon.src);

  const schowFeedbackForm = () => {
    setSupportFormData();
    dispatch(show());
  };

  const redirectToLink = (link) => {
    navigate(link);
    return <Navigate to={link} />;
  };

  let feedbackForm = (
    <FeedbackForm
      submitFunction={submitFunction}
      sendingResult={sendingResult}
    />
  );

  const setSupportFormData = () => {
    const payload = {
      title: constants.SUPPORT_POPUP_ITILE,
      message: feedbackForm,
      buttons: [],
      classes: {
        container: styles.feedbaclFormContainer,
        title: styles.feedbackFormTitle,
      },
    };
    dispatch(setData(payload));
  };

  useEffect(() => {
    feedbackForm = (
      <FeedbackForm
        submitFunction={submitFunction}
        sendingResult={sendingResult}
      />
    );

    setSupportFormData();
  }, [sendingResult]);

  useEffect(() => {
    const avatarImage = utils.user.getAvatar()?.src
      ? utils.user.getAvatar().src
      : utils.user.getAvatar();
    utils.mediafiles.checkImageLoading(
      avatarImage,
      constants.MENU[1].icon.src,
      setAvatar
    );
    setMenu([
      {
        ...menu[0],
        onClick: () => {
          return redirectToLink(menu[0].link);
        },
      },
      {
        ...menu[1],
        icon: avatarImage instanceof String ? avatarImage : avatar,
      },
      {
        ...menu[2],
        onClick: () => {
          return redirectToLink(menu[2].link);
        },
      },
      { ...menu[3], onClick: schowFeedbackForm },
    ]);
  }, []);

  return (
    <motion.header className="no-print">
      <AsideTypesMenu />
      <Container className={styles.Container}>
        <HorizontalMenu items={menu} />
      </Container>
    </motion.header>
  );
};
