
const EDIT_FIELD_PLACEHOLDER = 'Platz für die Texteingabe'
const EDITOR_SECTION_SETTINGS = {
    modules: {
      toolbar: {
        container: '#toolbar',
      },
    },
    formats: [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
    ],
    theme: 'snow',
    placeholder: EDIT_FIELD_PLACEHOLDER,
    selection: { start: 0, end: 0 }
  
  }

  

  const HINTS = {
    boldText: "Bold",
    italicText: "Italic",
    underlineText: "Underline",
    strikeText: "Strike",
    orderedList: "Ordentliche Liste",
    bulletList: "Aufzählung",
    reduceIndent: "Einzug verkleinern",
    increaseIndent: "Einzug vergrößern",
    link: "In einen Link umwandeln",
    image: "Ein Bild hinzufügen",
    clear: "Klartext-Format"
  }




  const PROLOCAL_LIST = ['http', 'https', 'mailto', 'tel', 'sms'];
  export default {
    EDITOR_SECTION_SETTINGS,
    HINTS,
    EDIT_FIELD_PLACEHOLDER,
    PROLOCAL_LIST
  }