import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isShown : false,
    title: '',
    subtitle: '',
    message: '',
    buttons : [],
    timer: 0,
    classes: [],
    shade: true,
    darkMode: false
};

const popUpSlice = createSlice({
  name: "popupWindow",
  initialState,
  reducers: {
    show: (state) => {
      state.isShown = true;
    },
    hide: (state)=>{
        state.isShown = false;
    },
    setData: (state, action) => {
       state.title = action.payload.title ? action.payload.title : state.title;
       state.message = action.payload.message ? action.payload.message : '';
       state.buttons = action.payload.buttons && Array.isArray(action.payload.buttons) ? action.payload.buttons : []
       state.subtitle = action.payload.subtitle ? action.payload.subtitle : ''; 
       state.timer = action.payload.timer ? action.payload.timer  : 0;
       state.classes = action.payload.classes && Object.keys(action.payload.classes).length ? action.payload.classes  : []; 
       state.shade = typeof action.payload.shade == 'boolean' ? action.payload.shade : true;
       state.darkMode = typeof action.payload.darkMode == 'boolean' ? action.payload.darkMode : false;
    },
  },
});

const { reducer, actions } = popUpSlice;

export const { show, hide, setData } = actions
export default reducer;

