import axios from "axios";
import environment from "../constants/environment";

const standart =  axios.create({
    baseURL: environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]?.URL_STANDART
})


const custom = axios.create({
    baseURL: environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]?.URL_CUSTOM
});


export default {
    standart,
    custom
}