import { motion } from "framer-motion";
import constants from "../constants";
import styles from "../footer.module.scss";

export const FooterImage = ({image})=>{
    return (
        <motion.div
        className={styles.logoContainer}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.img
          src={image.src}
          alt={image.alt}
          variants={constants.CARD_VARIANTS}
        />
      </motion.div>
    )
}