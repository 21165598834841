import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { UserForm } from "../../shared/components/_forms/UserForm/UserForm";
import { resetPassword } from "../../features/auth.slice";
import { setMessage } from "../../features/message";
import { userResetPasswordFormSchems } from "../../schemes/index";
import useRedirects from "../../shared/hooks/redirects/redirects";
import constants from "./constants";

export const ResetPasswordPage = () => {
  const [succesResult, setSuccesResult] = useState(false);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const dispatch = useDispatch();
  const { toHomepage } = useRedirects();

  const handleResetPassword = (formValue) => {
    setLoadingInProcess(true);
    const { username, useremail, password } = formValue;

    dispatch(resetPassword({ username : username?.trim() , useremail : useremail?.trim(), password : password?.trim() }))
      .unwrap()
      .then(
        (data) => {
          console.log(data);
          if (data && data.code && data.code == "accaout_updated") {
            formValue.username = constants.DEFAULT_VALUE;
            setSuccesResult(true);
            dispatch(setMessage(constants.SUCCESS_MESSAGE));
          }
          setLoadingInProcess(false);
        },
        (reason) => {
          console.log(reason);
          setSuccesResult(false);
          setLoadingInProcess(false);
        }
      );
  };

  if (toHomepage()) {
    return toHomepage();
  }

  return (
    <UserForm
      handler={handleResetPassword}
      fields={constants.FIELDS}
      buttonName={constants.BUTTON_NAME}
      verificationSheme={userResetPasswordFormSchems}
      succesResult={succesResult}
      loadingInProcess={loadingInProcess}
      setLoadingInProcess={setLoadingInProcess}
    >
      {constants.LINKS &&
        constants.LINKS.map((link) => (
          <motion.div dangerouslySetInnerHTML={{ __html: link }} />
        ))}
    </UserForm>
  );
};
