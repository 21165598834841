import environment from "../../constants/environment"

const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

export const MIN_HEIGHT = "100vh"
export const NOT_FOUND_TEXT  = "Die Seite wurde nicht gefunden"
export const NOT_FOUND_TITLE = "404"

export default {
    MIN_HEIGHT,
    NOT_FOUND_TEXT,
    NOT_FOUND_TITLE,
    THEME_CLASS
}