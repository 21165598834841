import environment from '../../../../../constants/environment'
import images from '../../../../../assets/images'
import global from '../../../../../constants/global'

const VERSION = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]
.VERSION

export const EDIT_ICON = {
    src: images.guidline.light_gray_edit,
    alt: "edit icon" 
}

export const SAVE_PDF = {
    src: images.guidline.light_gray_print,
    alt: "pdf"
}

export const PRINT_BUTTON_ICON = {
  src:  images.guidline.light_gray_print,
  alt: "print"
}

export const PLUG = {
    src: images.imagePlug,
    alt: "plug"
}

export const SHARE = {
    src: images.guidline[`share_icon_${VERSION}`],
    alt: 'shere by email'
}

const EDIT_HINT_SIGN = "Artikel bearbeiten"

const SHERE_LINK_ARTICLE = 'Artikel per e-mail teilen'

const WIKI_TITLE = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].TITLE ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].TITLE : "WIKI"

const MAIL_TITILE = `Interessanter%20Artikel%20auf%20${WIKI_TITLE}`

const MAIL_BODY = `Guten%20Tag,%0D%0Aich%20teile%20diese%20Veröffentlichung%20auf%20${WIKI_TITLE}%20mit%20Ihnen:%0D%0A%0D%0A${window.location.href}%0D%0A%0D%0A==================================%0D%0ADiese%20E-Mail%20wurde%20automatisch%20generiert.`

const SHERE_HREF_VALUE=`mailto:?subject=${MAIL_TITILE}&amp&body=${MAIL_BODY}`

const PRINT_BUTTON_LABEL = "Drucken";

const EDITOR_ROLES = global.EDITOR_ROLES

const EDIT_BUTTON_TEXT = 'Bearbeiten'

export default {
    EDIT_ICON,
    PLUG,
    SAVE_PDF,
    EDIT_HINT_SIGN,
    SHARE,
    SHERE_LINK_ARTICLE,
    MAIL_TITILE,
    WIKI_TITLE,
    MAIL_BODY,
    SHERE_HREF_VALUE,
    PRINT_BUTTON_ICON,
    PRINT_BUTTON_LABEL,
    EDITOR_ROLES,
    EDIT_BUTTON_TEXT
}
