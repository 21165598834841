import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { List } from "../List/List";
import PropTypes from "prop-types";
import constants from "./constants";
import utils from "../../../utils";
import styles from "./listitem.module.scss";

const ListItem = ({
  title,
  link,
  submenu,
  index,
  onClick,
  visibleSubmenu,
  descriptiveText,
  selectionString,
}) => {
  const selectVisibleMenu = (e) => {
    e.stopPropagation();
    const selectedIndex = visibleSubmenu == index ? -1 : index;
    onClick(selectedIndex);
  };

  return (
    <>
      <motion.div
        onClick={selectVisibleMenu}
        className={classNames(
          styles.submenu__item,
          submenu && index == visibleSubmenu ? styles.active : ""
        )}
      >
        {submenu && submenu.length > 0 ? (
          <>
            <motion.div
              className={classNames(
                styles.icon,
                visibleSubmenu != index ? styles.opened : ""
              )}
            >
              <img
                src={constants.ICON_OPEN.src}
                alt={constants.ICON_OPEN.alt}
              />
            </motion.div>
            <motion.div
              className={styles.link}
              onClick={selectVisibleMenu}
              dangerouslySetInnerHTML={{
                __html: utils.text.searchResultHilighting(
                  selectionString,
                  title
                ),
              }}
            />
          </>
        ) : (
          <motion.div>
            <motion.div>
              <Link
                className={styles.link}
                to={link}
                dangerouslySetInnerHTML={{
                  __html: utils.text.searchResultHilighting(
                    selectionString,
                    title
                  ),
                }}
              />
            </motion.div>
            {descriptiveText.length > 0 && (
              <motion.div
                className={styles.searchDescription}
                dangerouslySetInnerHTML={{
                  __html: `...${utils.text.searchResultHilighting(
                    selectionString,
                    descriptiveText
                  )}`,
                }}
              />
            )}
          </motion.div>
        )}
      </motion.div>
      {submenu && submenu.length > 0 && (
        <List items={submenu} submenuVisible={visibleSubmenu == index} selectionString={selectionString}/>
      )}
    </>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  submenu: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  onClick: PropTypes.func,
  visibleSubmenu: PropTypes.number,
  descriptiveText: PropTypes.string,
  selectionString: PropTypes.string,
};

ListItem.defaultProps = {
  submenu: [],
  index: 0,
  descriptiveText: "",
  selectionString: "",
};

export { ListItem };
