import PropTypes from "prop-types";
import classNames from "classnames";
import { motion } from "framer-motion";
import { SaveToFavoritesButton } from "../../Buttons/SaveToFavoritesButton/SaveToFavoritesButton";
import { StandartButton } from "../../Buttons/StandartButton/StandartButton";
import styles from "./cardtext.module.scss";

const CardTextArea = ({
  title,
  tagList,
  author,
  postId,
  userId,
  data,
  preview,
}) => {
  return (
    <motion.div className={classNames(styles.textContainer)}>
      {author && (
        <motion.div className={styles.authorContainer}>
          <motion.div className={styles.autorImageContainer}>
            <img src={author.avatar.src} alt={author.avatar.alt} />
          </motion.div>
          <motion.div className={styles.autorName}>
            <span>{author.name}</span>
          </motion.div>
        </motion.div>
      )}
      <motion.div className={styles.titleContainer}>
        <motion.h3>{title}</motion.h3>
      </motion.div>
      <motion.div className={styles.previewText}>{preview}</motion.div>
      <motion.div className={styles.actions}>
        {data && <motion.div className={styles.data}>{data}</motion.div>}

        {tagList.length > 0 && (
          <motion.div className={styles.tagsContaioner}>
            {tagList.map((tag, index) => (
              <motion.div className={styles.buttonItem}>
                <StandartButton
                  key={index}
                  title={tag}
                  onClick={() => {}}
                  mode={"normal"}
                  rounded={true}
                />
              </motion.div>
            ))}
          </motion.div>
        )}
        {userId && postId && (
          <motion.div className={styles.saveToFavContainer}>
            <SaveToFavoritesButton userId={userId} postId={postId} />
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};

CardTextArea.propTypes = {
  postId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  author: PropTypes.oneOfType([PropTypes.shape({
    avatar: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
    name: PropTypes.string,
  }), PropTypes.string]),
  title: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  tagList: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.string,
};

CardTextArea.defaultProps = {
  author: "",
  tagList: [],
  userId: undefined,
  postId: undefined,
};

export { CardTextArea };
