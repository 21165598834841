import environment from "../../constants/environment"
import global from "../../constants/global"
import images from "../../assets/images"


const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

const TABS = [
    {
        key: 1,
        //label: "Gespeicherte Entwürfe",
        label: "Entwürfe"
    },
    {
        key: 2,
        //label: "Veröffentlichte Artikel"
        label: "Veröffentlichungen"
    },
    {
        key: 3,
        label: "Zur Mäßigung"
    }
]

const EDITOR_ROLES = global.EDITOR_ROLES

const PUBLICATION_OF_OTHERS = {
    name: 'Benutzerpublikationen',
    key: 4
}




export default {
    TABS,
    THEME_CLASS,
    EDITOR_ROLES,
    PUBLICATION_OF_OTHERS,
}