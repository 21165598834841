import { motion } from "framer-motion";
import { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Row, Col } from "antd";
import { BaseSubmitButton } from "../../Buttons/BaseSubmitButton/BaseSubmitButton";
import { feedbackFormSchems } from "../../../../schemes/index";
import { SendingResult } from "../SendingResult/SendingResult";
import { DataList } from "../../VerticalList/DataList/DataList";
import { FormField } from "../../TextField/FormField/FormField";
import api from "../../../../api/server.api";
import crud from "../../../../api/crud";
import constants from "./constnats";
import styles from "./feedbackform.module.scss";
// import { Hint } from "../../_sections/EditArticleSection/components/Hint/Hint";
import { InfoCard } from "../../Cards/InfoCard/InfoCard";

const FeedbackForm = ({
  submitFunction,
  buttonText,
  sendingResult,
  widthMode,
}) => {
  const [selectorRecipientValue, setSelectorRecipientValue] = useState(
    constants.ROLES_LIST[0].name
  );
  const [selectRecipientDescription, setSelectorRecipientDescription] =
    useState(constants.ROLES_LIST[0].description);
  const sendFeedback = (values) => {
    const { name, message, email, recipient } = values;
    const sendVals = {
      title: JSON.stringify({
        name: name,
        email: email,
        recipient: recipient,
      }),
      content: message,
      fields: {
        "your-email": email,
        recipient: recipient,
      },
      status: "publish",
    };
    submitFunction(
      sendVals,
      api.standart,
      crud.standart.FEEDBACKS,
      () => {},
      () => {},
      {
        success: constants.SECCESS_FEEDBACK_SENT,
        error: constants.FAIL_FEEDBACK_SENT,
      }
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: "",
          recipient: "wiki_moderator",
        }}
        validationSchema={feedbackFormSchems}
        onSubmit={sendFeedback}
      >
        {(props) => {
          const chooseRecipentHandler = (value) => {
            props.setFieldValue("recipient", value);
            const getdescription = constants.ROLES_LIST.filter(
              (role) => role.id == value
            );
            setSelectorRecipientDescription(getdescription[0].description);
          };
          return (
            <Form className={styles.feedbackForm}>
              <Row>
                <Col md={widthMode ? 12 : 24}>
                  <DataList
                    valueList={constants.ROLES_LIST}
                    selectorValue={selectorRecipientValue}
                    setSelectorValue={setSelectorRecipientValue}
                    disabled={false}
                    className={styles.datList}
                    selectHandler={chooseRecipentHandler}
                  />
                </Col>
                <Col md={widthMode ? 12 : 24}>
                  <InfoCard
                    text={selectRecipientDescription}
                    classes={widthMode ? styles.descriptioContainer : ""}
                    infoIcon={widthMode ? true : false}
                  />
                </Col>
                <Col md={24} className={widthMode ? styles.marginFields : ""}>
                  <FormField
                    type="text"
                    name="recipient"
                    id="recipient"
                    classes={styles.invisible}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  span={widthMode ? 12 : 11}
                >
                  <motion.div className={widthMode ? styles.bitMarginLeft : ''}>
                  <FormField
                    type="text"
                    name="name"
                    id="name"
                    placeholder={constants.FIELDS_TEXT.name}
                  />
                  {props.errors.name && (
                    <motion.div
                      className={classNames("input-error", styles.errors)}
                    >
                      <span>{props.errors.name}</span>
                    </motion.div>
                  )}
                  </motion.div>
                </Col>
                <Col span={widthMode ? "" : 2}></Col>
                <Col
                  span={widthMode ? 12 : 11}
                >
                  <motion.div className={widthMode ? styles.bitMarginRight : ''}>
                  <FormField
                    type="email"
                    name="email"
                    id="email"
                    placeholder={constants.FIELDS_TEXT.email}
                  />
                  {props.errors.email && (
                    <motion.div
                      className={classNames("input-error", styles.errors)}
                    >
                      <span>{props.errors.email}</span>
                    </motion.div>
                  )}
                  </motion.div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormField
                    as="textarea"
                    name="message"
                    id="message"
                    placeholder={constants.FIELDS_TEXT.message}
                  />
                  {props.errors.question && (
                    <motion.div
                      className={classNames("input-error", styles.errors)}
                    >
                      <span>{props.errors.question}</span>
                    </motion.div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <BaseSubmitButton mode={widthMode ? "outlined" : "filled"} title={buttonText} />
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <SendingResult sendingResult={sendingResult} />
    </>
  );
};

FeedbackForm.propTypes = {
  submitFunction: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  sendingResult: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  widthMode: PropTypes.bool,
};

FeedbackForm.defaultProps = {
  submitFunction: () => {},
  buttonText: constants.DEFAULT_BUTTON_TEXT,
  sendingResult: "",
  widthMode: false,
};

export { FeedbackForm };
