import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Container } from "../../../_layouts/Container/Container";
import { StandartButton } from "../../../Buttons/StandartButton/StandartButton";
import constants from "./constants";
import utils from "../../../../utils";
import styles from "./articlesection.module.scss";

export const ArticleSection = ({
  post,
  containerClasses = "",
  children,
  inEdit = false,
  printAvalible = true,
}) => {
  const navigate = useNavigate();
  const [previewHeight, setPreviewHeight] = useState(0);

  const editArticle = () => {
    if (post.id) {
      navigate(`/posts/edit/${post.id}`);
    }
  };

  const hasTileCheck = (post) => {
    return post && post.title && post.title.rendered;
  };

  const hasPreiewImageChack = (post) => {
    if (typeof post?.acf?.preview_image == "undefined") {
      return false;
    }
    if (
      typeof post?.acf?.preview_image == "string" &&
      post?.acf?.preview_image.length
    ) {
      return true;
    }
    return (
      post && post?.acf && post?.acf?.preview_image && post?.acf?.preview_image.src
    );
  };

  const hasContentCheck = (post) => {
    return (
      post &&
      post.content &&
      post.content.rendered &&
      Array.isArray(post.content.rendered)
    );
  };

  const print = () => {
    window.print();
  };
  useEffect(() => {
    setTimeout(() => {
      calculatePreviewHeight();
    }, 500);
  }, []);

  const getPreiewImage = () => {
    const image =
      typeof post?.acf?.preview_image === "string"
        ? JSON.parse(post?.acf?.preview_image).src
        : post?.acf?.preview_image?.src;
    return image;
  };

  const calculatePreviewHeight = () => {
    const image = document.querySelector(`.${styles.mainImage}`);
    if (image) {
      const heightImg = image.getBoundingClientRect().height;
      console.log(heightImg);
      setPreviewHeight(heightImg);
    }
  };

  return (
    <>
      <Container className={containerClasses}>
        <motion.div className={styles.titleBlock}>
          <h1>
            {hasTileCheck(post)
              ? utils.post.decodeHtmlCharCodes(
                  utils.post.germanToUppercase(post?.title?.rendered)
                )
              : ""}
          </h1>
          {children}
          {(utils.post.isAuthorCheck(post) ||
            constants.EDITOR_ROLES.includes(utils?.user?.getUserData()?.role)) &&
            inEdit && (
              <StandartButton
                title={constants.EDIT_BUTTON_TEXT}
                mode={"grayOutlined"}
                imageBefore={constants.EDIT_ICON}
                classes={classNames(styles.buttonTools, "no-print")}
                onClick={editArticle}
              />
            )}
          {printAvalible && (
            <StandartButton
              title={constants.PRINT_BUTTON_LABEL}
              mode={"grayOutlined"}
              imageBefore={constants.PRINT_BUTTON_ICON}
              classes={classNames(styles.buttonTools, "no-print")}
              onClick={print}
            />
          )}
        </motion.div>
        <div>
          <div className={styles.PreviewBlock}>
            {/* {post.excerpt && post.excerpt.rendered.length > 1 && ( */}
              <motion.div
                className={classNames(
                  styles.cardViewContainer,
                  styles.noMargin,
                  styles.previewTextContainer,
                  hasPreiewImageChack(post) ? styles.withMargin : ""
                )}
              >
                <motion.div
                  className={classNames(
                    styles.cardOfItem,
                    styles.darkMode,
                    styles.previewText,
                    hasPreiewImageChack(post) ? styles.withMinHeight : "",
                    "preview-height"
                  )}
                  role="previewText"
                  style={{
                    height: previewHeight > 0 ? `${previewHeight}px` : "auto",
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: utils.text.removerBreakes(
                        utils.post.decodeHtmlCharCodes(
                          post?.excerpt?.rendered?.length > 1
                            ? post?.excerpt?.rendered
                            : ""
                        )
                      ),
                    }}
                  />
                  <p></p>
                </motion.div>
              </motion.div>
            {hasPreiewImageChack(post) != 0 && (
              <motion.div
                role="previewImage"
                className={classNames(styles.mainImage)}
              >
                <motion.img src={getPreiewImage()} alt={"preview image"} />
              </motion.div>
            )}
          </div>
          <motion.div className={styles.contentBlock}>
            {hasContentCheck(post) &&
              post.content.rendered.map((parag, index) => {
                return (
                  <motion.div
                    className={classNames(
                      styles.paragraphContainer,
                      "ql-editor"
                    )}
                    key={`paragraph-${index}`}
                  >
                    <h2
                      className={classNames(styles.title, styles.subTitle)}
                      dangerouslySetInnerHTML={{ __html: parag.title }}
                    />
                    <p
                      className={classNames(styles.title, styles.subTitle)}
                      dangerouslySetInnerHTML={{
                        __html:
                          utils.post.convertSymbolesForCode(parag.text),
                      }}
                    />
                  </motion.div>
                );
              })}
            {inEdit && (
              <motion.div className={styles.sheareLinkConatiner}>
                {/* <motion.a href={`mailto:?subject=${constants.MAIL_TITILE};body=${constants.MAIL_BODY};`} className={styles.sheareLinkItem}> */}
                <motion.a
                  href={constants.SHERE_HREF_VALUE}
                  className={styles.sheareLinkItem + " no-print"}
                >
                  <span>{constants.SHERE_LINK_ARTICLE}</span>
                  <img
                    src={constants.SHARE.src}
                    alt={constants.SHARE.alt}
                    loading="lazy"
                  />
                </motion.a>
              </motion.div>
            )}
          </motion.div>
        </div>
      </Container>
    </>
  );
};
