import { motion } from "framer-motion";
import { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import constants from "./constants";
import utils from "../../../utils";
import styles from "./datalist.module.scss";
const DataList = ({
  valueList,
  selectHandler,
  disabled,
  selectorValue,
  setSelectorValue,
  className,
}) => {
  const [opened, setOpened] = useState(false);
  const changeValue = (e) => {
    const value = e.target.getAttribute("value");
    selectHandler(value);
    setSelectorValue(e.target.innerHTML);
    openList();
  };

  const openList = () => {
    setOpened(!opened);
  };

  return (
    <motion.div className={classNames(styles.dataListContainer, className)}>
      <motion.div
        className={classNames(
          styles.disabler,
          disabled ? styles.activated : ""
        )}
      ></motion.div>
      {valueList.length > 1 && (
        <motion.div
          className={classNames(styles.selector, styles.selectorContainer)}
        >
          <motion.div
            onClick={openList}
            className={classNames(
              styles.selectorValue,
              className,
              opened ? styles.opened : ""
            )}
            dangerouslySetInnerHTML={{
              __html: selectorValue,
            }}
          />
          <motion.div
            className={styles.selectorValueList}
            animate={opened ? constants.ANIMATION.SHOW_COLLAPSE : constants.ANIMATION.HIDE_COLLAPSE}
          >
            {valueList.map((val) => {
              return (
                <motion.div
                  onClick={changeValue}
                  key={`option-${val.id}`}
                  value={val.id}
                  className={classNames(styles.selectorItem, className)}
                >
                  {utils.post.decodeHtmlCharCodes(val.name)}
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>
      )}
    </motion.div>
  );
};

DataList.propTypes = {
  valueList: PropTypes.arrayOf(PropTypes.object),
  selectHandler: PropTypes.func,
  disabled: PropTypes.bool,
  selectorValue: PropTypes.string.isRequired,
  setSelectorValue: PropTypes.func.isRequired,
  className: PropTypes.string,

}

DataList.defaultProps = {
  valueList: [],
  selectHandler: ()=>{},
  disabled: false,
  className: ''
  
}

export { DataList };
