import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { hide } from "../../../../features/popup.window";
import { useEffect } from "react";
import styles from "./popup.module.scss";
import { StandartButton } from "../../Buttons/StandartButton/StandartButton";

const MainPopupContainer = () => {
  const dispatch = useDispatch();
  const {
    isShown,
    title,
    message,
    buttons,
    subtitle,
    timer,
    classes,
    shade,
    darkMode,
  } = useSelector((state) => state.popup);

  const hideWindow = () => {
    dispatch(hide());
  };

  useEffect(() => {
    if (timer) {
      setTimeout(function () {
        dispatch(hide());
      }, timer);
    }
  }, [timer]);

  return (
    <>
      {isShown && (
        <div
          className={classNames(
            styles.backgroundShade,
            shade ? "" : styles.noShade
          )}
        >
          <div
            className={classNames(
              styles.popupConainer,
              darkMode ? styles.darkMode : "",
              classes?.container
            )}
          >
            <div
              className={classNames(styles.cross, classes?.cross)}
              onClick={hideWindow}
            />
            <div
              className={classNames(
                styles.title,
                styles.titles,
                classes?.title
              )}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            {console.log(title)}
            {subtitle != "" && (
              <div
                className={classNames(
                  styles.subtitle,
                  styles.titles,
                  classes?.cross
                )}
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
              />
            )}
            <motion.div
              className={styles.message}
              // dangerouslySetInnerHTML={{ __html: message }}
            >{message}</motion.div>
            {console.log(message)}
            {buttons && buttons.length > 0 && (
              <div className={styles.buttonsSection}>
                {buttons.map((btn, index) => (
                  <motion.div className={styles.buttons}>
                    <StandartButton
                      title={btn.btnTitle}
                      mode={index == buttons.length - 1 ? "filled" : "normal"}
                      onClick={btn.btnClick}
                    />
                  </motion.div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { MainPopupContainer };
