import images from '../../../../assets/images'
import crud from '../../../../api/crud'
import environment from '../../../../constants/environment';
import {
  TRANSITION_SETTINGS,
  FADEIN_DOWN_ANIMATION,
  FADEOUT_UP_ANIMATION,
} from "../../../../constants/animation";

const FOOTER_LOGO_TOP = {
  src: images.footerLogoTop,
  alt: 'logo top'
}

const FOOTER_LOGO_BOTTOM = {
  src: images.footerLogoBottom,
  alt: 'logo bottom'
}

const EGK_LOGO = {
  src: images.egkLogo,
  alt: 'egk'
}

export const MENU = [
  {
    type: 'Text',
    link: crud.standart.QESTIONS,
    title: 'FAQ',
  },
  {
    type: 'Text',
    link: '/posts/edit',
    title: 'Artikel erstellen',
  },
]

const ONTRON_LOGO = {
  src: images.ontron,
  alt: 'ontron'
}

const PLOT_A_LOGO = {
  src: images.plotALogo,
  alt: 'plot A'
}

const CURRENT_ENV = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].FOOTER

export const CARD_VARIANTS = {
  offscreen: FADEOUT_UP_ANIMATION,
  onscreen: {
    ...FADEIN_DOWN_ANIMATION,
    transition: TRANSITION_SETTINGS,
  }
};

export default {
  CURRENT_ENV,
  ONTRON_LOGO,
  CARD_VARIANTS,
  FOOTER_LOGO_BOTTOM,
  FOOTER_LOGO_TOP,
  PLOT_A_LOGO,
  EGK_LOGO
}