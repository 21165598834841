import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { FaqPage } from "../../../pages/FaqPage/FaqPage";
import { HomePage } from "../../../pages/HomePage/HomePage";
import { UserPage } from "../../../pages/UserPage/UserPage";
import { PostPage } from "../../../pages/PostPage/PostPage";
import { PostListPage } from "../../../pages/PostsListPage/PostsListPage";
import { LoginPage } from "../../../pages/LoginPage/LoginPage";
import { EditArticlePage } from "../../../pages/EditArticlePage/EditArticlePage";
import { RegisterPage } from "../../../pages/RegisterPage/RegisterPage";
import { ResetPasswordPage } from "../../../pages/ResetPasswordPage/ResetPasswordPage";
import { NotFoundPage } from "../../../pages/NotFoundPage/NotFoundPage";
import { NotAvaliblePage } from "../../../pages/NotAvaliblePage/NotAvaliblePage";
import environment from "../../../constants/environment";
import globalTexts from "../../../constants/globalTexts";


export const Router = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoints = 1092;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);

    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  
  const routesChoose = (routesGroups)=>{
    const defualtRoute = (<Route exact path="*" element={<NotAvaliblePage title={globalTexts.NO_PAGE_TITLE} subtitle={globalTexts.NO_PAGE_SUBTITLE}/>} />)
    if(Array.isArray(routesGroups) && routesGroups.length){
      const routesGroup = routesGroups.reduce((result, group)=>{
        if(group.condition && !result){
          return group.routes
        }
        return result;
      }, false)
      return routesGroup ? routesGroup : defualtRoute
    }
    return defualtRoute
  }

  const routesGroups = [
    {
      condition: width <= breakpoints,
      routes: (<Route exact path="*" element={<NotAvaliblePage />} />)
    },
    {
      condition: typeof environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV] === undefined || typeof environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST] === undefined,
      routes: (<Route exact path="*" element={<NotAvaliblePage title={globalTexts.UNAVAILABLE_RES_TITLE} subtitle={globalTexts.UNAVAILABLE_RES_SUBTITLE} />} />)
    },
    {
      condition: true,
      routes: ( <> 
        { <Route exact path="*" element={<NotFoundPage />} />}
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/register" element={<RegisterPage />} />
        <Route exact path="/reset-password" element={<ResetPasswordPage />} />
        {/* <Route exact path="/reset-password" element={<NotAvaliblePage title="Diese Seite wird derzeit technisch überarbeitet" subtitle="Wenn Sie Ihr Passwort zurücksetzen müssen, wenden Sie sich an den <a href='mailto:yulia.arkadeva@plot-a.eu'>technischen Support</a>" />}/> */}
        <Route exact path="/posts" element={<PostListPage />} />
        <Route exact path="/posts/:slug" element={<PostPage />} />
        <Route exact path="/posts/edit/" element={<EditArticlePage />} />
        <Route exact path="/posts/edit/:id" element={<EditArticlePage />} />
        <Route path="/profile" element={<UserPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route exact path="/404" element={<NotFoundPage />}/>
        </>)
    }
  ]

  return (
    <main>
        <Routes>
        { routesChoose(routesGroups)}
        </Routes> 
    </main>
  );
};
