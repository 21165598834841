import { motion } from "framer-motion";
import classNames from "classnames";
import constants from "./constants";
import styles from "./progressbar.module.scss";

export const Progerssbar = ({ steps, handler, progress, className }) => {
  return (
    <motion.div className={styles.stepsArea + " no-print"}>
      {steps.map((step, index) => {
        return (
          <motion.div
            key={`step-${index}`}
            className={classNames(styles.stepContainer)}
            style={{ width: `${step.progress}%` }}
            step={index}
            onClick={handler}
          >
            <motion.div
              className={classNames(
                styles.stepTitle,
                index == progress ? styles.active : "",
                index >= progress ? className : ""
              )}
            >
              <motion.div className={styles.text}>{step.title}</motion.div>
            </motion.div>
            <motion.div className={styles.progressbarContainer}>
              <motion.div
                className={styles.progress}
                animate={
                  step.done ? constants.STEP_DONE : constants.STEP_INPOGRESS
                }
              ></motion.div>
            </motion.div>
          </motion.div>
        );
      })}
      <motion.div className={classNames(styles.stepContainer)}>
        <motion.div
          className={classNames(
            styles.stepTitle,
            progress == steps.length ? styles.active : ""
          )}
        >
          <motion.div className={styles.text}>
            {constants.DONE_TITLE}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
