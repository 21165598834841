import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Footer } from "../../shared/components/_sections/Footer/Footer";
import { Header } from "../../shared/components/_sections/Header/Header";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { NavSider } from "../../shared/components/_menus/NavSider/NavSider";
import { Container } from "../../shared/components/_layouts/Container/Container";
import useRedirects from "../../shared/hooks/redirects/redirects";
import utils from "../../shared/utils"
import styles from "./postlist.module.scss"

export const PostListPage = () => {
  const [visible, setVisibility] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.user);
  const [menuTree, setmenuTree] = useState([]);
  const { toLoginpage } = useRedirects()

  if (!isLoggedIn) {
    utils.user.setLastPage(window.location.pathname)
    toLoginpage()
  }

  return (
    <DefaultPage>
    <Preloader visible={visible} />
    {!visible && (
      <>
        <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
        <Container
          className={classNames(styles.container)}
        >
          <Header />
          <Footer></Footer>
        </Container>
      </>
    )}
  </DefaultPage>
  );
};
