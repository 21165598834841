export const DEFAULT_VALUE = ''

export const FIELDS = [
    { name: "username", type: "text", placeholder: "Email", default: "" }  
]

export const SUCCESS_MESSAGE = `Ihr Passwort wurde erfolgreich zurückgesetzt. Ein neues Passwort wurde an die von Ihnen hinterlegte E-Mail-Adresse verschickt.`

export const BUTTON_NAME = 'Zurücksetzen'

export const LINKS = [
    `Haben Sie noch kein Benutzerkonto? <a href="/register">Registrieren</a>`,
    `oder gehen Sie zurück zur <a href="/login"> Anmeldeseite</a>`
]

export default {
    DEFAULT_VALUE,
    FIELDS,
    SUCCESS_MESSAGE,
    BUTTON_NAME,
    LINKS
}