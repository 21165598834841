import images from "../../assets/images"
import environment from "../../constants/environment"
import animation from "../../constants/animation";

const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

const BACKGROUND = {
    src: images.backgroundImage,
    alt: 'background'
}

const CARD_VARIANTS = animation.CARD_VARIANTS

const FAQ_TITLE = 'Beliebte Fragen und Antworten'

export default {
    BACKGROUND,
    THEME_CLASS,
    CARD_VARIANTS,
    FAQ_TITLE
}