import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visibility: false,
  content: [],
  extraClick: () => {},
}

const checkContentPlayload = (content) => {
  if (!Array.isArray(content)) return false
  //TODO Add conditions
  return true
}

const asideMenuStatus = createSlice({
  name: 'asideMenuStaus',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.visibility =
        typeof action.payload == 'boolean' ? action.payload : initialState
    },
    setContent: (state, action) => {
      state.content = checkContentPlayload(action.payload) ? action.payload : []
    },
    setExtraClick: (state, action) => {
      state.extraClick =
        typeof action.payload == 'function' ? action.payload : () => {}
    },
  },
})

const { reducer, actions } = asideMenuStatus
export const { setStatus, setContent, setExtraClick } = actions
export default reducer
