import { motion } from "framer-motion";
// import { Row, Col } from "antd";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useFormik } from "formik";
import classNames from "classnames";
import { Container } from "../../_layouts/Container/Container";
import { Progerssbar } from "../../Progressbar/Progressbar";

import { Carousel } from "../../Carousel/Carousel";
import { SelectorSection } from "./components/SelectorSection/SelectorSection";
import { Hint } from "./components/Hint/Hint";
import { InputSection } from "./components/InputSection/InputSection";
import { SectionFileInput } from "./components/SectionFileInput/SectionFileInput";
import { SectionTextArea } from "./components/SectionTextArea/SectionTextArea";
import { Preloader } from "../../Preloader/Preloader";
import { BlockContentSection } from "./components/BlockContentSection/BlockContentSection";
import { InfoCard } from "../../Cards/InfoCard/InfoCard";
import { Row } from "../../_layouts/Row/Row";
import { Col } from "../../_layouts/Col/Col";
import { addArticleSchema } from "../../../../schemes";
import { LinkTitle } from "./components/LinkInput/LinkTitle/LinkTitle";
import { ArticleSection } from "../_global/ArticleSection/ArtilcleSection";
import { SectionFinish } from "./components/SectionFinish/SectionFinish";
import constants from "./constants";
import global from "../../../../constants/global";
import api from "../../../../api/server.api";
import crud from "../../../../api/crud";
import utils from "../../../utils";
import styles from "./editarticle.module.scss";

export const EditArticleSection = ({
  categories,
  setCategories,
  menuReloader,
  thePost,
  isNewPost = false,
}) => {
  const navigate = useNavigate();
  const mainFormId = uuid().slice(0, 16);
  const [currentPost, setCurrentPost] = useState(thePost);
  const [blockList, setBlockList] = useState([]);
  const [steps, setSteps] = useState(constants.STEPS);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const [draftSavedStatus, setDraftSavedStartus] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [addingResult, setAddingResult] = useState(
    constants.ADDING_POST_STATUSES.notLoaded
  );
  const [defaultParent, setDefaultParent] = useState(
    thePost.category ? thePost.category.term_id : 0
  );
  const [showDraftMenu, setShowDraftMenu] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [categorySelectorValue, setCategorySelectorValue] = useState("-");
  const [subCategorySelectorValue, setSubCategorySelectorValue] = useState("-");
  const titleRef = useRef(null);
  const [userEdedPostCondition, setUserEdedPostCondition] = useState(
    currentPost.no_id || currentPost.id
  );
  const [articleId, setArticleId] = useState(
    typeof currentPost.id !== "undefined" ? currentPost.id : 0
  );

  const setStartCategoryValue = (
    fieldName,
    setValueHandler,
    categories = []
  ) => {
    let categoryToSet = currentPost[fieldName]
      ? currentPost[fieldName].name
      : "-";
    if (categories.length && categoryToSet == "-") {
      categoryToSet = categories[0].name;
      const postCategoryId = currentPost?.categories?.[0];
      if (postCategoryId) {
        const postCategory = categories.find(
          (category) => category.id == postCategoryId
        );

        if (postCategory) {
          categoryToSet = postCategory.name;
        }
      }
    }
    setValueHandler(categoryToSet);
  };

  const defaultFormikValues =
    typeof currentPost.id !== "undefined"
      ? {
          category: currentPost.category ? currentPost.category.term_id : 0,
          subcategory: currentPost.subcategory
            ? currentPost.subcategory.term_id
            : 0,
          title: currentPost["title"]["rendered"]
            ? utils.post.decodeHtmlCharCodes(currentPost["title"]["rendered"])
            : "",
          slug: currentPost["slug"],
          image: currentPost["acf"]["preview_image"]
            ? JSON.parse(currentPost["acf"]["preview_image"])
            : 0,
          content: currentPost["content"]["rendered"]
            ? currentPost["content"]["rendered"]
            : "",
          excerpt: currentPost["excerpt"]["rendered"]
            ? currentPost["excerpt"]["rendered"]
            : "",
          links:
            currentPost["acf"]["links"] &&
            currentPost["acf"]["links"].length > 0
              ? currentPost["acf"]["links"]
              : "",
          status: "draft",
          mediafiles: [],
          deletedMediafiles: [],
          progress:
            currentPost.acf && currentPost.acf.progress
              ? currentPost.acf.progress
              : progress,
        }
      : constants.DEFAULT_VALUES;

  const [externalLinks, setExternalLinks] = useState(
    defaultFormikValues.links ? JSON.parse(defaultFormikValues.links) : []
  );
  const LoadSubCategories = async () => {
    const chooseCondition = currentPost.category
      ? currentPost.category.term_id
      : categories.length
      ? categories[0].id
      : 0;

    try {
      await Promise.all([
        utils.api.getData(
          api.standart,
          `${crud.standart.CATEGORIES}?parent=${chooseCondition}&per_page=100`,
          setSubCategories
        ),
      ]);
    } catch (e) {
    } finally {
      categorySelectHandler(chooseCondition);
      setStartCategoryValue(
        "subcategory",
        setSubCategorySelectorValue,
        subCategories
      );
    }
  };

  const deleteImageFormServer = () => {
    if (formik.values.image && formik.values.image.id) {
      utils.mediafiles.deleteMedifile(
        formik.values.image.id,
        () => {},
        () => {}
      );
    }
  };

  const setStepsConditions = () => {
    const configuredSteps = [
      {
        ...steps[0],
        fields: ["title", "image", "excerpt", "content", "links"],
      },
      {
        ...steps[1],
        fields: ["title", "image", "excerpt", "content", "links"],
      },
      {
        ...steps[2],
        fields: ["title", "image", "excerpt", "content", "links"],
      },
    ];
    setSteps(configuredSteps);
  };

  const formik = useFormik({
    initialValues: {
      category: defaultFormikValues.category,
      subcategory: defaultFormikValues.subcategory,
      title: "",
      image: "",
      content: utils.text.adoptBreakets(defaultFormikValues.content),
      excerpt: "",
      links: "",
      status: "draft",
      mediafiles: [],
      deletedMediafiles: [],
      progress: progress,
    },
    validationSchema: addArticleSchema,
  });

  const saveDraft = (val, immediatelyUpdataData = {}) => {
    setLoadingInProcess(true);
    setDraftSavedStartus(1);
    let data = {};
    if (articleId) {
      data.id = articleId;
      if (immediatelyUpdataData && typeof immediatelyUpdataData === "object") {
        data = { ...data, ...immediatelyUpdataData };
      }
    }

    const handler = (data) => {
      if (data.id) {
        setArticleId(data.id);
        if (draftSavedStatus != 2) {
          menuReloader();
        }
        setAddingResult(constants.ADDING_POST_STATUSES.inprosses);
        setDraftSavedStartus(2);
        setLoadingInProcess(false);
        setProgress(val);
        return;
      }
      setLoadingInProcess(false);
      setProgress(val);
    };

    const errorHandler = (error) => {
      console.log(error);
      setDraftSavedStartus(0);
      if (error.includes("String contains non ISO-8859-1 code point")) {
        setGeneralError(constants.INCORRECT_SYMBOLS);
        titleRef.current.scrollIntoView();
      } else if (typeof error === "string") {
        setGeneralError(constants.CANT_SAVE_DATA);
        titleRef.current.scrollIntoView();
      }
      setLoadingInProcess(false);
    };

    const addContent = (newData) => {
      utils.post.addingHandler(
        newData,
        handler,
        formik,
        articleId,
        errorHandler
      );
    };

    const changeImage = (newData) => {
      if (formik.values.image && !formik.values.image.id) {
        if (!formik.values.image.id) {
          const upDateContent = (imageData) => {
            newData.image = { src: imageData.source_url, id: imageData.id };
            changeValue("image", newData.image);
            addContent(newData);
            setLoadingInProcess(false);
          };
          utils.mediafiles.addMediafile(
            formik.values.image,
            upDateContent,
            errorHandler
          );
        } else {
          utils.mediafiles.deleteMedifile(
            formik.values.image.id,
            () => {
              setLoadingInProcess(false);
            },
            errorHandler
          );
        }
      } else {
        addContent(newData);
        setLoadingInProcess(false);
      }
    };
    changeImage(data);
  };

  const goalHandler = () => {
    const data = {
      status:
        utils.user.getUserData().role == constants.ALLOW_PUBLISH
          ? constants.PUBLISH_STATUSES
          : constants.PENDING_STATUSES,
    };
    if (articleId) {
      data.id = articleId;
    }

    const handler = (data) => {
      if (data.id) {
        setDraftSavedStartus(0);
        menuReloader();
        setAddingResult(constants.ADDING_POST_STATUSES.loaded);
      } else {
        setAddingResult(constants.ADDING_POST_STATUSES.notLoaded);
      }
      setLoadingInProcess(false);
    };

    const errorHandler = (error) => {
      setLoadingInProcess(false);
      setAddingResult(constants.ADDING_POST_STATUSES.notLoaded);
    };
    utils.post.addingHandler(data, handler, formik, articleId, errorHandler);
  };

  const checkBegoreGoalHandler = () => {
    goalHandler();
  };

  const isErrorsExists = () => {
    const fieldWithError =
      steps.length > progress && steps[progress].fields
        ? steps[progress].fields.reduce((result, field) => {
            return formik.errors[field] ? field : result;
          }, null)
        : null;

    if (!fieldWithError) {
      setGeneralError("");
      return false;
    }

    setGeneralError(
      `${constants.TURNING_ERROR.replace("***", fieldWithError)}: ${
        formik.errors[fieldWithError]
      }`
    );
    return true;
  };

  const changeProgress = (val, needSave = true) => {
    const setLoadingParams = () => {
      setProgress(Number(val));
      setLoadingInProcess(false);
      setAddingResult(constants.ADDING_POST_STATUSES.inprosses);
    };
    setLoadingInProcess(true);
    const changeSlide = () => {
      const saveCondition =
        Number(val) < steps.length &&
        ((Number(val) > 1 && typeof currentPost.id === "undefined") ||
          typeof currentPost.id !== "undefined");
      if (Number(val) != progress) {
        if (!isErrorsExists()) {
          if (saveCondition && Number(val) < steps.length) {
            setLoadingParams();
            if (needSave) {
              saveDraft(Number(val));
            }
          } else if (Number(val) >= steps.length) {
            setLoadingParams();
            if (needSave) {
              goalHandler();
            }
            return;
          } else {
            setLoadingParams();
          }
        } else {
          setLoadingInProcess(false);
        }
      } else {
        setLoadingInProcess(false);
        setProgress(val);
      }
    };

    try {
      changeSlide();
    } catch (err) {
      setLoadingInProcess(false);
    } finally {
      // setLoadingInProcess(false);
    }
  };

  useEffect(() => {
    const stepsList = steps.map((step, index) => {
      return index < progress
        ? { ...step, done: true }
        : { ...step, done: false };
    });
    changeValue("progress", progress);
    setSteps(stepsList);
  }, [progress]);

  const progressBarClickHandler = (e) => {
    const clickedStep = e.currentTarget.getAttribute("step");
    if (isNewPost || clickedStep < progress) {
      changeProgress(Number(clickedStep));
    }
  };

  const categorySelectHandler = (id) => {
    formik.setFieldValue("category", id);
    const addSubCategories = (val) => {
      const newSubCatList =
        val.length > 0 ? [{ id: 0, name: "-" }].concat(val) : [];
      const throwSubCategory =
        newSubCatList.filter((item) =>
          String(item.id).includes(formik.values.subcategory)
        ).length == 0;
      setSubCategories(newSubCatList);
      setDefaultParent(id);
      setCurrentPost({
        ...currentPost,
        category: { term_id: id, name: categorySelectorValue },
        subcategory: 0,
      });
      changeValue("category", id);
      if (throwSubCategory) {
        changeValue("subcategory", 0);
        changeValue("category", id);
        setStartCategoryValue(
          "subcategory",
          setSubCategorySelectorValue,
          newSubCatList
        );
      }
    };

    const querry = () => {
      utils.api.getData(
        api.standart,
        `${crud.standart.CATEGORIES}?parent=${id}`,
        addSubCategories
      );
    };
    querry();
  };

  const subcategorySelectHandler = (val) => {
    formik.setFieldValue("subcategory", val);
  };

  const onChangeArticle = (e) => {
    formik.handleChange(e);
    setGeneralError("");
  };

  const changeParentCategory = (id) => {
    formik.setFieldValue("category", id);
    formik.setFieldValue("subcategory", 0);
    categorySelectHandler(id);
  };

  //for propses
  const changeValue = (name, val, callback = null) => {
    formik.setFieldValue(name, val);
    if (callback instanceof Function) callback();
  };

  useEffect(() => {
    if (userEdedPostCondition) {
      setStartCategoryValue("category", setCategorySelectorValue, categories);
      LoadSubCategories();
      async function setInitialValues() {
        if (defaultFormikValues) setStepsConditions();
        await formik.setValues(defaultFormikValues, false);
        changeProgress(defaultFormikValues.progress, false);
      }
      setInitialValues();
    } else {
      navigate("/404");
    }
  }, []);

  const clickDraftMenu = () => {
    setShowDraftMenu(!showDraftMenu);
  };

  const saveDraftAction = (e, immediatelyUpdataData = {}) => {
    saveDraft(progress, immediatelyUpdataData);
    setShowDraftMenu(false);
  };

  return (
    <>
      {loadingInProcess && (
        <Preloader litenTheme={true}>
          <motion.div>{global.LOADING}</motion.div>
        </Preloader>
      )}
      <Container className={styles.editArtivleContainer}>
        <motion.div
          className={classNames(
            styles.draftMenuContainer + " no-print",
            showDraftMenu ? styles.active : ""
          )}
        ></motion.div>
        <Progerssbar
          steps={steps}
          progress={progress}
          handler={progressBarClickHandler}
          className={isNewPost ? "" : styles.notClickable}
        />
        {generalError && (
          <Hint
            text={generalError}
            title={constants.ERROR_HINT_TITLE}
            type={"error"}
            className={styles.borderHint}
          />
        )}
        <motion.div className={styles.formSectionContainer}>
          <Carousel
            curent={progress}
            setCurent={changeProgress}
            finalHandler={checkBegoreGoalHandler}
            isPaused={isPaused}
          >
            <motion.div className={styles.slide}>
              <Row className={styles.contentRow}>
                <Col span={24}>
                  <InputSection
                    // title={constants.SECTION_TITLES[2]}
                    name={"title"}
                    defaultValue={defaultFormikValues.title}
                    placeholder={"Titel"}
                    changeValue={changeValue}
                    errors={formik.errors.title}
                  />
                </Col>
              </Row>
              <Row className={styles.contentRow}>
                <Col span={16}>
                  <SectionTextArea
                    // title={constants.SECTION_TITLES[5]}
                    placeholder={"Text des Vorworts"}
                    name={"excerpt"}
                    changeValue={changeValue}
                    defaultValue={defaultFormikValues.excerpt}
                    errors={formik.errors.excerpt}
                  ></SectionTextArea>
                </Col>
                {/* <Col span={1}></Col> */}
                <Col span={8}>
                  <SectionFileInput
                    // title={constants.SECTION_TITLES[4]}
                    name={"image"}
                    buttonText={constants.IMAGE_BTN_TEXT}
                    changeValue={changeValue}
                    defaultValue={defaultFormikValues.image}
                    errors={formik.errors.image}
                    deleteFromServer={deleteImageFormServer}
                  ></SectionFileInput>
                </Col>
              </Row>
              <Row className={styles.contentRow}>
                <Col span={24}>
                  <BlockContentSection
                    content={formik.values.content}
                    setContent={changeValue}
                    setError={setGeneralError}
                    setLoadingInProcess={setLoadingInProcess}
                    blockList={blockList}
                    setBlockList={setBlockList}
                    deletedMediafiles={formik.values.deletedMediafiles}
                    setIsPaused={setIsPaused}
                    saveDraftCallback={saveDraftAction}
                  />
                </Col>
              </Row>
            </motion.div>
            <motion.div className={classNames(styles.slide, styles.bigMargin)}>
              <Row className={styles.contentRow}>
                <Col span={10}>
                  <SelectorSection
                    title={constants.SECTION_TITLES[0]}
                    categories={categories}
                    setCategories={setCategories}
                    selectHandler={categorySelectHandler}
                    defaultParent={0}
                    selectorValue={categorySelectorValue}
                    setSelectorValue={setCategorySelectorValue}
                    changeMainForm={changeParentCategory}
                    categoriesCrud={`${crud.standart.PARENT_CATEGORIES}&`}
                  ></SelectorSection>
                </Col>
                <Col span={4}></Col>
                <Col span={10}>
                  <InfoCard
                    text={constants.HINTS[0].title}
                    classes={styles.descriptioContainer}
                    infoIcon={true}
                  />
                </Col>
              </Row>
              <Row className={styles.contentRow}>
                <Col span={10}>
                  <SelectorSection
                    title={constants.SECTION_TITLES[1]}
                    categories={subCategories}
                    setCategories={setSubCategories}
                    selectHandler={subcategorySelectHandler}
                    defaultParent={defaultParent}
                    changeMainForm={subcategorySelectHandler}
                    selectorValue={subCategorySelectorValue}
                    setSelectorValue={setSubCategorySelectorValue}
                    categoriesCrud={`${crud.standart.CATEGORIES}?parent=${defaultParent}&`}
                  ></SelectorSection>
                </Col>
                <Col span={4}></Col>
                <Col span={10}>
                  <InfoCard
                    text={constants.HINTS[1].text}
                    classes={styles.descriptioContainer}
                    infoIcon={true}
                  />
                </Col>
              </Row>
            </motion.div>
            <motion.div className={styles.slide}>
              <form
                id={mainFormId}
                className={styles.formConatiner}
                onSubmit={formik.handleSubmit}
              >
                <input
                  id="category"
                  name="category"
                  type="text"
                  value={formik.values.category}
                  onChange={onChangeArticle}
                  className={styles.invisible}
                />
                <input
                  id="subcategory"
                  name="subcategory"
                  type="text"
                  value={formik.values.subcategory}
                  onChange={onChangeArticle}
                  className={styles.invisible}
                />
                <input
                  id={"links"}
                  name={"links"}
                  placeholder={"Text of the publication"}
                  value={formik.values.links}
                  onChange={onChangeArticle}
                  className={styles.invisible}
                ></input>

                <textarea
                  id={"excerpt"}
                  name={"excerpt"}
                  placeholder={"Text of the publication"}
                  value={formik.values.excerpt}
                  onChange={onChangeArticle}
                  className={styles.invisible}
                ></textarea>
                <input
                  id={"content"}
                  type="text"
                  name={"content"}
                  value={formik.values.content}
                  onChange={onChangeArticle}
                  className={styles.invisible}
                />
                <Row className={styles.contentRow}>
                  <Col span={24}>
                    <ArticleSection
                      post={{
                        categories: [
                          formik.values.subcategory
                            ? formik.values.subcategory
                            : formik.values.category,
                        ],
                        title: {
                          rendered: formik.values.title,
                        },
                        content: {
                          rendered: utils.post.divider(formik.values.content),
                        },
                        excerpt: {
                          rendered: formik.values.excerpt,
                        },
                        acf: {
                          main_image: formik.values.image,
                          links: formik.values.links,
                          preview_image: formik.values.image,
                        },
                        status: "draft",
                      }}
                      containerClasses={styles.articlePreviewContainer}
                      isPreview={true}
                      printAvalible={false}
                    />
                  </Col>
                </Row>
              </form>
            </motion.div>
            <motion.div className={styles.slide}>
              <SectionFinish result={addingResult} />
            </motion.div>
          </Carousel>
        </motion.div>
      </Container>
    </>
  );
};
