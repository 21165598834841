import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useNavigate, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Preloader } from "../../Preloader/Preloader";
import constants from "./constants";
import useFillHint from "../../../hooks/fillHint/fillHint";
import { show, hide, setData } from "../../../../features/popup.window";
import { v4 as uuid } from "uuid";
import api from "../../../../api/server.api";
import crud from "../../../../api/crud";
import styles from "./publishpostsection.module.scss";
import utils from "../../../utils";
import { CardsHorizontal } from "../../Cards/CardHorizontal/CardsHorizoantal";

const PublishedPostsSection = ({
  posts,
  setPosts,
  toolsListExists,
  menuReloader,
}) => {
  const hidePopup = () => {
    dispatch(hide());
  };
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const mainFormId = uuid().slice(0, 16);
  const [deletedId, setdeletedId] = useState(0);
  const { mouseOver: mouseOverEdit, mouseLeave: mouseLeaveEdit } = useFillHint(
    constants.EDIT_HINT_TEXT
  );
  const { mouseOver: mouseOverDelete, mouseLeave: mouseLeaveDelete } =
    useFillHint(constants.DELETE_HINT_TEXT);

  const deleteCallback = (id) => {
    const ps = posts.filter((item) => item.id != id);
    console.log(ps);
    setPosts(ps);
  };

  const [toolsList] = useState([
    {
      icon: constants.EDIT_BUTTON,
      action: (id) => {
        const address = `${constants.EDIT_ADDRESS}/${id}`;
        nav(address);
        return <Navigate to={address} />;
      },
      mouseOver: mouseOverEdit,
      mouseLeave: mouseLeaveEdit,
    },
    {
      icon: constants.DELETE_BUTTON,
      action: (id) => {
        console.log(id);
        setdeletedId(id);
        deleteDraft();
      },
      mouseOver: mouseOverDelete,
      mouseLeave: mouseLeaveDelete,
    },
  ]);

  useEffect(() => {
    const rederedPosts = document
      .getElementById(mainFormId)
      .querySelectorAll(".postCard");
    utils.post.postOutputInTubs(rederedPosts, styles.bigPostCard);
  }, [posts]);

  useEffect(() => {
    if (deletedId) {
      deleteDraft();
    }
  }, [deletedId]);

  const deleteBtnHandler = () => {
    deleteOnServer();
  };

  const deleteOnServer = async () => {
    const succesHandler = () => {
      menuReloader();
      const payload = {
        title: constants.OPUP_DELETE_TITLE,
        message: constants.POPUP_SUCCESS_DELETE_TITLE,
        buttons: [],
      };
      dispatch(setData(payload));
      dispatch(show());
      deleteCallback(deletedId);
      setdeletedId(0);
    };

    const failHandler = (err) => {
      console.log(err);
      const payload = {
        title: constants.POPUP_FAIL_DELETE_TITLE,
        message: constants.POPUP_FAIL_DELETE_MESSAGE,
        buttons: [],
      };
      dispatch(setData(payload));
      dispatch(show());
      setdeletedId(0);
    };

    try {
      setShowLoader(true);
      dispatch(hide());
      console.log(deletedId);
      await Promise.all([
        utils.api.deleteData(
          api.standart,
          `${crud.standart.POSTS}/${deletedId}?force=true`,
          succesHandler,
          {},
          failHandler
        ),
      ]);
    } catch (e) {
      console.log(e);
      failHandler(e.message);
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const deleteDraft = () => {
    const buttons = [
      {
        btnTitle: constants.CANSEL_BTN,
        btnClick: hidePopup,
        isLink: true,
      },
      {
        btnTitle: constants.DELETE_BTN,
        btnClick: deleteBtnHandler,
        isLink: false,
      },
    ];
    const payload = {
      title: constants.POPUP_DELETE_TITLE,
      message: constants.POPUP_MESSAGE,
      buttons: buttons,
    };
    try {
      dispatch(setData(payload));
      dispatch(show());
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {showLoader && <Preloader />}
      <motion.div id={mainFormId}>
        {posts && posts.length ? (
          posts.map((post, index) => {
            return (
              <motion.div className={styles.cardListcontainer}>
                <CardsHorizontal
                  postId={post.id}
                  key={`post-${index}`}
                  classes={classNames(
                    "postCard",
                    styles.postCard,
                    styles.smallPostCard
                  )}
                  author={{
                    avatar: {
                      src: post.author?.avatar,
                      alt: post.author?.nicname,
                    },
                    name: post.author?.nicname,
                  }}
                  toolsList={toolsListExists ? toolsList : []}
                  maxSize={true}
                  title={post.title?.rendered}
                  variants={constants.ANIMATION.items.variants}
                  preview={utils.text.removeTags(post.excerpt?.rendered)}
                  date={post.date}
                  transition={constants.ANIMATION.items.transition(
                    index * constants.ANIMATION_DELAY_INDEX
                  )}
                />
              </motion.div>
            );
          })
        ) : (
          <motion.div className={styles.emptyResult}>
            {constants.NO_PUBLISHED_YET}
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

PublishedPostsSection.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPosts: PropTypes.func.isRequired,
  toolsList: PropTypes.arrayOf(PropTypes.object),
  menuReloader: PropTypes.func,
};

PublishedPostsSection.defaultProps = {
  toolsList: [],
  menuReloader: () => {},
};

export { PublishedPostsSection };
