import apiUtils from './apiQueryUtils'
import userUtils from './userData'
import api from '../../api/server.api'
import crud from '../../api/crud'

const deleteCommentFunction = (
  comment,
  updateCommentsFunction,
  customErrorHandler,
  ErrorText,
) => {
  const token = userUtils.getToken()
  // const headerWithAuth = userUtils.headerWithAuth();

  const deleteCommnetQuerry = async () => {
    try {
      await apiUtils.deleteData(
        api.custom,
        `${crud.custom.COMMENT_EDIT}/${comment.id}`,
        updateCommentsFunction,
        {},
        errorHalding,
      )
    } catch (e) {
      errorHalding(ErrorText)
    }
  }

  const errorHalding = (e) => {
    customErrorHandler(e)
  }

  if (token) {
    deleteCommnetQuerry()
  }
}

export default {
  deleteCommentFunction,
}
