import images from "../../../../assets/images"

const ICON_OPEN = {
    src: images.guidline.arrow_right_white,
    alt: "open sub menu"
}


export default {
    ICON_OPEN
}