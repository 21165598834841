import animation from '../../../../constants/animation'
import environment from '../../../../constants/environment'

const CARD_VARIANTS = {
  offscreen: animation.FADEOUT_ANIMATION,
  onscreen: {
    ...animation.FADEIN_ANIMATION,
    transition: { ...animation.TRANSITION_SETTINGS },
  },
}

const ANIMATION_DELAY = 0.2

const CARD_ANIMATION = animation.CARD_VARIANTS

const BUTTON_TEXT = 'E-Mail senden'

const FEEDBACK_FORM_TITLE = 'Kontaktieren Sie uns für weitere Fragen'

const TITLE_COLOR = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].TITLES_COLOR

const FEEDBACK_FORM_SECTION = 'Kontaktieren Sie uns für weitere Fragen'

export default {
  CARD_VARIANTS,
  BUTTON_TEXT,
  FEEDBACK_FORM_TITLE,
  TITLE_COLOR,
  ANIMATION_DELAY,
  CARD_ANIMATION,
  FEEDBACK_FORM_SECTION
}