import images from "../../../../../assets/images";
import environment from "../../../../../constants/environment";

const NOPAGE_IMG_DEPARTMENT = {
  src: images.noPageImgDepartment,
  alt: "404 page",
};

const NOPAGE_IMG_EGK = {
  src: images.noPageImgEGK,
  alt: "404 page",
};

const NOPAGE_IMG_UKHD = {
  src: images.noPageImgUKHD,
  alt: "404 page",
};

const CURRENT_VER =
  environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]
    .VERSION;

export default {
  CURRENT_VER,
  NOPAGE_IMG_DEPARTMENT,
  NOPAGE_IMG_EGK,
  NOPAGE_IMG_UKHD,
};
