import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SingleField } from "../../TextField/SingleField/SingleField";
import { List } from "../../VerticalList/List/List";
import { LoaderIcon } from "../../Preloader/LoaderIcon/LoaderIcon";
import api from "../../../../api/server.api";
import utils from "../../../utils/apiQueryUtils";
import crud from "../../../../api/crud";
import {
  TRANSITION_SETTINGS,
  FADEIN_ANIMATION,
  FADEOUT_ANIMATION,
  WIDTH_START_ANIMATION,
  WIDTH_FINISH_ANIMATION,
} from "../../../../constants/animation";
import constants from "./constants";
import styles from "./navsider.module.scss";

export const NavSider = ({ menuTree, setmenuTree }) => {
  const [isLoading, setLoading] = useState(false);
  const [queryTimer, setQueryTimer] = useState(undefined);
  const [searchError, setSearchError] = useState("");
  const [searchVal, setSearchVal] = useState('')

  const transition = {
    ...TRANSITION_SETTINGS,
    ease: "linear",
    duration: 0.8,
  };

  const cardVariants = {
    offscreen: WIDTH_START_ANIMATION,
    onscreen: {
      ...WIDTH_FINISH_ANIMATION,
      transition: { ...transition, duration: 0.4 },
    },
  };

  const titleCardVariants = {
    offscreen: FADEOUT_ANIMATION,
    onscreen: {
      ...FADEIN_ANIMATION,
      transition: { ...transition, duration: 0.4 },
    },
  };

  const menuListUpdate = (list) => {
    if (list.length > 0) {
      setmenuTree(list);
      setSearchError("")
      return;
    }
    setSearchError(constants.EMPTY_RESULT);
  };

  const updateMenu = async (searchString) => {
    try {
      await Promise.all([
        utils.getData(
          api.custom,
          `${crud.custom.MULTY_LVL_MENU}?s=${searchString}`,
          menuListUpdate,
          {},
          (e) => {
            console.log(e);
          }
        ),
      ]);
    } catch (e) {
      setmenuTree([]);
    } finally {
      setLoading(false);
    }
  };

  const searchInputKeyPress = (e) => {
    const exculudingCondition =
      e.key == "Alt" ||
      e.key == "Shift" ||
      e.key == "Meta" ||
      e.key == "ArrowLeft" ||
      e.key == "ArrowRight" ||
      e.key == "Control";
    if (!exculudingCondition && e.target.value.length > 2) {
      const searchString = e.target.value;
      setSearchVal(searchString)
      subMitHandler(searchString);
    }else{
      setSearchVal('');
    }
    if( e.target.value.length == 0){
      subMitHandler('');
      setSearchVal('');
    }
  };

  const subMitHandler = async (searchString) => {
    setLoading(true);
    setSearchError("")
    clearTimeout(queryTimer);
    setQueryTimer(
      setTimeout(function () {
        updateMenu(searchString);
      }, 500)
    );
  };

  return (
    <motion.aside className={styles.verticalMenu + " no-print"}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
        <motion.div className={styles.logo} variants={titleCardVariants}>
          <Link
            className={styles.logoLink}
            to="/"
            dangerouslySetInnerHTML={{
              __html: constants.HOME_LINK_TEXT,
            }}
          />
        </motion.div>
        {constants.SUBTITLE && (
          <motion.div variants={titleCardVariants} className={styles.subtitle}>
            {constants.SUBTITLE}
          </motion.div>
        )}
        <motion.div className={styles.searchArea} variants={cardVariants}>
          <SingleField
            onKeyUp={searchInputKeyPress}
            submitHandler={subMitHandler}
            autoComplete={constants.SERACH_AUTOCOMPLETE_VAL}
            title={constants.SEARCH_FIELD_NAME}
            placeholder={constants.SERCH_PLACEHOLDER}
          />
        </motion.div>
      </motion.div>
      {searchError.length == 0 && (
        <>
          {!isLoading && (
            <motion.div className={styles.menuContainer}>
              {menuTree.map((main_cat, index) => {
                const getItemsInCat = (array) => {
                  return array?.map((child) => {
                    return {
                      title: child.post_title,
                      link: `/posts/${child.post_name}`,
                      text: child.post_content
                    };
                  });
                };
                const items = getItemsInCat(main_cat.children).concat(
                  main_cat.subnodes?.map((child) => {
                    return {
                      title: child.cat_name,
                      link: "/",
                      submenu: getItemsInCat(child.children),
                    };
                  })
                );

                return (
                  <>
                    <motion.div className={styles.categoryTitle} key={index}>
                      {main_cat.cat_name}
                    </motion.div>
                    <List items={items} submenuVisible={true} selectionString={searchVal}/>
                  </>
                );
              })}
            </motion.div>
          )}
          {isLoading && (
            <>
              <motion.div className={styles.loagingsignContainer}>
                <LoaderIcon />
                <motion.p>
                  <span>{constants.SEARCH_DESCRIPTION_SIGN}</span>
                </motion.p>
              </motion.div>
            </>
          )}
        </>
      )}
      {searchError && (
        <motion.div className={styles.errorContainer}>
          <span className="input-error">{searchError}</span>
        </motion.div>
      )}
    </motion.aside>
  );
};
