import { motion } from "framer-motion";
import { useNavigate, Navigate } from "react-router-dom";
import classNames from "classnames";
import styles from "./card.module.scss";
import PropTypes from "prop-types";
import { CardTextArea } from "../CardTextArea/CardTextArea";
import { CardImageArea } from "../CardImageArea/CardImageArea";
const CardsHorizontal = ({
  postId,
  userId,
  darkMode,
  image,
  author,
  title,
  preview,
  date,
  tagList,
  maxSize,
  classes,
  variants,
  transition,
  link,
  toolsList,
}) => {
  const nav = useNavigate();
  const addLink = () => {
    const finalLink = `/posts/${link}`;
    nav(finalLink);
    return <Navigate to={finalLink} />;
  };

  return (
    <motion.div
      className={classNames(
        styles.container,
        classes,
        maxSize ? styles.maxSize : "",
        darkMode ? styles.darkMode : "",
        link ? styles.link : ""
      )}
      onClick={link ? addLink : () => {}}
      variants={variants}
      transition={transition}
      initial={"start"}
      animate={"end"}
      exit={"start"}
      viewport={{ once: true }}
    >
      {toolsList.length > 0 && (
        <motion.div className={styles.toolsContainer}>
          {toolsList.map((tool, index) => (
            <motion.div
              key={index}
              onClick={() => {
                tool.action(postId);
              }}
              onMouseOver={tool.mouseOver}
              onMouseLeave={tool.mouseLeave}
              className={styles.toolsItem}
            >
              <motion.img src={tool.icon.src} alt={tool.icon.alt} />
            </motion.div>
          ))}
        </motion.div>
      )}
      {maxSize && (
        <CardTextArea
          postId={postId}
          userId={userId}
          darkMode={darkMode}
          author={author}
          title={title}
          preview={preview}
          data={date}
          tagList={tagList}
        />
      )}
      {image.length > 0 && <CardImageArea image={image} maxSize={maxSize} />}
      {!maxSize && (
        <CardTextArea
          postId={postId}
          userId={userId}
          author={author}
          title={title}
          preview={preview}
          data={date}
          tagList={tagList}
        />
      )}
    </motion.div>
  );
};

export { CardsHorizontal };

CardsHorizontal.propTypes = {
  postId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  image: PropTypes.string,
  darkMode: PropTypes.bool,
  author: PropTypes.oneOfType([
    PropTypes.shape({
      avatar: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  title: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  tagList: PropTypes.arrayOf(PropTypes.string),
  date: PropTypes.string,
  maxSize: PropTypes.bool,
  classNames: PropTypes.string,
  link: PropTypes.string,
  toolsList: PropTypes.arrayOf(PropTypes.object),
};

CardsHorizontal.defaultProps = {
  image: "",
  darkMode: false,
  author: "",
  tagList: [],
  userId: undefined,
  postId: undefined,
  maxSize: false,
  classNames: "",
  date: "",
  link: "",
  toolsList: [],
};
