import images from '../../../../../../assets/images/'
import errorConstants from '../../../../../../constants/errors'
import constants from '../../constants'
import environment from '../../../../../../constants/environment'

const VERSION = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]
.VERSION

export const BASKET_IMG = {
  src: images.guidline[`trash_icon_${VERSION}`],
  alt: 'delete',
}

const PLUS_IMAGE = {
  src: images.guidline.plus_button_blue,
  alt: 'new category'
}

const COLLAPSE_TITLE = 'Neue Kategorie'

const SERVER_ERROR = errorConstants.SERVER_ERROR

const NO_USER_TOKEN = errorConstants.NO_USER_TOKEN

const ADD_NEW = "Neu hinzufügen"

const NEW_CATEGORY_TEMPLATE = constants.NEW_CATEGORY_TEMPLATE

const TITLE_PLACEHOLDER = "Titel"

const CLEAR_NEW_CATEGORY = "Löschen Sie die Felder"

export default {
  BASKET_IMG,
  COLLAPSE_TITLE,
  SERVER_ERROR,
  NO_USER_TOKEN,
  ADD_NEW,
  NEW_CATEGORY_TEMPLATE,
  TITLE_PLACEHOLDER,
  CLEAR_NEW_CATEGORY,
  PLUS_IMAGE
}
