import { motion } from "framer-motion";
import { Formik, Form, Field } from "formik";
import constants from "./constants";
import styles from "./basiccommentform.module.scss";

export const BasicCommentForm = ({
  children,
  defaultValues,
  validationSchema,
  submitFunction,
  commentId = 0,
  closeFunction,
}) => {
  const fakeSubmit = () => {
    const realSubmitBtn = document.getElementById(
      `cooment-realSubmit-${commentId}`
    );
    if (realSubmitBtn) {
      realSubmitBtn.click();
    }
  };

  return (
    <motion.div className={styles.editCommentContainer}>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={submitFunction}
      >
        {(props) => (
          <Form className={styles.editCommentForm}>
            <motion.div>
              <Field
                type="textarea"
                name="content"
                id="content"
                component="textarea"
                className={styles.editCommentField}
                placeholder={constants.INPUT_PLACEHOLDER}
              />
            </motion.div>
            {props.errors.content && (
              <motion.div className={styles.fieldError}>
                <span>{props.errors.content}</span>
              </motion.div>
            )}
            {props.errors.commentId && (
              <motion.div className={styles.fieldError}>
                <span>{props.errors.commentId}</span>
              </motion.div>
            )}
            {children}
            <input
              type="submit"
              id={`cooment-realSubmit-${commentId}`}
              className={styles.hiddenSubmit}
            />
          </Form>
        )}
      </Formik>
      <motion.div className={styles.buttonsContainer}>
        <motion.div
          className={styles.formButton}
          onClick={closeFunction}
          whileTap={{ scale: 0.5 }}
        >
          <img
            src={constants.COMMENT_CROSS.src}
            alt={constants.COMMENT_CROSS.alt}
          />
        </motion.div>
        <motion.div
          className={styles.formButton}
          onClick={fakeSubmit}
          whileTap={{ scale: 0.5 }}
        >
          <img
            src={constants.COMMNET_CHECK_MARK.src}
            alt={constants.COMMNET_CHECK_MARK.alt}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
