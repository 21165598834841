import {motion} from "framer-motion"
import useFillHint from "../../shared/hooks/fillHint/fillHint";
import { useEffect } from "react";
import classNames from "classnames";
import constants from "./constants";

export const DefaultPage = ({ children, className = "" }) => {
  const { mouseLeave } = useFillHint();

  useEffect(() => {
    mouseLeave();
  }, []);

  return (
    <motion.div className={classNames(className, constants.THEME_CLASS)}>
      {children}
    </motion.div>
  );
};
