import global from "../../../../constants/global"
import animation from "../../../../constants/animation"

export const CARD_VARIANTS = {
    offscreen: animation.FADEOUT_UP_ANIMATION,
    onscreen: {
      ...animation.FADEIN_DOWN_ANIMATION,
      transition: animation.TRANSITION_SETTINGS,
    }
  };

const ANIMATION = { menu: { ...animation.DOWN_APPEARING(0.3) }, menuItem: {...animation.FADEIN_APPEARING(0.3)} }

const BUTTON_TYPES = global.BUTONS_MODE_LIST

const MENU_ITEM_DELAY = 0.1

export default {
    BUTTON_TYPES,
    ANIMATION,
    MENU_ITEM_DELAY,
    CARD_VARIANTS
}