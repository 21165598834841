import images from '../../../../assets/images'
import globalConstants from '../../../../constants/global'
import environment from '../../../../constants/environment'


const VERSION = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]
.VERSION

const ACTIVE_LIKE_BTN = {
    src: images.guidline[`like_button_${VERSION}`],
    alt : "like set"
}

const GRAY_LIKE_BTN = {
    src: images.guidline.like_button,
    alt: "like unset"
}

const LOADING = globalConstants.LOADING

const COMMENT_IS_ADDED = 'Der Kommentar wurde erfolgreich hinzugefügt'

const COMMENT_COULD_NOT_BE_ADDED = 'Ein Kommentar kann nicht hinzugefügt werden. Versuchen Sie es erneut oder wenden Sie sich an den technischen Support'

const COMMENT_LIST_COULD_NOT_BE_UPDATED = 'Die Liste der Kommentare zu dieser Veröffentlichung kann nicht aktualisiert werden'

const NOONE_LEFT_A_COMMENT_YET = 'Bisher hat noch niemand einen Kommentar zu diesem Beitrag abgegeben. Sie können der Erste sein'

const THIS_COMMENT_COULD_NOT_BE_DELETED = 'Dieser Kommentar kann nicht gelöscht werden'

const EDIT_BUTTON_TITLE = 'Bearbeiten'

const DELETE_BUTTON_TITLE = 'Löschen'

const REPLAY_BUTTON_TITLE = 'Antwort'

const LOADING_TEXT = 'Die Kommentarliste wird aktualisiert...'

const LIKE_ERROR = 'Fehler beim Senden von "Gefällt mir" -Daten'

const STRANGE_LIKE_RESPONSE_FROM_SERVER = 'Vom Server wurde eine falsche Formatantwort erhalten'

const LIKES_WRONG_VALUE = 'Der Wert der "Gefällt mir" -Markierungen auf dem Server und auf dem Client stimmt nicht überein'

const MODERATOR_ROLE = globalConstants.EDITOR_ROLES[0]


export default {
    COMMENT_LIST_COULD_NOT_BE_UPDATED,
    NOONE_LEFT_A_COMMENT_YET,
    THIS_COMMENT_COULD_NOT_BE_DELETED,
    COMMENT_IS_ADDED,
    COMMENT_COULD_NOT_BE_ADDED,
    EDIT_BUTTON_TITLE,
    DELETE_BUTTON_TITLE,
    REPLAY_BUTTON_TITLE,
    LOADING_TEXT,
    ACTIVE_LIKE_BTN,
    GRAY_LIKE_BTN,
    LOADING,
    LIKE_ERROR,
    STRANGE_LIKE_RESPONSE_FROM_SERVER,
    LIKES_WRONG_VALUE,
    MODERATOR_ROLE
}