import images from "../../../../assets/images"

const INPUT_PLACEHOLDER = "Ihre Meinung ist uns sehr wichtig! Was halten Sie von diesem Beitrag?"

const COMMNET_CHECK_MARK = {
    src: images.commentOk,
    alt: "change comment"
}

const COMMENT_CROSS = {
    src: images.commentCross,
    alt: "remove a comment text"
}

const CONMMENT_IS_UPDATED = 'Änderungen vorgenommen'
const COMMENT_COULDNT_BE_UPDATED = 'Fehler beim Aktualisieren. Versuchen Sie, die Seite neu zu laden, oder wenden Sie sich an Ihren Administrator'

export default {
    INPUT_PLACEHOLDER,
    COMMENT_CROSS,
    COMMNET_CHECK_MARK,
    CONMMENT_IS_UPDATED,
    COMMENT_COULDNT_BE_UPDATED
}