import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Navigate, useNavigate } from "react-router-dom";
import { Container } from "../../shared/components/_layouts/Container/Container";
import { Footer } from "../../shared/components/_sections/Footer/Footer";
import { Header } from "../../shared/components/_sections/Header/Header";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { PublishedPostsSection } from "../../shared/components/_sections/PublishedPostsSection/PublishedPostsSection";
import { NavSider } from "../../shared/components/_menus/NavSider/NavSider";
import { UserEditForm } from "../../shared/components/_forms/UserEditForm/UserEditForm";
import { Tabs } from "../../shared/components/Tabs/Tabs";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import useRedirects from "../../shared/hooks/redirects/redirects";
import api from "../../api/server.api";
import crud from "../../api/crud";
import utils from "../../shared/utils";
import constants from "./constants";
import styles from "./userpage.module.scss";

export const UserPage = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [menuTree, setmenuTree] = useState([]);
  const [visible, setVisibility] = useState(true);
  const [posts, setPosts] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [onModeration, setOnModeration] = useState([]);
  const [allPublished, setAllPublished] = useState([]);
  const [usr, setUsr] = useState({});
  const [tabs, setTabs] = useState(constants.TABS);
  const { toLoginpage } = useRedirects();


  const menuReloader = () => {
    utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree);
  };

  const setPostsTabs = (data) => {
    setPosts(data);
  };

  useEffect(() => {
    const newTabs = tabs;
    newTabs[1].children = (
      <PublishedPostsSection
        posts={posts}
        published={true}
        menuReloader={menuReloader}
        setPosts={setPosts}
        toolsListExists={true}
      />
    );
    setTabs(newTabs);
  }, [posts]);

  const setTabsOnModeration = (data) => {
    setOnModeration(data);
  };

  useEffect(() => {
    const newTabs = tabs;
    const isModerator = utils.user.getUserData()?.role == constants.EDITOR_ROLES[0]
    newTabs[2].children = (
      <PublishedPostsSection
        posts={onModeration}
        menuReloader={menuReloader}
        canEdit={isModerator}
        setPosts={setOnModeration}
        toolsListExists={isModerator}
      />
    );
    setTabs(newTabs);
  }, [onModeration]);

  const AllPublished = (data) => {
    setAllPublished(data);
  };

  useEffect(() => {
    if (tabs.length > 3) {
      const newTabs = tabs;
      newTabs[3].children = (
        <PublishedPostsSection
          posts={allPublished}
          menuReloader={menuReloader}
          setPosts={setAllPublished}
          toolsListExists={true}
        />
      );
      setTabs(newTabs);
    }
  }, [allPublished]);

  const setDraftsTabs = (data) => {
    setDrafts(data);
  };

  useEffect(() => {
    const newTabs = tabs;
    newTabs[0].children = (
      <PublishedPostsSection
        posts={drafts}
        menuReloader={menuReloader}
        setPosts={setDrafts}
        toolsListExists={true}
      />
    );
    setTabs(newTabs);
  }, [drafts]);

  

  const loadInformation = async () => {
    const userID = utils.user.getUserID();
    const token = utils.user.getToken();

    if (!userID || !token) {
      navigate("/login");
      return <Navigate to="/login" />;
    }
    try {
      let loadFunctionsArray = [
        utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree),
        utils.api.getData(
          api.custom,
          `${crud.custom.PREVIEWS}?author=${userID}`,
          setPostsTabs
        ),
        utils.api.getData(
          api.custom,
          `${crud.custom.PREVIEWS}?author=${userID}&status=draft`,
          setDraftsTabs
        ),
        utils.api.getData(
          api.custom,
          `${crud.custom.PREVIEWS}?${
            utils.user.getUserData()?.role != constants.EDITOR_ROLES[0]
              ? "author=" + userID + "&"
              : ""
          }status=pending`,
          setTabsOnModeration
        ),
        utils.api.postData(
          api.custom,
          `${crud.custom.USER_DATA}`,
          (data) => {
            if (data.data) {
              setUsr(data.data);
            }
          },
          {
            id: userID,
          }
        ),
      ];
      if (utils.user.getUserData()?.role == constants.EDITOR_ROLES[1]) {
        loadFunctionsArray.push(
          utils.api.getData(api.custom, `${crud.custom.PREVIEWS}`, AllPublished)
        );
      }
      await Promise.all(loadFunctionsArray);
    } catch (e) {
      console.log(e);
    } finally {
      setVisibility(false);
    }
  };

  useEffect(() => {
    loadInformation();
    if (
      utils.user.getUserData() &&
      utils.user.getUserData()?.role == constants.EDITOR_ROLES[1] &&
      tabs.filter((tab) => tab.key == constants.PUBLICATION_OF_OTHERS.key)
        .length == 0
    ) {
      tabs.push({
        key: constants.PUBLICATION_OF_OTHERS.key,
        label: constants.PUBLICATION_OF_OTHERS.name,
      });
    }
  }, []);

  if (!isLoggedIn) {
    utils.user.setLastPage(window.location.pathname);
    return toLoginpage();
  }

  return (
    <DefaultPage>
      <Preloader visible={visible} />
      {!visible && (
        <>
          <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
          <Container className={classNames(styles.container)}>
            <Header />
            <Container className={styles.userInterfaceTabs}>
              <UserEditForm user={usr} className={styles.UserEditForm} />
              <Tabs
                defaultActiveKey={1}
                items={tabs}
                className={styles.userInterfaceTabs}
              />
            </Container>
            <Footer></Footer>
          </Container>
        </>
      )}
    </DefaultPage>
  );
};
