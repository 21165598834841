import { motion } from "framer-motion"
import classNames from "classnames"
import { RESULT_KITS } from "./constants"
import styles from "../../editarticle.module.scss"

export const SectionFinish = ({result, errorCode = '', className=""})=>{    
    return (
        <motion.div className={classNames(styles.resultContainer, className)}>
            <motion.div className={styles.imageBlock}>
                <img src={RESULT_KITS[result].icon.src} alt={RESULT_KITS[result].icon.alt}/>
            </motion.div>
            <motion.div
            className={styles.textBalock}
            dangerouslySetInnerHTML={{
                __html: RESULT_KITS[result].text
              }}>
            </motion.div>
        </motion.div>
    )
}