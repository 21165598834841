import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setStatus } from "../../../../features/asidemenu.status";
import classNames from "classnames";
import constants from "./constants";
import styles from "./style.module.scss";

export const AsideTypesMenu = () => {
  const dispatcher = useDispatch();
  const { visibility, content, extraClick } = useSelector(
    (state) => state.asideMenu
  );
  const [displayNoneCondition, setDisplayNoneCondition] = useState(false);

  const closeAsideMenu = (e) => {
    dispatcher(setStatus(false));
  };

  const clickOnAsideManu = (e) => {
    if (!visibility) {
      dispatcher(setStatus(true));
    }
    e.stopPropagation();
  };

  useEffect(() => {
    if (visibility) {
      setDisplayNoneCondition(visibility);
    } else {
      setTimeout(() => {
        setDisplayNoneCondition(visibility);
      }, 500);
    }
  }, [visibility]);

  return (
    <>
      <motion.div
        className={classNames(
          styles.AsideMenuContainer,
          visibility ? styles.visible : "",
          displayNoneCondition ? "" : styles.notVisible
        )}
        onClick={closeAsideMenu}
      >
        <motion.div
          className={classNames(
            styles.AsideMenu,
            visibility ? styles.visible : ""
          )}
          id="block-konfiguration-menu"
          onClick={clickOnAsideManu}
        >
          <motion.div
            className={styles.AsideMenuCross}
            onClick={closeAsideMenu}
          ></motion.div>
          {content &&
            Array.isArray(content) &&
            content.map((item, index) => (
              <motion.div
                className={classNames(
                  styles.ExumpleContainer,
                  item.isActive ? styles.active : ""
                )}
                key={index}
                onClick={() => {
                  extraClick(index);
                }}
              >
                <motion.div className={styles.ExumpleContainerPicture}>
                  <motion.div className={item.containerClass}>
                    <motion.div
                      className={classNames(
                        item.itemsClass,
                        styles.smallImagePicture
                      )}
                    >
                      <motion.img
                        src={constants.SMALL_EXUMLE_IMAGE.src}
                        alt={constants.SMALL_EXUMLE_IMAGE.alt}
                      />
                    </motion.div>
                    <motion.div
                      className={classNames(
                        styles.smallImageTitle,
                        item.itemsClass,
                        item.titleClass
                      )}
                    >
                      {constants.EXUMPLE_IMAGE_TEXT}
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div className={styles.ExumpleContainerSign}>
                  <motion.div className={styles.number}>
                    {index + 1 < 10 ? `0${index + 1}` : index + 1}
                  </motion.div>
                  <motion.div className={styles.text}>{item.title}</motion.div>
                </motion.div>
              </motion.div>
            ))}
        </motion.div>
      </motion.div>
    </>
  );
};
