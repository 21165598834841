const HOST = `${window.location.hostname}${
  window.location.port ? ':' + window.location.port : ''
}`

const SAC_SETTINGS = {
  HOME_LINK_TEXT: 'UKHD',
  // SUBTITLE : 'SAC Projekthandbuch',
  SUBTITLE: 'Fachhandbuch SAC',
  TITLE: 'Wiki UKHD SAC Fachhandbuch',
  LOGO_SIGN: 'sacSign',
  DESCRIPTION_SIGN:
    'Freie Wissensdatenbank für Mitarbeitende: Hier können Sie Wikis erstellen, sie mit Informationen anreichern und zusammen mit Ihren Kollegen und Kolleginnen bearbeiten.',
  COLOR: '#004A70',
  TITLES_COLOR: '#004A70',
  FOOTER: 'ukhd',
  FAVICON: 'ukhd',
  VERSION: 'ukhd',
  EXCLUDED_CATEGORIES: [1, 6],
}

const GESAMPROGRAMM_SETTINGS = {
  HOME_LINK_TEXT: 'UKHD',
  // SUBTITLE : 'SAC Fachhandbuch',
  SUBTITLE: 'Projekthandbuch Gesamtprogramm',
  // LOGO_SIGN : 'sacSign',
  TITLE: 'Wiki UKHD Gesamtprogramm',
  LOGO_SIGN: 'wikiSign',
  DESCRIPTION_SIGN:
    'Freie Wissensdatenbank für Mitarbeitende: Hier können Sie Wikis erstellen, sie mit Informationen anreichern und zusammen mit Ihren Kollegen und Kolleginnen bearbeiten.',
  COLOR: '#004A70',
  TITLES_COLOR: '#004A70',
  FOOTER: 'ukhd',
  FAVICON: 'ukhd',
  VERSION: 'ukhd',
  EXCLUDED_CATEGORIES: [1, 6],
}

const DEPARTMENT_SETTINGS = {
  HOME_LINK_TEXT: 'ontron <span>plot A</span>',
  SUBTITLE: 'Das Department',
  TITLE: 'Wiki ontron/plot A Department',
  COLOR: '#A6A6A6',
  TITLES_COLOR: '#333333',
  FOOTER: 'department',
  FAVICON: 'department',
  VERSION: 'ukhd',
  THEME: 'gray-theme',
  EXCLUDED_CATEGORIES: [1, 6],
}

const EGK_SETTINGS = {
  HOME_LINK_TEXT: 'EGK',
  SUBTITLE: 'WIKI',
  TITLE: 'EGK Wiki',
  COLOR: '#A6A6A6',
  TITLES_COLOR: '#333333',
  FOOTER: 'egk',
  VERSION: 'egk',
  FAVICON: 'egk',
  THEME: 'green-theme',
  EXCLUDED_CATEGORIES: [1, 6],
}

//for local tests
const UKHD = {
  URL_STANDART: 'http://23.88.63.69:8082/wp-json/wp/v2',
  URL_CUSTOM: 'http://23.88.63.69:8082/',
  HOME_LINK_TEXT: 'UKHD<span>WIKI</span>',
  LOGO_SIGN: 'wikiSign',
  DESCRIPTION_SIGN:
    'Freie Wissensdatenbank für Mitarbeitende: Hier können Sie Wikis erstellen, sie mit Informationen anreichern und zusammen mit Ihren Kollegen und Kolleginnen bearbeiten.',
  COLOR: '#004A70',
  TITLES_COLOR: '#004A70',
  FOOTER: 'ukhd',
  EXCLUDED_CATEGORIES: [1, 6],
  MAX_LOADING_FILE_SIZE: 10485760,
  VERSION: 'ukhd'
}

const UKHD_SAC = {
  ...SAC_SETTINGS,
  URL_STANDART: 'https://server.wiki-sac.wissen-lernen.eu/wp-json/wp/v2',
  URL_CUSTOM: 'https://server.wiki-sac.wissen-lernen.eu/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 1,
}

const UKHD_GESUMPROGRAMM = {
  ...GESAMPROGRAMM_SETTINGS,
  URL_STANDART: 'https://server.wiki-ukhd.wissen-lernen.eu/wp-json/wp/v2',
  URL_CUSTOM: 'https://server.wiki-ukhd.wissen-lernen.eu',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 1,
}

const DEPARTMENT = {
  ...DEPARTMENT_SETTINGS,
  URL_STANDART: 'https://server.wiki-department.wissen-lernen.eu/wp-json/wp/v2',
  URL_CUSTOM: 'https://server.wiki-department.wissen-lernen.eu/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 17,
}

const EGK = {
  ...EGK_SETTINGS,
  URL_STANDART: 'https://server.wiki-egk.wissen-lernen.eu/wp-json/wp/v2',
  URL_CUSTOM: 'https://server.wiki-egk.wissen-lernen.eu/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 17,
  VERSION: 'egk'
}

const DEPARTMENT_TEST = {
  ...DEPARTMENT_SETTINGS,
  URL_STANDART: 'http://23.88.54.192:8082/wp-json/wp/v2',
  URL_CUSTOM: 'http://23.88.54.192:8082/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 1,
}

const UKHD_GESAMPROGRAMM_TEST = {
  ...GESAMPROGRAMM_SETTINGS,
  URL_STANDART: 'http://23.88.54.192:8082/wp-json/wp/v2',
  URL_CUSTOM: 'http://23.88.54.192:8082/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 1,
}

const UKHD_SAC_TEST = {
  ...SAC_SETTINGS,
  URL_STANDART: 'http://23.88.54.192:8082/wp-json/wp/v2',
  URL_CUSTOM: 'http://23.88.54.192:8082/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 1,
}

const EGK_TEST = {
  ...EGK_SETTINGS,
  URL_STANDART: 'http://23.88.54.192:8082/wp-json/wp/v2',
  URL_CUSTOM: 'http://23.88.54.192:8082/',
  MAX_LOADING_FILE_SIZE: 10485760,
  TECH_ADMIN_ID: 1,
}

const LOCAL = {
  ...DEPARTMENT_SETTINGS,
  URL_STANDART: 'http://localhost:8082/wp-json/wp/v2',
  URL_CUSTOM: 'http://localhost:8082/',
  MAX_LOADING_FILE_SIZE: 2097152,
  TECH_ADMIN_ID: 1,
}

const VARIABLES = {
  prod: {
    'wiki-ukhd.wissen-lernen.eu': UKHD_GESUMPROGRAMM,
    'wiki-sac.wissen-lernen.eu': UKHD_SAC,
    'wiki-department.wissen-lernen.eu': DEPARTMENT,
    'wiki-egk.wissen-lernen.eu': EGK
  },
  dev: {
    'localhost:3000': UKHD,
    'localhost:3004': UKHD_GESAMPROGRAMM_TEST,
    'localhost:3002': UKHD_SAC_TEST,
    'localhost:3006': DEPARTMENT_TEST,
    'localhost:3008': LOCAL,
    'localhost:3010': EGK_TEST,
  },
  stage: {
    'test.wiki-ukhd.wissen-lernen.eu': UKHD_GESAMPROGRAMM_TEST,
    'test.wiki-sac.wissen-lernen.eu': UKHD_SAC_TEST,
    'test.wiki-department.wissen-lernen.eu': DEPARTMENT_TEST,
    'test.wiki-egk.wissen-lernen.eu': EGK_TEST
  },
}

export default {
  VARIABLES,
  HOST,
}
