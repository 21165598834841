import environment from "../../../../constants/environment";
import images from "../../../../assets/images";

const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

const settings = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]

const ERROR_MESSAGE = 'Etwas ist schiefgelaufen';
const TITLE = 'Schön, dass Sie da sind!';

const FORM_TITLE =  settings.HOME_LINK_TEXT

const DEFAULT_COLOR = settings.COLOR

const EYE_OPENED = {
    src: images.guidline.eye_closed_icon,
    alt: 'eye opened'
}

const EYE_CLOSED = {
    src: images.guidline.eye_opened_icon,
    alt: 'eye closed'
}


export default {
    ERROR_MESSAGE,
    TITLE,
    FORM_TITLE,
    DEFAULT_COLOR,
    THEME_CLASS,
    EYE_OPENED,
    EYE_CLOSED
}