import { useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import constants from "./constants";
import styles from "./savetofav.module.scss";

const SaveToFavoritesButton = (onClick, userId, postId) => {
  const [isAdded, setIsAdded] = useState(false);

  const saveFavorites = () => {
    setIsAdded(!isAdded);
    if (onClick instanceof Function) onClick();
  };

  return (
    <motion.div className={styles.saveToFavContainer} onClick={saveFavorites}>
      {isAdded && (
        <motion.img
          src={constants.ENABLE_ICON.src}
          alt={constants.ENABLE_ICON.alt}
        />
      )}
      {!isAdded && (
        <motion.img
          src={constants.DISABLED_ICON.src}
          alt={constants.DISABLED_ICON.alt}
        />
      )}
    </motion.div>
  );
};

SaveToFavoritesButton.propTypes = {
  onClick: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  postId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SaveToFavoritesButton.defaultProps = {
  onClick: () => {},
};

export { SaveToFavoritesButton };
