import {motion} from "framer-motion"
import classNames from "classnames";
import styles from "../../editarticle.module.scss"

export const SectionTitle = ({title, className=""}) => {
  return (
    <motion.div className={classNames(styles.sectionTitle, className)} >
      <motion.h2>{title}</motion.h2>
    </motion.div>
  );
};
