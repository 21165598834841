import React from 'react'
import ReactDOM from 'react-dom/client'
import 'antd/dist/reset.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { StyleProvider } from '@ant-design/cssinjs'
import { Provider } from 'react-redux'
import { store } from './app/store'
import global from './constants/global';
import './style/index.scss'
import './style/global/global.scss'


const favicon = document.querySelector('link[rel="icon"]');
favicon.setAttribute('href', global.FAVICON)

const title = document.querySelector('title')
title.innerHTML = global.TITLE

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <StyleProvider hashPriority="high">
          <App />
        </StyleProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)
