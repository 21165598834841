import { useState } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { Formik, Form, Field } from "formik";
import { v4 as uuid } from "uuid";
import { DataList } from "../../../../VerticalList/DataList/DataList";
import { Collapse } from "../../../../Collapse/Collapse";
import { SectionTitle } from "../SectionsTitle/SectionTitle";
import { addCategorySchema } from "../../../../../../schemes";
import { BaseSubmitButton } from "../../../../Buttons/BaseSubmitButton/BaseSubmitButton";
import useFillHint from "../../../../../hooks/fillHint/fillHint";
import api from "../../../../../../api/server.api";
import crud from "../../../../../../api/crud";
import utils from "../../../../../utils";
import constants from "./constants";
import styles from "../../editarticle.module.scss";
import { FormField } from "../../../../TextField/FormField/FormField";
import { StandartButton } from "../../../../Buttons/StandartButton/StandartButton";

export const SelectorSection = ({
  title,
  categories,
  setCategories,
  selectHandler,
  defaultParent,
  changeMainForm,
  categoriesCrud,
  selectorValue,
  setSelectorValue,
}) => {
  const categoryFormId = uuid().slice(0, 8);
  const collapseId = uuid().slice(0, 9);
  const [disableDataList, setDisableDataList] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const { mouseLeave, mouseOver } = useFillHint(constants.CLEAR_NEW_CATEGORY);

  const onChangeCollapse = () => {
    if (disableDataList) {
      setDisableDataList(false);
      return;
    }
    setDisableDataList(true);
  };

  const resetForm = () => {
    document.getElementById(collapseId).querySelector(`.openCollapse`).click();
    document.getElementById(collapseId).querySelector(`.clearBtn`).click();
  };

  const preAndCategories = (values) => {
    addCategorie(values);
  };

  const addCategorie = (values) => {
    console.log("ddd");
    const parent = defaultParent,
      { name } = values,
      token = utils.user.getToken();

    const errorHandler = (err) => {
      setGeneralError(err);
    };

    const reloadCategories = (data) => {
      const newCategories = (subdata) => {
        setCategories(
          !categories.filter((element) => element.name == "-").length
            ? [{ id: 0, name: "-" }].concat(subdata)
            : subdata
        );
        setSelectorValue(name);
      };
      if (typeof data.name !== "undefined") {
        utils.api.getData(
          api.standart,
          `${categoriesCrud}per_page=100`,
          newCategories,
          {},
          errorHandler
        );
        changeMainForm(data.id);
        resetForm();
      } else {
        setGeneralError(constants.SERVER_ERROR);
      }
    };

    if (token) {
      utils.api.postData(
        api.standart,
        crud.standart.CATEGORIES,
        reloadCategories,
        { parent, name },
        {},
        errorHandler
      );
    } else {
      setGeneralError(constants.NO_USER_TOKEN);
    }
  };

  return (
    <>
      <SectionTitle title={title} />
      {categories.length > 0 && (
        <DataList
          title={title}
          valueList={categories}
          selectHandler={selectHandler}
          disabled={disableDataList}
          selectorValue={selectorValue}
          setSelectorValue={setSelectorValue}
          className={styles.zoomDropDownList}
        ></DataList>
      )}
      {/* <StandartButton title={constants.COLLAPSE_TITLE} imageBefore={constants.PLUS_IMAGE}/> */}
      <Collapse
        title={constants.COLLAPSE_TITLE}
        className={styles.collapseContainer}
        clickExtraHandler={onChangeCollapse}
        id={collapseId}
      >
        <Formik
          initialValues={{
            name: "",
            parent: defaultParent,
          }}
          validationSchema={addCategorySchema}
          onSubmit={preAndCategories}
        >
          {(props) => {
            const deleteNewCategoryHandler = (e) => {
              const form = document.getElementById(categoryFormId);
              const fields = form.querySelectorAll("input");
              fields.forEach((element) => {
                const name = element.getAttribute("name");
                props.setFieldValue(
                  name,
                  constants.NEW_CATEGORY_TEMPLATE[name]
                );
              });
            };
            return (
              <>
                <motion.div
                  className={classNames(styles.deleteBtn, "clearBtn")}
                  onClick={deleteNewCategoryHandler}
                  onMouseEnter={mouseOver}
                  onMouseLeave={mouseLeave}
                >
                  <img
                    src={constants.BASKET_IMG.src}
                    alt={constants.BASKET_IMG.alt}
                  />
                </motion.div>
                <Form
                  className={styles.categorieForm}
                  id={categoryFormId}
                  autoComplete="off"
                >
                  <FormField type={"text"} name={"name"} id={"name"} placeholder={constants.TITLE_PLACEHOLDER} classes={styles.selecorField}/>
                  {/* <Field
                    type="text"
                    name="name"
                    id="name"
                    value={utils.post.decodeHtmlCharCodes(props.values.name)}
                    placeholder={constants.TITLE_PLACEHOLDER}
                    onChange={props.handleChange}
                  /> */}
                  {props.errors.title ? (
                    <motion.div className={styles.error}>
                      <span>{props.errors.title}</span>
                    </motion.div>
                  ) : (
                    ""
                  )}
                  {/* <BaseSubmitButton
                    value={constants.ADD_NEW}
                    className={styles.addCategorieButton}
                  /> */}
                  <BaseSubmitButton title={constants.ADD_NEW} mode="filled" />
                </Form>
                <motion.div className={styles.error}>{generalError}</motion.div>
              </>
            );
          }}
        </Formik>
      </Collapse>
    </>
  );
};
