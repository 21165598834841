import utils from '../../shared/utils'
import environment from "../../constants/environment"

const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

export const REMEMBER_ME_LABEL = 'Login-Daten für die Zukunft speichern'

export const FIELDS = [
  {
    name: 'username',
    type: 'text',
    placeholder: 'Email',
    default: utils.user.getSavedEmail(),
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Passwort',
    default: utils.user.getSavedPassword(),
  },
  {
    name: 'rememberme',
    type: 'checkbox',
    default: '',
    value: REMEMBER_ME_LABEL,
    placeholder: REMEMBER_ME_LABEL,
  },
]

export const BUTTON_NAME = "Anmeldung"
export const SUBTITLE = "Bitte geben Sie für den Wiki Anmeldedaten ein:"

export const LINKS = [
  `Haben Sie Ihr<a href="/reset-password"> Passwort vergessen?</a>`,
  `Oder haben Sie noch kein Konto? <a href="/register">Registrieren</a>`
]

export default {
  REMEMBER_ME_LABEL,
  FIELDS,
  BUTTON_NAME,
  SUBTITLE,
  LINKS,
  THEME_CLASS 
}