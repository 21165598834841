import { motion } from "framer-motion";
import classNames from "classnames";
import useFillHint from "../../../../../../../hooks/fillHint/fillHint";
import styles from "../../blockcontentsection.module.scss";

export const AddContentButton = ({
  type,
  icon,
  title,
  description,
  className="",
  clickHandler = () => {},
  startMode = true,
}) => {
  const { mouseLeave, mouseOver } = useFillHint(description);

  const onButtonClick = (e)=>{
    mouseLeave(e);
    clickHandler(e);
  }
  return (
    <motion.div
      data-type={type}
      className={classNames(
        className,
        styles.buttonContainer,
        !startMode ? styles.usualModeButton : ""
      )}
      onMouseEnter={mouseOver}
      onMouseLeave={mouseLeave}
      onClick={onButtonClick}
    >
      <img className={startMode ? styles.startButton : ''} src={icon.src} alt={icon.alt} />
      {!startMode && (
        <p>
          <span>{title}</span>
        </p>
      )}
    </motion.div>
  );
};
