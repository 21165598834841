import { motion } from "framer-motion";
import classNames from "classnames";
import { SectionTitle } from "../SectionsTitle/SectionTitle";
import useLoadContent from "../../../../../hooks/loadContent/loadContent";
import styles from "../../editarticle.module.scss";
import global from "../../../../../../constants/global";
import { useState } from "react";
import { StandartButton } from "../../../../Buttons/StandartButton/StandartButton";
import constants from "../../constants";

export const SectionFileInput = ({
  title = "",
  name,
  buttonText,
  changeValue,
  errors = "",
  defaultValue = "",
  deleteFromServer,
}) => {
  const [image, setImage] = useState(defaultValue);
  const { prossessFileLoading, generalError } = useLoadContent();
  const clickHandler = () => {
    const item = document.getElementById(`file-image-${name}`);
    item.click();
  };

  const onChangeHandler = (e) => {
    if (e.target && e.target.files && e.target.files[0]) {
      const imageFile = e.target.files[0];
      const callback = function (e) {
        if (e.target && e.target.result) {
          changeValue(name, {
            src: imageFile,
            blockId: "preview",
            alt: "preview-image",
            type: "preview-image",
          });
          setImage({ src: e.target.result });
        }
      };

      prossessFileLoading(e.target.files[0], callback);
    }
  };

  const deleteImage = () => {
    setImage("");
    changeValue(name, "");
    const input = document.getElementById(`file-image-${name}`);
    console.log(input);
    if (input) {
      input.value = "";
    }
    deleteFromServer();
  };

  return (
    <motion.div>
      {title && <SectionTitle title={title} />}
      <motion.div>
        <input
          id={`file-image-${name}`}
          type="file"
          name={"image"}
          onChange={onChangeHandler}
          className={styles.invisible}
          accept={global.FILE_TYPES_FOR_IMAGES.join()}
        />
        <motion.div className={styles.imageLoader} onClick={clickHandler}>
          <img
            src={image.src ? image.src : constants.DEFAULT_LOAD_IMAGE}
            alt={"load image"}
          />
          {image.src && (
            <motion.div
              onClick={deleteImage}
              className={styles.cross}
            ></motion.div>
          )}
        </motion.div>
        {/* <motion.div
          style={{ backgroundImage: `url(${image.src})` }}
          className={classNames(
            styles.imagePreview,
            image ? "" : styles.invisible
          )}
        >
          <motion.div
            onClick={deleteImage}
            className={styles.cross}
          ></motion.div>
        </motion.div>
        <StandartButton
          title={buttonText}
          onClick={clickHandler}
          mode="filled"
        /> */}
        {errors && <label className={styles.error}>{errors}</label>}
        {generalError && (
          <motion.div className={styles.error}>{generalError}</motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};
