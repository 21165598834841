import { motion } from "framer-motion";
import classNames from "classnames";
import { Container } from "../../../_layouts/Container/Container";
import { Collapse } from "../../../Collapse/Collapse";
import PropTypes from "prop-types"
import constants from "./constants";
import styles from "./style.module.scss";

const FaqSection = ({ questions, classes }) => {
  console.log(questions)
  return (
    <Container className={classNames(styles.container, classes)}>
      {questions && (
        <>
          <motion.ul>
            {questions.map((question, index) => (
              <motion.li key={index}>
                <Collapse
                  animationDelay={constants.ANIMATION_DELAY}
                  title={question.title.rendered}
                  key={`question-${index}`}
                  className={styles.questionCollapseContainer}
                >
                  <motion.div
                    className={styles.collapseText}
                    dangerouslySetInnerHTML={{
                      __html: question.content.rendered,
                    }}
                  />
                </Collapse>
              </motion.li>
            ))}
          </motion.ul>
        </>
      )}
    </Container>
  );
};

FaqSection.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.string
}

FaqSection.defaultProps = {
  classes: ''
}

export { FaqSection };
