import images from '../../../../assets/images'
import environment from '../../../../constants/environment'

const settings = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]

const HOME_LINK_TEXT = settings.HOME_LINK_TEXT

const SUBTITLE = settings.SUBTITLE

const MENU_COLOR = settings.COLOR

export const SEARCH_ICON = {
    src: images.searchIcon,
    alt: 'search'
}
export const SERCH_PLACEHOLDER = 'Suche'

export const SEARCH_FIELD_NAME = 'searchValue'

export const SERACH_AUTOCOMPLETE_VAL = 'off'

const SEARCH_DESCRIPTION_SIGN = 'Die Suche läuft bereits...'

const EMPTY_RESULT = 'Die Suche hat keine Ergebnisse geliefert'

export default {
    SEARCH_ICON,
    HOME_LINK_TEXT,
    SUBTITLE, 
    MENU_COLOR,
    SEARCH_FIELD_NAME,
    SERCH_PLACEHOLDER,
    SERACH_AUTOCOMPLETE_VAL,
    SEARCH_DESCRIPTION_SIGN,
    EMPTY_RESULT
}