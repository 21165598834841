import images from "../../../../assets/images/guideline"
import animation from "../../../../constants/animation"

const INFORMATION = 'Information '

const INFO_ICON = {
    src: images.info_icon,
    alt: 'info'
}

const ANIMATION = animation.CARD_VARIANTS

export default {
    INFORMATION,
    INFO_ICON,
    ANIMATION
}