import utils from "../../utils";
import constants from "./constants";
import { useState } from "react";

const useSendQuery = () => {
  const startResult = {
    error: false,
    message: "",
  };
  const [sendingResult, setSendingResult] = useState(startResult);

  const submitFunction = (
    values,
    apiType,
    url,
    extraFunction = () => {},
    extraErrorHandler = () => {},
    textMessages = {
      success: constants.DEFAULT_SUCCESS,
      error: constants.DEFAULT_FAIL,
    }
  ) => {
    setSendingResult({ ...sendingResult, message: constants.LOADING });
   
    const send = async () => {
      utils.api.postData(
        apiType,
        url,
        (response) => {
          setSendingResult({
            ...sendingResult,
            error: false,
            message: textMessages.success,
          });
          extraFunction(response);
        },
        values,
        {},
        (error) => {
          setSendingResult({
            ...sendingResult,
            error: true,
            message: error ? error : constants.DEFAULT_FAIL,
          });
          extraErrorHandler(error ? error : constants.DEFAULT_FAIL);
        }
      );
    };
    try {
      send();
    } catch (error) {
      if (error.data && error.data.message) {
        setSendingResult({
          ...sendingResult,
          error: true,
          message: error.data.message,
        });
      }
    }
    return;
  };

  return {
    sendingResult,
    submitFunction,
  };
};

export default useSendQuery;
