import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { StandartButton } from "../../Buttons/StandartButton/StandartButton";
import classNames from "classnames";
import useTokenValidation from "../../../hooks/tokenValidation/tokenValidation";
import constants from "./constants";
import utils from "../../../utils";
import styles from "./popuppanel.module.scss";

const PopupPanel = ({ hoverFunction, mouseLeaveFunction }) => {
  const navigate = useNavigate();
  const { logOut } = useTokenValidation();
  const user = utils.user.getUserData();
  const [avatar, setAvatar] = useState(constants.DEFAULT_USER_AVATAR?.src);

  useEffect(() => {
    const avatarImage = utils.user.getAvatar()?.src
      ? utils.user.getAvatar().src
      : utils.user.getAvatar();
    utils.mediafiles.checkImageLoading(
      avatarImage,
      constants.DEFAULT_USER_AVATAR?.src,
      setAvatar
    );
  }, []);

  const profilePageRedirect = () => {
    navigate(constants.PROFILE_PAGE_REDIRECT);
    return <Navigate to={constants.PROFILE_PAGE_REDIRECT} />;
  };

  return (
    <motion.div
      className={styles.panelConatiners}
      onMouseEnter={hoverFunction}
      onMouseLeave={mouseLeaveFunction}
    >
      <motion.div className={styles.panelArea}>
        <motion.div className={styles.transparentLevel}>
          <motion.div className={styles.avatarBack}></motion.div>
          <motion.div className={styles.avatarContainer}>
            <img
              className={classNames("avatar", styles.avatarImg)}
              src={avatar}
              alt={constants.DEFAULT_USER_AVATAR?.alt}
            />
          </motion.div>
          <motion.div className={styles.usertextInfo}>
            {console.log(user)}
            {user &&
              (user.user_nicename ||
                user.user_display_name ||
                user.display_name) && (
                <motion.div className={styles.userName}>
                  {utils.post.titleLengthValidation(
                    user.display_name ||
                      user.user_display_name ||
                      user.user_nicename
                  )}
                </motion.div>
              )}
            {user && user.user_email && (
              <motion.div className={styles.userEmail}>
                {user.user_email}
              </motion.div>
            )}
          </motion.div>
          <motion.div className={styles.userButtons}>
            <StandartButton
              title={constants.PROFILE_BUTTON}
              onClick={profilePageRedirect}
              mode={"normal"}
            />
            <StandartButton
              title={constants.LOGOUT}
              onClick={logOut}
              mode={"outlined"}
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

PopupPanel.propTypes = {
  hoverFunction: PropTypes.func,
  mouseLeaveFunction: PropTypes.func,
};

PopupPanel.defaultProps = {
  hoverFunction: () => {},
  mouseLeaveFunction: () => {},
};

export { PopupPanel };
