import { motion } from "framer-motion";
import { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SimpleButton } from "../../Buttons/SimpleButton/SimpleButton";
import { StandartButton } from "../../Buttons/StandartButton/StandartButton";
import { PopupPanel } from "../../Popup/PopupPanel/PopupPanel";
import constants from "./constants";
import styles from "./horizontalmenu.module.scss";

export const HorizontalMenu = ({ children, items, classes }) => {
  const [popupVisibility, setPopUpVisibility] = useState(false);

  const mouseOver = () => {
    setPopUpVisibility(true);
  };

  const mouseLeave = () => {
    setPopUpVisibility(false);
  };
  const chooseButton = (item, index) => {
    switch (item.type) {
      case "Text": {
        return (
          <StandartButton
            key={index}
            title={item.title}
            onClick={item.onClick}
            mode={constants.BUTTON_TYPES[0]}
            variants={constants.CARD_VARIANTS}
          />
        );
      }
      case "UserBtn": {
        const icon = `<img src="${item.icon}" alt="profile" className="${styles.userIcon}" id="profile-icon-avatar"/>`;
        return (
          <>
            <SimpleButton
              icon={item.icon}
              title={icon}
              mouseLeave={mouseLeave}
              mouseOver={mouseOver}
              classes={styles.userButtonConatiner}
              internalClasses={styles.userButtonInternalContainer}
              variants={constants.CARD_VARIANTS}
            >
              {popupVisibility && <PopupPanel hoverFunction={mouseOver} mouseLeaveFunction={mouseLeave} />}
            </SimpleButton>
          </>
        );
      }
      case "FilledText": {
        return (
          <StandartButton
            key={index}
            title={item.title}
            onClick={item.onClick}
            mode={constants.BUTTON_TYPES[1]}
            variants={constants.CARD_VARIANTS}
          />
        );
      }
      case "Overlined":
        return (
          <StandartButton
            key={index}
            title={item.title}
            onClick={item.onClick}
            mode={constants.BUTTON_TYPES[2]}
            variants={constants.CARD_VARIANTS}
          />
        );
      default: {
        return <></>;
      }
    }
  };

  return (
    <motion.ul className={classNames(styles.horizontalMenu, classes)}>
      {items &&
        items.length &&
        items.map((item, index) => (
          <motion.li
            variants={constants.ANIMATION.menu.variants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
            className={styles.menuListItem}
            key={index}
          >
            {chooseButton(item, index)}
          </motion.li>
        ))}
      {children}
    </motion.ul>
  );
};

HorizontalMenu.propTypes = {
  classes: PropTypes.string,
};

HorizontalMenu.defaultProps = {
  classes: "",
};
