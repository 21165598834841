import environment from "../../constants/environment"

const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

const TITLE = 'Seite nicht verfügbar'
//Page is not available
const SUBTITLE = 'Die Website ist in der mobilen Version nicht verfügbar'
//The site is not available on the mobile version

export default {
    TITLE,
    SUBTITLE,
    THEME_CLASS
}