import images from '../../../assets/images'
import environment from '../../../constants/environment';

const settings = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]

export const BANNER_TEXT = settings.DESCRIPTION_SIGN ? settings.DESCRIPTION_SIGN : '';

export const BIG_LOGO = {
  src : images.bigLogo,
  alt: 'ukhd_logo'
}

export const BIG_SIGN = {
  src: settings.LOGO_SIGN ? images[settings.LOGO_SIGN] : images.wikiSign,
  alt: 'wiki'
}

const LOGO_EXISTS = !settings.LOGO_SIGN && !settings.DESCRIPTION_SIGN;

export default {
  BANNER_TEXT,
  BIG_LOGO,
  BIG_SIGN,
  LOGO_EXISTS
}