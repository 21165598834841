import images from "../../../../../../assets/images/index"
import environment from "../../../../../../constants/environment";


const LoadingIcon = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]
.FAVICON


// export const SUCESS_TEXT = "<p>Herzlichen Glückwunsch!</p><p>Ihr Artikel wurde erfolgreich veröffentlicht</p>";
export const SUCESS_TEXT = "<p>Gut gemacht!</p><p>Ihr Artikel wurde erfolgreich zur Moderation eingereicht</p>"
export const FAIL_TEXT = "<p>Irgendetwas läuft schief!</p><p>Der Server hat einen Fehler zurückgegeben.</p>"  
export const LOADING_TEXT = "<p>Laden...</p>"

export const SUCCESS_ICON = {
    src:  LoadingIcon  &&  images[`doneIcon${LoadingIcon}`] ? images[`doneIcon${LoadingIcon}`] : images.doneIconukhd,
    alt: "Success"
}

export const FAIL_ICON = {
    src: images.failIcon,
    alt: "Fail"
}

export const LOADING_ICON = {
    src: LoadingIcon  &&  images[`loadingIcon${LoadingIcon}`] ? images[`loadingIcon${LoadingIcon}`] : images.loadingIconukhd,
    alt: "loading.."
}


export const RESULT_KITS  = [
    {
        text: FAIL_TEXT,
        icon: FAIL_ICON
    },
    {
        text: LOADING_TEXT,
        icon: LOADING_ICON
    },
    {
        text: SUCESS_TEXT,
        icon: SUCCESS_ICON
    },
]

export default {
    SUCESS_TEXT,
    FAIL_TEXT,
    LOADING_TEXT,
    SUCCESS_ICON,
    FAIL_ICON,
    LOADING_ICON,
    RESULT_KITS
}
