import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { Footer } from "../../shared/components/_sections/Footer/Footer";
import { NavSider } from "../../shared/components/_menus/NavSider/NavSider";
import { Container } from "../../shared/components/_layouts/Container/Container";
import { Navigate } from "react-router-dom";
import { Header } from "../../shared/components/_sections/Header/Header";
import { ArticleSection } from "../../shared/components/_sections/_global/ArticleSection/ArtilcleSection";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { AuthorData } from "../../shared/components/AuthorData/AuthorData";
// import { AutomationDataSection } from "../../shared/components/_partials/AutomaticDataSection/AutomaticDataSection";
import { CommentsSection } from "../../shared/components/_sections/CommentsSection/CommentsSection";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import utils from "../../shared/utils";
import { divider } from "../../shared/utils/postHandler";
import useRedirects from "../../shared/hooks/redirects/redirects";
import crud from "../../api/crud";
import api from "../../api/server.api";
import styles from "./postpage.module.scss";

export const PostPage = () => {
  const { slug } = useParams();
  const [menuTree, setmenuTree] = useState([]);
  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [redirectNotFound, setRedirectNotFound] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.user);
  const [visible, setVisibility] = useState(true);
  const { toLoginpage, toNotFoundPage } = useRedirects();

  const postHandler = (result) => {
    const item = Array.isArray(result) ? result[0] : result;
    if (!item || !item.content || !item?.content?.rendered) {
      setRedirectNotFound(true);
      return;
    }
    let postHendeled = item;
    const paragraphList = divider(item.content.rendered);

    setPost({
      ...postHendeled,
      content: {
        rendered: paragraphList,
      },
    });

    if (item.comments) {
      setComments(item.comments);
    }
  };

  const loadInformation = async () => {
    try {
      await Promise.all([
        utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree),
        utils.api.getData(
          api.standart,
          `${crud.standart.POSTS}?slug=${slug}`,
          postHandler
        ),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setVisibility(false);
    }
  };

  useEffect(() => {
    loadInformation();
  }, []);


  useEffect(()=>{
    if(redirectNotFound){
      toNotFoundPage()
    }
  }, [redirectNotFound])

  useEffect(() => {
    loadInformation();
  }, [slug]);

  if (!isLoggedIn) {
    utils.user.setLastPage(window.location.pathname);
    return toLoginpage();
  }

  return (
    <>
      <DefaultPage>
        <Preloader visible={visible} />
        {!visible && (
          <>
            <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
            <Container className={classNames("print-container", styles.container)}>
              <Header />
              <div id={styles.articleConatiner}>
                {post.authomatic_data && post.date && (
                  <AuthorData
                    author={post.authomatic_data}
                    date={post.date}
                  />
                )}
                <ArticleSection post={post} printAvalible={true} inEdit={true}/>

                <CommentsSection
                  postId={post.id}
                  comments={comments}
                  setComments={setComments}
                />
              </div>
              <Footer></Footer>
            </Container>
          </>
        )}
      </DefaultPage>
      {/* {redirectNotFound === true ? (
        <Navigate to="/404" />
      ) : (
        <DefaultPage className="customLayout">
          {!visible && (
            <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
          )}
          <Layout>
            <Preloader visible={visible} />
            {!visible && (
              <>
                <Header />
                <div id="article-container">
                  {post.authomatic_data && post.date && (
                    <AutomationDataSection
                      author={post.authomatic_data}
                      date={post.date}
                    />
                  )}
                  <ArticleSection post={post}>
                  </ArticleSection>
                  <CommentsSection
                    postId={post.id}
                    comments={comments}
                    setComments={setComments}
                  />
                </div>
                <Footer></Footer>
              </>
            )}
          </Layout>
        </DefaultPage>
      )} */}
    </>
  );
};
