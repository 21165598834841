import environment from "../../../../../constants/environment"
import animation from "../../../../../constants/animation"


const FAQ_TITLE = 'Häufig gestellte Fragen'
//'Most frequently asked'

const TITLE_COLOR = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].TITLES_COLOR

const ANIMATION_DELAY = 0.2

const CARD_VARIANTS = animation.CARD_VARIANTS

export default {
    FAQ_TITLE,
    TITLE_COLOR,
    ANIMATION_DELAY,
    CARD_VARIANTS
}