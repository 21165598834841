import { motion } from "framer-motion";
import styles from "../../editarticle.module.scss";

const InputField = ({
  changeValue = ()=>{},
  errors,
  name,
  value,
  placeholder,
  onBlur = ()=>{},
  className
}) => {
  return (
    <motion.div>
      <input
        name={name}
        type="text"
        placeholder={placeholder}
        onChange={changeValue}
        value={value}
        onBlur={onBlur}
        className={className}
      />
      {errors && (
        <motion.div className={styles.error}>
          <span>{errors}</span>
        </motion.div>
      )}
    </motion.div>
  );
};



export {InputField}