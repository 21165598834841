import { motion } from "framer-motion";
import utils from "../../../../../../../../../utils";
import constants from "../../../../constants";

export const FileItemTitle = ({ fileTypeGroup, fileName, id }) => {
  return (
    <motion.div className={"fileListSection_ItemTitle"} data-id={id}>
      <motion.img src={fileTypeGroup.src} alt={fileTypeGroup.alt} />
      <span>{utils.post.titleLengthValidation(utils.mediafiles.fileNameProccess(fileName, constants.FILE_TITLE_LIMIT_LENGTH), 18)}</span>
    </motion.div>
  );
};
