import { motion } from "framer-motion"
import classNames from "classnames"
import styles from "./style.module.scss"

export const Col = ({children, span, className})=>{
    return (
        <motion.div className={classNames(className, styles.column, styles[`column-span-${span}`])}>
            {children}
        </motion.div>
    )
}