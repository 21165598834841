import userData from './userData'
import errorConstants from '../../constants/errors'

//TODO: Set correct error handler
const defaultErrorHandler = (err) => {
  console.log(err)
}

const getData = async (
  axiosType,
  address,
  stateFunction,
  headers = {},
  errorHandler = defaultErrorHandler,
) => {
  try {
    const response = await axiosType.get(
      address,
      userData.headerWithAuth(headers),
    )
    if (response && response.data) {
      stateFunction(response.data)
      return
    }
    errorHandler(errorConstants.EMPTY_ANSWER)
    throw new Error(errorConstants.EMPTY_ANSWER)
  } catch (err) {
    console.log(err)
    if (err.response) {
      if (err.response.status == '401' || err.response.status == '403') {
        userData.resetToken()
        window.location.reload()
      }
      errorHandler(err)
    } else {
      errorHandler(`Error ${err.message}`)
    }
  }
}

const postData = async (
  axiosType,
  address,
  stateFunction,
  data = {},
  headers = {},
  errorHandler = defaultErrorHandler,
) => {
  try {
    const response = await axiosType.post(address, data, userData.headerWithAuth(headers))
    if (response && response.data) {
      stateFunction(response.data)
      return
    }
    throw new Error(errorConstants.EMPTY_ANSWER)
  } catch (err) {
    console.log(err)
    if (err.response) {
      if (err.response.status == '401') {
        userData.resetToken()
        window.location.reload()
      }
      errorHandler(err.response.data.message)
    } else {
      errorHandler(err.toString())
    }
    return
  }
}

const deleteData = async (
  axiosType,
  address,
  stateFunction,
  headers = {},
  errorHandler = defaultErrorHandler,
) => {
  try {
    const response = await axiosType.delete(address,  userData.headerWithAuth(headers))
    if (response && response.data) {
      stateFunction(response.data)
      return
    }
  } catch (err) {
    if (err.response) {
      if (err.response.status == '401') {
        userData.resetToken()
        window.location.reload()
      }
      errorHandler(err.response.data.message)
    } else {
      errorHandler(err)
    }
    return
  }
}

export default {
  getData,
  postData,
  deleteData,
  defaultErrorHandler,
}
