import { motion } from "framer-motion";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { Formik, Field, Form, set } from "formik";
import useLoadContent from "../../../hooks/loadContent/loadContent";
import { SectionEditor } from "../../SectionEditor/SectionEditor";
import { addImageFormSchems } from "../../../../schemes";
import {
  setContent,
  setExtraClick,
} from "../../../../features/asidemenu.status";
import global from "../../../../constants/global";
import constants from "./constants";
import styles from "./imageview.module.scss";
// import { Preloader } from "../../Preloader/Preloader";
import utils from "../../../utils";

export const ImageLoadForm = ({
  id,
  editMode = true,
  contentData,
  onChange,
  imageContentsTypes,
  setActiveImageBlock,
  content,
  // loading = false,
  loadingError = "",
}) => {
  console.log(content)
  const dispatcher = useDispatch();
  const [localError, setLocalError] = useState('')
  const [loading, setLoading] = useState(false)
  const { checkFileSize, prossessFileLoading, generalError } = useLoadContent();
  const [image, setImage] = useState({
    src: content.src ? content.src : constants.DEFAULT_AVATAR.src,
    alt: content.alt ? content.alt : constants.DEFAULT_AVATAR.alt,
  });
  const clickFileLoader = () => {
    const getFileloader = document.getElementById(`src-${id}`);
    getFileloader.click();
  };

  const setActive = (typeId) => {
    setActiveImageBlock(typeId, id);
  };

  const updateGlobalVariables = () => {
    dispatcher(setContent(imageContentsTypes));
    dispatcher(setExtraClick(setActive));
  };

  useEffect(() => {
    if (editMode) {
      updateGlobalVariables();
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      updateGlobalVariables();
    }
  }, [imageContentsTypes]);

  useEffect(() => {
    if (editMode) {
      updateGlobalVariables();
    }
  }, [editMode]);

  useEffect(() => {
    if (content.src) {
      if (typeof content.src !== "string") {
        const callback = (e) => {
          if (e.target.result) {
            setImage({
              src: e.target.result,
              alt: content.alt ? content.alt : image.alt,
            });
          }
        };
        prossessFileLoading(content.src, callback);
      } else {
        setImage({
          src: content.src,
          alt: content.alt ? content.alt : image.alt,
        });
      }
    }
  }, [content]);

  return (
    <>
      <Formik
        initialValues={{
          src: image.src,
          alt: image.alt,
        }}
        validationSchema={addImageFormSchems}
      >
        {(props) => {
          const changeImageHandler = (e) => {
            setLocalError('')
            props.handleChange(e);
            if (e.target.files.length) {
              if (checkFileSize(e.target.files[0])) {
                const imageLink = (img) => {
                  setLoading(false)
                  props.setFieldValue("src", img);
                  onChange({
                    ...props.values,
                    src: img,
                  });
                  if(!img.length){
                    setLocalError(constants.LOCAL_IMAGE_LOAD_ERROR)
                  }
                };
                const checkImageLoad = (e) =>{
                  utils.mediafiles.checkImageLoading(
                  e?.source_url ? e?.source_url : constants.DEFAULT_AVATAR.src,
                  constants.DEFAULT_AVATAR.src,
                  imageLink
                );
                }
                const errorImageLoad = ()=>{
                  setLoading(false)
                  setLocalError(constants.LOCAL_IMAGE_LOAD_ERROR)
                }

                setLoading(true)
                utils.mediafiles.addMediafile({src: e.target.files[0]}, checkImageLoad, (error)=>{ errorImageLoad()})
                
                // imageLink(e.target.value)
              }
            }
          };

          return (
            <Form>
              <motion.div
                className={classNames(
                  contentData.containerClass ? contentData.containerClass : ""
                )}
              >
                <motion.div
                  className={classNames(
                    contentData.itemsClass ? contentData.itemsClass : ""
                  )}
                >
                  <input
                    type="file"
                    name="src"
                    id={`src-${id}`}
                    accept={global.FILE_TYPES_FOR_IMAGES.join()}
                    value={props.values.image}
                    onChange={changeImageHandler}
                    className={styles.invisible}
                    disabled={!loading ? false : true}
                  />
                  <motion.div className={classNames(styles.imageContainer)}>
                    <motion.div
                      className={styles.imageOuter}
                      onClick={!loading ? clickFileLoader : undefined}
                    >
                      {loadingError.length > 0 && (
                        <motion.div className={styles.errorLoading}>
                          <motion.div className={styles.sign}>
                            <span>{loadingError}</span>
                          </motion.div>
                        </motion.div>
                      )}
                      {/* {loading && <Preloader litenTheme={true} />} */}
                      <motion.img
                        className={loading ? styles.loadingImage : ""}
                        src={image.src ? image.src : constants.DEFAULT_AVATAR.src}
                        alt={utils.post.decodeHtmlCharCodes(
                          content.alt ? content.alt : image.alt
                        )}
                        // loading="lazy"
                      />
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div
                  className={classNames(
                    contentData.itemsClass ? contentData.itemsClass : "",
                    contentData.titleClass ? contentData.titleClass : ""
                  )}
                >
                  <motion.div className={styles.imageSign}>
                    {editMode && !loading ? (
                      <>
                        <SectionEditor
                          id={`${id}-text`}
                          editMode={editMode}
                          defaultValue={image.alt}
                          valueCallback={(e) => {
                            const processedE =
                              utils.post.decodeHtmlCharCodes(e);
                            if (
                              !image.alt.includes(processedE) ||
                              (image.alt.includes(processedE) &&
                                image.alt.length != processedE.length)
                            ) {
                              setImage({ ...image, alt: e });
                              onChange({ ...image, alt: e });
                              props.setFieldValue("alt", e);
                            }
                          }}
                        />
                        {/* <Field
                          type="text"
                          name="alt"
                          id="alt"
                          value={content.alt}
                          onChange={(e) => {
                            props.handleChange(e);
                            onChange({ ...props.values, alt: e.target.value });
                          }}
                          disabled={loading}
                        /> */}
                        {/* <motion.div className={styles.inputsSigns}>
                          {constants.ADD_IMAGE_SIGN}
                        </motion.div> */}
                      </>
                    ) : (
                      <motion.div
                        // className={styles.boldText}
                        dangerouslySetInnerHTML={{ __html: image.alt }}
                      />
                    )}
                  </motion.div>
                </motion.div>
              </motion.div>
              {generalError && (
                <motion.div className={"errorField"}>{generalError}</motion.div>
              )}
              {props.errors.alt && (
                <motion.div className={"errorField"}>
                  {props.errors.alt}
                </motion.div>
              )}
              {localError.length>0 && (
                <motion.div className={"errorField"}>
                  {localError}
                </motion.div>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
