import { motion } from "framer-motion";
import { useState } from "react";
import classNames from "classnames";
import { FileItemTitle } from "./components/FileItemTitle/FileItemTitle";
import useFillHint from "../../../../../../../hooks/fillHint/fillHint";
import useLoadContent from "../../../../../../../hooks/loadContent/loadContent";
import utils from "../../../../../../../utils";
import global from "../../../../../../../../constants/global";
import constants from "../../constants";
import styles from "../../blockcontentsection.module.scss";


export const FileSection = ({
  setFileList,
  blockId,
  content,
  block,
  extraDeleteFunction,
}) => {
  const [filesTypes] = useState({
    image: global.FILE_TYPES_FOR_IMAGES,
    pdf: global.PDF_TYPES,
    excel: global.EXCEL_TYPES,
    word: global.WORD_TYPES,
    //NO ICONS IN DESIGN FOR THIS TYPES
    // global.POWER_POINT_TYPES,
    // global.VIDEO_TYPES,
    // global.AUDIO_TYPES
  });
  const [buttonList] = useState([
    {
      ...constants.FILES_BLOCK_BUTTONS[0],
      clickHandler: () => {
        clickOnAddFile();
      },
      mouseLeave: useFillHint(constants.FILES_BLOCK_BUTTONS[0].description)
        .mouseLeave,
      mouseOver: useFillHint(constants.FILES_BLOCK_BUTTONS[0].description)
        .mouseOver,
    },
  ]);
  const { checkFileSize, generalError } = useLoadContent();
  const deleteButtonLeave = useFillHint(
    constants.FILE_ITEM_BUTTONS[0].description
  ).mouseLeave;
  const fileButtons = [
    {
      ...constants.FILE_ITEM_BUTTONS[0],
      mouseLeave: deleteButtonLeave,
      mouseOver: useFillHint(constants.FILE_ITEM_BUTTONS[0].description)
        .mouseOver,
      clickHandler: (e) => {
        deleteFileItem(e);
        deleteButtonLeave();
      },
    },
    {
      ...constants.FILE_ITEM_BUTTONS[1],
      mouseLeave: useFillHint(constants.FILE_ITEM_BUTTONS[1].description)
        .mouseLeave,
      mouseOver: useFillHint(constants.FILE_ITEM_BUTTONS[1].description)
        .mouseOver,
      clickHandler: (e) => {},
    },
  ];

  const deleteFileItem = (e) => {
    const upDateFileList = (list, condition) => {
      setFileList(
        list.reduce((res, item, index) => {
          if (condition(index)) {
            res.push(item);
          }
          return res;
        }, [])
      );
    };

    const deleteFromServer = (deletedElementId) => {
      const digitPartId = deletedElementId.split("file-");
      if (digitPartId.length > 1) {
        const contentElement = document.createElement("div");
        contentElement.innerHTML = content;
        const fileElementInContent = contentElement.querySelector(
          `#mediafile-block-${digitPartId[1]}`
        );
        const serverFileLink = fileElementInContent.querySelector(".file-link");
        if (serverFileLink) {
          const serverId = serverFileLink.getAttribute("data-id");
          extraDeleteFunction(block, serverId);
        }
      }
    };

    const deleteFromLayout = (deletedElementId) => {
      const deletedIndex = deletedElementId.split(`file-${blockId + 1}-`);
      if (deletedIndex.length > 1) {
        const list = block.content,
          allPreviousCondition = (index) => {
            if (utils.text.isNumber(deletedIndex[1])) {
              return Number(index) < Number(deletedIndex[1]);
            }
            return false;
          },
          allExceptDeleted = (index) => index != deletedIndex[1];
        upDateFileList(list, allPreviousCondition);
        setTimeout(() => {
          upDateFileList(list, allExceptDeleted);
        }, 100);
      }
    };

    if (e.currentTarget) {
      const deletedElementId = e.currentTarget.getAttribute("id");
      if (deletedElementId) {
        deleteFromLayout(deletedElementId);
        deleteFromServer(deletedElementId);
      }
    }
  };

  const clickOnAddFile = () => {
    const fileInaput = document.getElementById(`${block.index}-add-file-block`);
    if (fileInaput) {
      fileInaput.click();
      buttonList[0].mouseLeave();
    }
  };

  const fillFiles = (e) => {
    if (e.target && e.target.files) {
      const filesListFormer = (files) => {
        const list = Array.from(files).reduce((res, file) => {
          if (checkFileSize(file, constants.TOO_BIG_FILES)) {
            res.push({
              src: file,
              alt: file.name,
              type: file.type,
            });
          }
          return res;
        }, []);
        return list;
      };
      setFileList(block.content?.concat(filesListFormer(e.target.files)));
    }
  };

  const formLink = (file) => {
    if (file.src) {
      if (typeof file.src == "string") {
        return file.src;
      }
      const blob = new Blob([file.src], { type: file.type });
      return window.URL.createObjectURL(blob);
    }
    return false;
  };

  return (
    <motion.div className={styles.fileSectionContainer}>
      <motion.div className={"fileSectionMenu"}>
        <motion.div className={"fileListSection_title"}>{`${
          global.ATTACHEMANTS
        } (${Array.isArray(block.content) ? block.content?.length : "0"})`}</motion.div>
        {block.inEdit && (
          <motion.div className={styles.buttonListContainer}>
            {buttonList.map((button, index) => (
              <motion.div
                key={index}
                className={styles.fileButtonItem}
                onClick={button.clickHandler}
                onMouseOver={button.mouseOver}
                onMouseLeave={button.mouseLeave}
              >
                <motion.img src={button.icon.src} alt={button.icon.alt} />
              </motion.div>
            ))}
          </motion.div>
        )}
      </motion.div>
      <motion.div className={"fileListSection"}>
        <motion.div className={styles.invisbleFilesAddForm}>
          <input
            id={`${block.index}-add-file-block`}
            type="file"
            onChange={fillFiles}
            accept={Object.keys(filesTypes)
              .map((typeKind) => filesTypes[typeKind].join())
              .join()}
            multiple
          />
        </motion.div>
        {block.content?.map((file, index) => {
          const fileTypeGroup = utils.mediafiles.getTypeImage(
            file.type,
            filesTypes
          );
          return (
            <motion.div
              key={index}
              className={classNames(
                "fileListSection_Item",
                file.loading ? styles.fileLoading : ""
              )}
            >
              {file.error?.length > 0 && (
                <motion.div className={styles.errorLoading}>
                  <motion.div className={styles.sign}>
                    <span>{file.loadingError}</span>
                  </motion.div>
                </motion.div>
              )}
              <motion.div className={"fileListSection_Item_hoverBlock"}>
                <FileItemTitle
                  fileTypeGroup={fileTypeGroup}
                  fileName={file.alt ? file.alt : file.name}
                  id={file.dataId}
                />

                <motion.div className={styles.fileItemButtonsContainer}>
                  {fileButtons.map((button, buttonIndex) => {
                    const href = formLink(file);
                    return (
                      <motion.div
                        key={buttonIndex}
                        className={styles.fileButtonItem}
                        onClick={button.clickHandler}
                        onMouseOver={button.mouseOver}
                        onMouseLeave={button.mouseLeave}
                        id={`file-${blockId + 1}-${index}`}
                      >
                        {button.isLink ? (
                          <motion.a href={href} download>
                            <motion.img
                              src={button.icon.src}
                              alt={button.icon.alt}
                            />
                          </motion.a>
                        ) : (
                          <motion.div className={styles.imageContainer}>
                            <motion.img
                              src={button.icon.src}
                              alt={button.icon.alt}
                            />
                          </motion.div>
                        )}
                      </motion.div>
                    );
                  })}
                </motion.div>
                <motion.div
                  className={styles.fileButtonsConatiner}
                ></motion.div>
              </motion.div>
              <FileItemTitle
                fileTypeGroup={fileTypeGroup}
                fileName={file.alt ? file.alt : file.name}
              />
            </motion.div>
          );
        })}
      </motion.div>
      {generalError && (
        <motion.div className={"errorField"}>{generalError}</motion.div>
      )}
    </motion.div>
  );
};
