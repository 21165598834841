import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import classNames from "classnames";
import { NavSider } from "../../shared/components/_menus/NavSider/NavSider";
import { FaqSection } from "../../shared/components/_sections/_global/FaqSection/FaqSection";
import { Footer } from "../../shared/components/_sections/Footer/Footer";
import { Header } from "../../shared/components/_sections/Header/Header";
import { InformationBlock } from "../../shared/components/_sections/InformationBlock/InformationBlock/InformationBlock";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import useFillHint from "../../shared/hooks/fillHint/fillHint";
import useRedirects from "../../shared/hooks/redirects/redirects";
import api from "../../api/server.api";
import crud from "../../api/crud";
import utils from "../../shared/utils";
import constants from "./constants";
import { Container } from "../../shared/components/_layouts/Container/Container";
import styles from "./homepage.module.scss";

export const HomePage = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [motivationCards, setMotivationCards] = useState([]);
  const [mainPageArticles, setMainPageArticles] = useState([]);
  const [menuTree, setmenuTree] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [visible, setVisibility] = useState(true);
  const { toLoginpage } = useRedirects();
  const { mouseLeave } = useFillHint();

  const loadInformation = async () => {
    try {
      await Promise.all([
        utils.api.getData(
          api.standart,
          crud.standart.MOTIVATION_CARDS,
          setMotivationCards
        ),
        utils.api.getData(
          api.custom,
          `${crud.custom.PREVIEWS}?categories=6`,
          setMainPageArticles
        ),
        utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree),
        utils.api.getData(api.standart, crud.standart.QESTIONS, setQuestions),
      ]);
    } catch (e) {
    } finally {
      setVisibility(false);
    }
  };

  useEffect(() => {
    loadInformation();
    mouseLeave();
  }, []);

  if (!isLoggedIn) {
    return toLoginpage();
  }

  return (
    <>
      <DefaultPage>
        <Preloader visible={visible} />
        {!visible && (
          <>
            <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
            <Container
              className={classNames(styles.homepageBack, styles.container)}
              style={{ backgroundImage: `url(${constants.BACKGROUND.src})` }}
            >
              <Header />
              <InformationBlock
                motivationCards={motivationCards}
                mainPageArticles={mainPageArticles}
              />
              <motion.div
                className={classNames("sub-titles sub-titles-lv1", styles.faqQuestionTitle)}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
              >
                <motion.div
                  variants={constants.CARD_VARIANTS}
                  className={styles.sectionTitle}
                >
                  {constants.FAQ_TITLE}
                </motion.div>
              </motion.div>
              <FaqSection questions={questions} classes={styles.faqQuestionsContainer}/>
              <Footer></Footer>
            </Container>
          </>
        )}
      </DefaultPage>
    </>
  );
};
