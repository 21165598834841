import PropTypes from "prop-types";
import { motion } from "framer-motion";
import classNames from "classnames";
import { Field } from "formik";
import styles from "./fomrfield.module.scss";

const FormField = ({
  type,
  name,
  id,
  classes,
  placeholder,
  error,
  as,
  children,
}) => {
  return (
    <motion.div className={classNames(styles.fieldContainer, classes)}>
      <Field
        type={type}
        as={as}
        name={name}
        id={id}
        className={classNames(
          styles.input,
          as == "textarea" ? styles.textarea : ""
        )}
        placeholder={placeholder}
      ></Field>
      {children}
      {error && (
        <motion.div className={classNames("input-error", styles.errors)}>
          <span>{error}</span>
        </motion.div>
      )}
    </motion.div>
  );
};

FormField.propTypes = {
  type: PropTypes.oneOf(["text", "email", "textarea"]),
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  classes: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  as: PropTypes.string,
  onChange: PropTypes.func,
};

FormField.defaultProps = {
  type: "text",
  name: "",
  id: "",
  classes: "",
  placeholder: "",
  error: undefined,
  as: "",
  onChange: () => {},
};

export { FormField };
