import { motion } from "framer-motion";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { useRef } from "react";
import constants from "./contants";
import styles from "./tabs.module.scss";

export const Tabs = ({ items, defaultActiveKey = 0, className }) => {
  const [widthTitles, setWisthTitles] = useState(false);
  const [widthTitlesStart, setWisthTitlesStart] = useState(false);
  const [currentTab, setCurrentTab] = useState(defaultActiveKey - 1);
  const underline = useRef();

  const getActiveWidth = () => {
    const getAllTabs = document.querySelectorAll(`.${styles.ItemName}`);
    let paddingLeft = 0,
      i = 0;
    while (!getAllTabs[i].classList.contains(styles.active)) {
      paddingLeft += getAllTabs[i].offsetWidth;
      i++;
    }
    const currentWidth = document.querySelector(
      `.${styles.active}`
    ).offsetWidth;
    underline.current.setAttribute(
      "style",
      `width: ${currentWidth}px; left: ${paddingLeft}px;`
    );
  };

  const onClickTab = (e) => {
    const elementNumber = e.target ? e.target.getAttribute("itemIndex") : 0;
    setCurrentTab(elementNumber);
  };

  const moveScrollRight = ()=>{
    setWisthTitles(false)
    const linksItemCotainer = document.querySelector(`.${styles.tabLinks}`)
    console.log(linksItemCotainer)
    if(linksItemCotainer){
        linksItemCotainer.scrollTo({ top: 0, left: 1000, behavior: 'smooth' })
    }
    setWisthTitlesStart(true)
  }
  const moveScrollLeft = ()=>{
    setWisthTitlesStart(false)
    const linksItemCotainer = document.querySelector(`.${styles.tabLinks}`)
    console.log(linksItemCotainer)
    if(linksItemCotainer){
        linksItemCotainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    setWisthTitles(true)
  }

  useEffect(() => {
    getActiveWidth();
    setWisthTitles(true)
    // setWisthTitlesStart(true)
  }, []);

  useEffect(() => {
    getActiveWidth();
  }, [currentTab]);

  return (
    <motion.div
      role="tablist"
      className={classNames(styles.tabsContainer, className)}
    >
      <motion.div className={classNames(styles.tabLinks,"tabs-container")}>
        {items.length > 0 &&
          items.map((item, key) => (
            <motion.div
              itemIndex={key}
              onClick={onClickTab}
              key={key}
              className={classNames(
                styles.ItemName,
                key == currentTab ? styles.active : ""
              )}
            >
              {item.label}
            </motion.div>
          ))}
        <motion.div className={styles.underline} ref={underline}></motion.div>
      </motion.div>
      <motion.div className={styles.TabContent}>
        {items[currentTab].children}
      </motion.div>
      {widthTitles && <motion.div onClick={moveScrollRight} className={styles.arrow}></motion.div>}
      {widthTitlesStart && <motion.div onClick={moveScrollLeft} className={classNames(styles.arrow, styles.arrowStart)}></motion.div>}
    </motion.div>
  );
};
