const UNAVAILABLE_RES_TITLE = 'Die Arbeiten an dieser Adresse sind im Gange'

const UNAVAILABLE_RES_SUBTITLE = 'Es wurden noch nicht alle Einstellungen für diese Domäne hinzugefügt'

const NO_PAGE_TITLE = 'Seite nicht verfügbar'
const NO_PAGE_SUBTITLE = 'Seite konnte nicht geladen werden'


export default {
    UNAVAILABLE_RES_TITLE,
    UNAVAILABLE_RES_SUBTITLE,
    NO_PAGE_TITLE,
    NO_PAGE_SUBTITLE
}