import { useDispatch } from "react-redux";
import { logout } from "../../../features/auth.slice"
import useRedirects from "../redirects/redirects";
import api from "../../../api/server.api"
import crud from "../../../api/crud"
import utils from "../../utils"

const useTokenValidation = ()=>{
    const dispatch = useDispatch();
    const { toLoginpage } = useRedirects();
    const headerWithAuth = utils.user.headerWithAuth();

    const logOut = (e) => {
        utils.user.resetAllData();
        dispatch(logout()).then(() => {
          toLoginpage();
        });
    }

    const checkTokenValidation = async (callback = () => {}, args = {}) => {
        const token = utils.user.getToken()
        const checkTocken = (data) => {
           if (data && data.data && data.data.status == 200) {
             callback(args)
           } else {
             logOut()
           }
        }
      
        if (token) {
           try {
            await utils.api.postData(
              api.custom,
              crud.custom.TOCKEN_VALIDATE,
              checkTocken,
              {},
              headerWithAuth,
              (e)=>{
                logOut()
              },
            )
           } catch (e) {
             logOut()
           }
        } else {
          logOut()
        }
      }

    return {
        checkTokenValidation,
        logOut
    }
}
export default useTokenValidation;