import { motion } from "framer-motion";
import classNames from "classnames";
import { Container } from "../_layouts/Container/Container";
import utils from "../../utils";
import constants from "./constants";
import styles from "./authordata.module.scss";

export const AuthorData = ({ author, date, showImage = true, className }) => {
  return (
    <Container className={classNames(styles.authomaticData, className)}>
      {showImage && (
        <motion.div className={styles.avatar}>
          <img
            className={classNames("avatar", styles.avatarImg)}
            src={
              author.author_avatar
                ? author.author_avatar
                : constants.DEFAULT_AVATAR.src
            }
            alt={constants.DEFAULT_AVATAR.alt}
          />
        </motion.div>
      )}
      <motion.div className={styles.textData}>
        <motion.div className={styles.avatarName}>
          {author.author_name}
        </motion.div>
        <motion.div className={styles.date}>
          {date ? utils.post.postDateFormer(date) : ""}
        </motion.div>
      </motion.div>
    </Container>
  );
};
