export const removerBreakes = (t = '') => {
  return t.replace(/[\n\r]+/g, '').replace(/\s{2,10}/g, ' ')
}

export const adoptBreakets = (t = '') => {
  return removerBreakes(t.replace(/<p><\/p>+/g, '<p><br></p>'))
}

export const divideToParagraphs = (t = '') => {
  //const text = removerBreakes(t)
  const text = adoptBreakets(t)
  const devidedText = text.split('<h2>').reduce((result, part) => {
    try {
      const paragraph = {
        title: part.split('</h2')[0],
        text: part.split('</h2').length > 1 ? part.split('</h2')[1] : '',
      }
      result.push(paragraph)
    } catch (e) {
      return result
    }
  }, [])

  if (devidedText.length == 0) {
    const paragraph = {
      title: `<h2></h2>`,
      text: text,
    }
    devidedText.push(paragraph)
  }
  return devidedText
}

const removeTags = (text) => {
  return text.replace(/<(.|\n)*?>/g, '')
}

function isNumber(str) {
  if (typeof str != 'string') return false
  return !isNaN(str) && !isNaN(parseFloat(str))
}

const searchResultHilighting = (searchString, startString) => {
  if (searchString) {
    const deviding = String(startString.toLowerCase()).indexOf(searchString.toLowerCase())
    if (deviding != -1) {
      return `${deviding - 1 > 0 ? startString.slice(0, deviding - 1) : ''}<span class="resultingPart">${startString.slice(deviding, deviding + searchString.length)}</span>${startString.slice(deviding + searchString.length)}`

    } else {
      return startString
    }
  } else {
    return startString
  }
}

export default {
  divideToParagraphs,
  removerBreakes,
  removeTags,
  isNumber,
  adoptBreakets,
  searchResultHilighting
}
