import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./style.module.scss";

export const PopupHint = () => {
  const { isShown, coords, text } = useSelector(
    (state) => state.hint
  );

  return (
    <motion.div
      id={`icon-hint`}
      className={classNames(
        styles.PopupHintContainer,
        isShown ? "" : styles.hidden
      )}
      style={{
        left: `${coords.x ? coords.x : 0}px`,
        top: `${coords.y ? coords.y : 0}px`,
      }}
    >
      <motion.div className={styles.PopupHint}>
        <span>{text}</span>
      </motion.div>
    </motion.div>
  );
};
