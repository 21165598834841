import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Footer } from "../../shared/components/_sections/Footer/Footer";
import { Header } from "../../shared/components/_sections/Header/Header";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { NavSider } from "../../shared/components/_menus/NavSider/NavSider";
import { Container } from "../../shared/components/_layouts/Container/Container";
import { EditArticleSection } from "../../shared/components/_sections/EditArticleSection/EditArticleSection";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import { setData, show, hide } from "../../features/popup.window";
import useRedirects from "../../shared/hooks/redirects/redirects";
import api from "../../api/server.api";
import crud from "../../api/crud";
import utils from "../../shared/utils/";
import constants from "./constants";
import styles from "./editarticlepage.module.scss";

export const EditArticlePage = () => {
  const { id } = useParams();
  const dispatcher = useDispatch();
  const [editingPost, setEditingPost] = useState({ no_id: id ? false : true });
  const [menuTree, setmenuTree] = useState([]);
  const [visible, setVisibility] = useState(true);
  const [categories, setCategories] = useState([]);
  const { isLoggedIn } = useSelector((state) => state.user);

  const { toNotFoundPage, toPostPage, toLoginpage } = useRedirects();

  const menuReloader = () => {
    utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree);
  };

  const filterCategories = (categories) => {
    setCategories(
      categories.filter(
        (cat) => !constants.EXCLUDED_CATEGORIES.includes(Number(cat.id))
      )
    );
  };

  const checkEditingPost = (post) => {
    if (
      !utils.post.isAuthorCheck(post) &&
      constants.EDITOR_ROLES_LIST.indexOf(utils.user.getUserData().role) == -1
    ) {
      return toPostPage(post.slug);
    } else {
      setVisibility(false);
    }
    setEditingPost(post);
  };

  const loadInformation = async () => {
    const functionsList = [
      utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree),
      utils.api.getData(
        api.standart,
        `${crud.standart.PARENT_CATEGORIES}&per_page=100`,
        filterCategories
      ),
    ];
    if (id) {
      functionsList.push(
        utils.api.getData(
          api.standart,
          `${crud.standart.POSTS}/${id}`,
          checkEditingPost,
          () => {
            toNotFoundPage();
          }
        )
      );
    }

    try {
      await Promise.all(functionsList);
    } catch (e) {
    } finally {
      setVisibility(false);
    }
  };

  useEffect(() => {
    loadInformation();
  }, []);

  if (!isLoggedIn) {
    utils.user.setLastPage(window.location.pathname);
    return toLoginpage();
  }

  return (
    <>
      <DefaultPage>
        <Preloader visible={visible} />
        {!visible && (
          <>
            <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
            <Container
              className={classNames(styles.homepageBack, styles.container)}
            >
              <Header />
              <EditArticleSection
                categories={categories}
                setCategories={setCategories}
                menuReloader={menuReloader}
                thePost={editingPost}
                isNewPost={id}
              />
              {/* <InformationBlock
                motivationCards={motivationCards}
                mainPageArticles={mainPageArticles}
              />
              <FaqSection questions={questions} /> */}
              <Footer></Footer>
            </Container>
          </>
        )}
      </DefaultPage>
    </>
  );
};
