import images from "../../../../../../assets/images"



export const HINT = {
    src: images.hint,
    alt: "hint icon"
}

export const ERROR = {
    src: images.redErrorIcon,
    alt: "error"
}

export default {
    HINT,
    ERROR
}