import { motion } from "framer-motion";
import { useState } from "react";
import { SectionTitle } from "../SectionsTitle/SectionTitle";
import { InputField } from "../InputField/InputField";
import styles from "../../editarticle.module.scss"


export const InputSection = ({
  title = "",
  name,
  placeholder = "",
  changeValue,
  errors = "",
  defaultValue=""
}) => {

  const [value, setValue] = useState(defaultValue)

  const onChnageHandler = (e) => {
    setValue(e.target.value)
    changeValue(name, e.target.value);
  };

  return (
    <motion.div>
      {title && <SectionTitle title={title} />}
      <InputField name={name} changeValue={onChnageHandler} value={value} errors={errors} placeholder={placeholder} className={styles.inputAW}/>
    </motion.div>
  );
};
