import images from "../../../../assets/images";

const DISABLED_ICON = {
    src: images.guidline.flag_empty,
    alt: "flag not added"
}

const ENABLE_ICON = {
    src: images.guidline.flag_filled,
    alt: "added flag"
}

//TODO: Add an icon with tehme color

export default {
    DISABLED_ICON,
    ENABLE_ICON
}