import images from '../assets/images'
import environment from './environment'

const envFavicon =
  environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]?.FAVICON
const FAVICON = envFavicon ? images[`favicon${envFavicon}`] : images.faviconukhd

const TITLE =
  environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]?.TITLE

const ADMIN_ID = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST]?.TECH_ADMIN_ID
    
const HINT_MARGIN = 15

const BYTES_IN_MB = 1048576

const LOADING = 'Herunterladen...'

const NOT_CORRECT_LOGIN_ODER_PASSWORD = 'Kein gültiger Login oder Passwort'

const SOME_FIELDS_HAVE_ERRORS = 'Einige Felder sind falsch ausgefüllt'

const INCORRECT_FORMAT = 'Ungültiges Datenformat'

const BUTONS_MODE_LIST = ["normal", "filled", "outlined", "gray", "grayOutlined"]

const FILE_TYPES_FOR_IMAGES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/webp',
]

const PDF_TYPES = ['.pdf', 'application/pdf']

const EXCEL_TYPES = [
  '.csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsx',
  'xls'
]

const WORD_TYPES = [
  '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.docx',
]

const POWER_POINT_TYPES = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.ppt',
  '.pptx'
]

const VIDEO_TYPES = ['video/webm', 'video/mp4']

const AUDIO_TYPES = ['audio/mpeg', 'audio/mp3', 'audio/wav']

const ATTACHEMANTS = 'Anlage'

const FILE_TYPES_IMAGES = {
  pdf: {
    src: images.pdfFileIcon,
    alt: 'pdf',
  },
  image: { src: images.imagesFileIcon, alt: 'image' },
  excel: { src: images.excelFileIcon, alt: 'excel' },
  word: { src: images.wordFileIcon, alt: 'word' },
  unknownType: { src: '', alt: 'unknown type'}
}

const EDITOR_ROLES =  ['wiki_moderator', 'wiki_gardener', 'administrator'];

const MONTHES = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']

export default {
  FAVICON,
  HINT_MARGIN,
  LOADING,
  TITLE,
  BYTES_IN_MB,
  NOT_CORRECT_LOGIN_ODER_PASSWORD,
  SOME_FIELDS_HAVE_ERRORS,
  INCORRECT_FORMAT,
  FILE_TYPES_FOR_IMAGES,
  PDF_TYPES,
  EXCEL_TYPES,
  WORD_TYPES,
  POWER_POINT_TYPES,
  VIDEO_TYPES,
  AUDIO_TYPES,
  ATTACHEMANTS,
  FILE_TYPES_IMAGES,
  ADMIN_ID,
  EDITOR_ROLES,
  MONTHES,
  BUTONS_MODE_LIST
}
