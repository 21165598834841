import images from "../../../assets/images"

export const BACK = {
    src: images.guidline.gray_backa_arrow,
    alt: "Pfeil zurück",
    text: "Zurück"
}

export const NEXT = {
    src: images.guidline.white_arrow,
    alt: "Pfeil weiter",
    text: "Nächster Schritt"}

const FINAL_STEP = {
    src: images.guidline.white_arrow,
    alt: "Pfeil weiter",
    text: "Veröffentlichen"
}

    
export default {
    BACK,
    NEXT,
    FINAL_STEP
}

