import { Container } from "../../_layouts/Container/Container";
import { NoPageStaticImage } from "./NoPageStaticImage/NoPageStaticImage";
import styles from "./nopagesection.module.scss";

export const NoPageSection = ({ title = "", text = "" }) => {
  return (
    <Container className={styles.ErrorPlug}>
      <NoPageStaticImage title={title} text={text} />
    </Container>
  );
};
