// import { Layout } from "antd";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./style.module.scss";

const Container = ({ children, className, style  }) => {
  return (
    <motion.section className={classNames(styles.container, className)} style={style}>
      {children}
    </motion.section>
  );
};

Container.propTypes  = {
  className: PropTypes.string
}

Container.defaultProps = {
  classNames: ""
}


export { Container };


