const closest = (element, selector) => {
  if (!element) return null
  if (element.matches(selector)) return element
  if (!element.parentElement) return null
  else {
    return closest(element.parentElement, selector)
  }
}

const closestChild = (e, selector) => {
  if (!e) return null
  if (e.matches(selector)) return e
  if (!e.children.length) return null
  return Array.from(e.children).reduce((res, child) => {
    return !res ? closestChild(child, selector) : res
  }, null)
}

export default {
  closest,
  closestChild,
}
