import { useSelector } from "react-redux";
import utils from "../../utils";
import { Navigate, useNavigate } from "react-router-dom";

const useRedirects = () => {
  const { isLoggedIn, user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const toHomepage = () => {
    if (isLoggedIn && user) {
      console.log(isLoggedIn)
      navigate("/");
      return <Navigate to="/" />;
    }
    return false
  };

  const toLoginpage = () => {
    if (!isLoggedIn) {
      navigate("/login");
      return <Navigate to="/login" />;
    }
  };

  const toNotFoundPage = () => {
    navigate(`/404`);
    return <Navigate to={`/404`} />;
  };

  const toPostPage = (slug) => {
    if (slug) {
      navigate(`/posts/${slug}`);
      return <Navigate to={`/posts/${slug}`} />;
    } else {
      return toNotFoundPage();
    }
  };

  const toLastVisitedPage = () => {
    const lastVisitedPage = utils.user.getLastPage();
    if (lastVisitedPage) {
      utils.user.resetLastPage();
      navigate(lastVisitedPage);
      return <Navigate to={lastVisitedPage} />;
    } else {
      return toHomepage();
    }
  };

  return {
    toHomepage,
    toLoginpage,
    toPostPage,
    toNotFoundPage,
    toLastVisitedPage,
  };
};

export default useRedirects;
