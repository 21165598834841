import api from "./apiQueryUtils";
import user from "./userData";
import number from "./numberHandler"
import post from "./postHandler"
import text from "./textHandler"
import elementHandlers from "./customElementHandlers"
import comments from "./commnetsHandler"
import mediafiles from "./mediafilesHandler"
import icons from "./iconsHandler"

export default {
    api,
    user,
    number,
    post,
    text,
    elementHandlers,
    comments,
    mediafiles,
    icons
}