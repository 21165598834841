import classNames from "classnames";
import PropTypes from "prop-types";
import { LoaderIcon } from "./LoaderIcon/LoaderIcon";
import styles from "./preloader.module.scss";

const Preloader = ({ visible, children, className, litenTheme }) => {
  return (
    <div
      className={classNames(
        styles.Preloader,
        className,
        litenTheme ? styles.litenTheme : ""
      )}
      style={{ display: `${visible ? "flex" : "none"}` }}
    >
      <LoaderIcon />
      {children}
    </div>
  );
};

Preloader.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  litenTheme: PropTypes.bool,
};

Preloader.defaultProps = {
  visible: true,
  className: "",
  litenTheme: false,
};

export { Preloader };
