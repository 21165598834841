import { Router } from './shared/components/Router/Router';


function App() {
  return (
      <Router/>
  );
}

export default App;
