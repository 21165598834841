import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import { Row } from "../../../_layouts/Row/Row";
import { CardsHorizontal } from "../../../Cards/CardHorizontal/CardsHorizoantal";
import { Col } from "../../../_layouts/Col/Col";
import { LogoBunner } from "../../../LogoBunner/LogoBunner";
import { Container } from "../../../_layouts/Container/Container";
import utils from "../../../../utils";
import constants from "./constants";
import styles from "./informationblock.module.scss";

export const InformationBlock = ({ motivationCards, mainPageArticles }) => {
  return (
    <Container>
      <Row>
        <Col span={24}>
          <LogoBunner />
        </Col>
      </Row>
      <Row>
        <div className={styles.paddingTop}></div>
      </Row>
      <Row>
        {motivationCards.map((card, index) => (
          <Col span={8} key={index}>
            <AnimatePresence>
              <CardsHorizontal
                title={card.title.rendered}
                key={index}
                preview={utils.text.removeTags(card.content.rendered)}
                darkMode={true}
                classes={styles.noBorderCards}
                variants={constants.ANIMATION.items.variants}
                transition={constants.ANIMATION.items.transition(
                  index * constants.ANIMATION_DELAY_INDEX
                )}
              />
            </AnimatePresence>
          </Col>
        ))}
      </Row>
      <Row>
        <div className={styles.paddingTop}></div>
      </Row>
      <Row>
        <Col span={16}>
          {mainPageArticles.map((article, index) => {
            if (index % 2 == 1) {
              return (
                <AnimatePresence key={index}>
                  <CardsHorizontal
                    title={article.title.rendered}
                    preview={utils.text.removeTags(article.excerpt.rendered)}
                    maxSize={true}
                    date={article.date}
                    author={{
                      avatar: {
                        src: article.author?.avatar ? article.author?.avatar : constants.DEFAULT_AVATAR.src,
                        alt: article.author?.nicname ? article.author?.nicname : '',
                      },
                      name: article.author?.nicname ? article.author?.nicname : ''
                    }}
                    link={article.slug}
                    variants={constants.ANIMATION.items.variants}
                    transition={constants.ANIMATION.items.transition(
                      index * constants.ANIMATION_DELAY_INDEX +
                        constants.ANIMATION_DELAY_INDEX * motivationCards.length
                    )}
                  />
                </AnimatePresence>
              );
            }
          })}
        </Col>
        <Col span={8}>
          {mainPageArticles.map((article, index) => {
            if (index % 2 == 0) {
              return (
                <AnimatePresence key={index}>
                  <CardsHorizontal
                    title={article.title.rendered}
                    preview={utils.text.removeTags(article.excerpt.rendered)}
                    variants={constants.ANIMATION.items.variants}
                    date={article.date}
                    author={{
                      avatar: {
                        src: article.author.avatar,
                        alt: article.author.nicname,
                      },
                      name: article.author.nicname
                    }}
                    link={article.slug}
                    transition={constants.ANIMATION.items.transition(
                      index * constants.ANIMATION_DELAY_INDEX +
                        constants.ANIMATION_DELAY_INDEX *
                          motivationCards.length +
                        constants.ANIMATION_DELAY_INDEX *
                          mainPageArticles.length
                    )}
                  />
                </AnimatePresence>
              );
            }
          })}
        </Col>
      </Row>
    </Container>
  
  );
};
