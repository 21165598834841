import { motion } from "framer-motion";
import classNames from "classnames";
import { useState } from "react";
import { Row } from "../../../../_layouts/Row/Row";
import { BasicCommentForm } from "../../../../_forms/BasicCommentForm/BasicCommentForm";
import { editCommentFormSchems } from "../../../../../../schemes";
import { addCommentFormSchems } from "../../../../../../schemes";
import api from "../../../../../../api/server.api";
import crud from "../../../../../../api/crud";
import utils from "../../../../../utils";
import constants from "../../constants";
import styles from "../../commentssection.module.scss";

export const Comment = ({
  comment,
  updateComment,
  addComment,
  children,
  deleteComment,
  inEdit = false,
}) => {
  const [generalError, setGeneralError] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [editMode, setEditMode] = useState(inEdit);
  const [networkError, setNetworkError] = useState(false);
  const [newReplayInProccess, setNewReplayInProcess] = useState(false);
  const editFormDefaultValues = {
    id: comment.id,
    content:
      comment.content && comment.content.rendered
        ? utils.text.removeTags(comment.content.rendered)
        : utils.text.removeTags(comment.content),
    parent: comment.parent,
    post: comment.post,
  };
  const addNewReplayFormDefaultValues = {
    content: "",
    parent: comment.id,
    post: comment.post,
  };

  const featuresList = [
    {
      title: constants.EDIT_BUTTON_TITLE,
      action: () => {
        editCommentButton();
      },
      onlyAuthor: true,
    },
    {
      title: constants.DELETE_BUTTON_TITLE,
      action: () => {
        deleteCommentFromServer(comment);
      },
      onlyAuthor: true,
      moderatable: true,
    },
    {
      title: constants.REPLAY_BUTTON_TITLE,
      action: () => {
        replayComment();
      },
      onlyAuthor: false,
    },
  ];

  const replayComment = () => {
    setNewReplayInProcess(true);
  };

  const editCommentButton = () => {
    setEditMode(true);
  };

  const closeEditModeComment = () => {
    setEditMode(false);
    setNetworkError(false);
  };

  const showParameter = (onlyAuthor) => {
    return (
      (onlyAuthor &&
        comment.author &&
        comment.author == utils.user.getUserID()) ||
      !onlyAuthor
    );
  };

  const updateCommentOnClientSude = (response) => {
    if (response.data && response.data.content) {
      updateComment({
        ...comment,
        content: { rendered: utils.text.removeTags(response.data.content) },
      });
      setEditMode(false);
    }
  };

  const updateCommentOnServer = (values, { resetForm }) => {
    if (values.content) {
      utils.api.postData(
        api.custom,
        `${crud.custom.COMMENT_EDIT}/${values.id}`,
        (result) => {
          updateCommentOnClientSude(result);
          resetForm({ values: { post: comment.id, content: "" } });
        },
        {
          id: values.id,
          content: values.content,
        },
        {},
        (e) => {
          console.log(e);
          resetForm({ values: values });
          // networkError(e)
        }
      );
    }
  };

  const deleteCommentFromServer = () => {
    deleteComment(comment, setListLoading);
    utils.comments.deleteCommentFunction(
      comment,
      () => {
        deleteComment(comment, setListLoading);
        //deleteComment(comment, setListLoading);
      },
      (e) => {
        console.log(e);
      },
      constants.THIS_COMMENT_COULD_NOT_BE_DELETED
    );
  };

  const getCurrentUserLikeStatus = () => {
    // console.log(comment.liked_users);
    return (
      comment.liked_users &&
      comment.liked_users.indexOf(String(utils.user.getUserID())) != -1
    );
  };

  const showLikesCondition = (likesArray) => {
    return Array.isArray(likesArray) && likesArray.length > 0;
  };

  const changeLikesQuantity = () => {
    const currentUserId = utils.user.getUserID();
    const updatedComment = comment;
    if (!showLikesCondition(updatedComment.liked_users)) {
      updatedComment["liked_users"] = [];
    }
    let likeList = updatedComment.liked_users.reduce((res, userId) => {
      if (userId != currentUserId) {
        res.push(userId);
      }
      return res;
    }, []);
    if (updatedComment.liked_users.length == likeList.length) {
      likeList.push(currentUserId);
    }
    updateComment({ ...updatedComment, liked_users: likeList });
  };

  const likesUpdateOnServer = () => {
    changeLikesQuantity();
    const serverResult = (result) => {
      if (Array.isArray(result)) {
        return true;
      }
      setGeneralError(constants.STRANGE_LIKE_RESPONSE_FROM_SERVER);
      changeLikesQuantity();
    };

    const errorHandler = (e) => {
      setGeneralError(constants.featuresList);
      changeLikesQuantity();
    };

    try {
      utils.api.postData(
        api.custom,
        crud.custom.LIKES,
        serverResult,
        {
          comment: comment.id,
        },
        {}
      );
    } catch (e) {
      errorHandler(e);
    }
  };

  return (
    <>
      <motion.div className={styles.commnetItem}>
          <motion.div
            className={styles.authorAvatarContainer}
            style={{
              backgroundImage: `url(${
                comment && comment.author_avatar ? comment.author_avatar : ""
              })`,
            }}
          ></motion.div>
          <motion.div className={styles.commentData}>
            <motion.div className={styles.commentMetaInfo}>
              <motion.div
                className={classNames(
                  styles.authorTitle,
                  styles.commentMetaInfoPart
                )}
              >
                {comment.author_name}
              </motion.div>
              <motion.div className={styles.commentMetaInfoPart}>
                {utils.post.postDateFormer(comment.date)}
              </motion.div>
            </motion.div>
            {editMode ? (
              <BasicCommentForm
                commentId={comment.id}
                closeFunction={closeEditModeComment}
                submitFunction={updateCommentOnServer}
                defaultValues={editFormDefaultValues}
                validationSchema={editCommentFormSchems}
              >
                {networkError && (
                  <motion.div className={styles.fieldError}>
                    <span>{networkError}</span>
                  </motion.div>
                )}
              </BasicCommentForm>
            ) : (
              <motion.div
                className={styles.commnetText}
                dangerouslySetInnerHTML={{
                  __html: comment.content && comment.content.rendered,
                }}
              />
            )}
            {!editMode && (
              <>
                <motion.div className={styles.commentFeaturesContainer}>
                  <motion.div className={styles.textButtons}>
                    {featuresList.length > 0 &&
                      featuresList.map((feature, index) => {
                        return showParameter(feature.onlyAuthor) || feature.moderatable && utils.user.getUserData().role == constants.MODERATOR_ROLE ? (
                          <motion.div
                            className={styles.featureItem}
                            onClick={feature.action}
                            key={`feature-${index + 1}`}
                          >
                            {feature.title}
                          </motion.div>
                        ) : (
                          ""
                        );
                      })}
                  </motion.div>
                  <motion.div className={styles.likeButton}>
                    <motion.img
                      className={classNames(
                        getCurrentUserLikeStatus() ? styles.visible : ""
                      )}
                      onClick={likesUpdateOnServer}
                      src={constants.ACTIVE_LIKE_BTN.src}
                      alt={constants.ACTIVE_LIKE_BTN.alt}
                    />

                    <motion.img
                      className={
                        getCurrentUserLikeStatus() ? "" : styles.visible
                      }
                      onClick={likesUpdateOnServer}
                      src={constants.GRAY_LIKE_BTN.src}
                      alt={constants.GRAY_LIKE_BTN.alt}
                      whileTap={{ scale: 0.5 }}
                    />
                    <span
                      className={classNames(
                        styles.quantity,
                        showLikesCondition(comment.liked_users) ? "" : "",
                        getCurrentUserLikeStatus() ? styles.active : ""
                      )}
                    >
                      {showLikesCondition(comment.liked_users)
                        ? comment.liked_users.length
                        : 0}
                    </span>
                  </motion.div>
                </motion.div>
              </>
            )}
            {generalError && (
              <motion.div className={styles.commentError}>
                {generalError}
              </motion.div>
            )}

            {listLoading && (
              <motion.div className={styles.loadingCommentList}>
                {constants.LOADING_TEXT}
              </motion.div>
            )}
            {newReplayInProccess && (
              <BasicCommentForm
                closeFunction={() => {
                  setNewReplayInProcess(false);
                }}
                submitFunction={(value, { resetForm }) => {
                  addComment(value, { resetForm }, setListLoading);
                  setNewReplayInProcess(false);
                }}
                defaultValues={addNewReplayFormDefaultValues}
                validationSchema={addCommentFormSchems}
              >
                {networkError && (
                  <motion.div className={styles.fieldError}>
                    <span>{networkError}</span>
                  </motion.div>
                )}
              </BasicCommentForm>
            )}
            {children}
          </motion.div>
      </motion.div>
    </>
  );
};
