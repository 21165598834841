import { motion } from "framer-motion";
import { Formik, Field, Form } from "formik";
import classNames from "classnames";
import PropTypes from "prop-types";
import constants from "./constants";
import styles from "./singlefield.module.scss";

const SingleField = ({
  submitHandler,
  autoComplete,
  onKeyUp,
  title,
  placeholder,
  icon,
}) => {
  const vals = {};
  vals[title] = "";
  return (
    <Formik
      initialValues={vals}
      onSubmit={(values) => {
        submitHandler(values[title]);
      }}
    >
      {(props) => (
        <Form
          className={classNames(
            styles.form,
            Object.keys(props.errors).length ? styles.hasErrors : ""
          )}
          autoComplete={autoComplete}
        >
          <motion.button type="submit" className={styles.button}>
            <motion.img
              src={icon.src}
              alt={icon.alt}
              whileTap={{ scale: 0.3 }}
            />
          </motion.button>
          <Field
            id={title}
            name={title}
            type={"text"}
            placeholder={placeholder}
            onKeyUp={onKeyUp}
            className={styles.field}
          />
        </Form>
      )}
    </Formik>
  );
};

SingleField.propTypes = {
  submitHandler: PropTypes.func.isRequired,
  autoComplete: PropTypes.oneOf(["off", "on"]),
  onKeyUp: PropTypes.func,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
};

SingleField.defaultProps = {
  autoComplete: "on",
  onKeyUp: () => {},
  placeholder: "",
  icon: constants.DEFAULT_ICON, 
};

export { SingleField };
