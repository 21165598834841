import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { AddContentButton } from "./components/AddContentButton/AddContentButton";
import { SectionEditor } from "../../../../SectionEditor/SectionEditor";
import { FunctionalButton } from "./components/FunctionalButton/FunctionalButton";
import { FileSection } from "./components/FileSection/FileSection";
import { ImageLoadForm } from "../../../../_forms/ImageLoadForm/ImageViewFrom";
import { setStatus } from "../../../../../../features/asidemenu.status";
import utils from "../../../../../utils";
import global from "../../../../../../constants/global";
import constants from "./constants";
import styles from "./blockcontentsection.module.scss";

export const BlockContentSection = ({
  content = "",
  blockList,
  setBlockList,
  setContent,
  setError,
  setLoadingInProcess,
  deletedMediafiles,
  setIsPaused,
  saveDraftCallback,
}) => {
  const dispatcher = useDispatch();
  const [contentTypes] = useState(constants.CONTENT_TYPES);
  const [showContentTypes, setShowContentTypes] = useState(null);

  /* Functional buttons and there functions */
  const beforeUpdateBlockList = (notChangedBlockList) => {
    setBlockList(notChangedBlockList);
  };

  const blockContentButtons = [
    {
      ...constants.BLOCK_FUNCTIONAL_BUTTONS[4],
      clickHandler: (index) => {
        dispatcher(setStatus(true));

        setActiveImageBlock(1, blockList[index]);
      },
      isAvalibleCondition: (index, block) => {
        const currentBlock = getBlockByIndex(block.index);
        if (currentBlock) {
          return currentBlock.type == "image";
        }
        return false;
      },
    },
    {
      ...constants.BLOCK_FUNCTIONAL_BUTTONS[0],
      clickHandler: (index) => {
        deleteBlock(index);
      },
      isAvalibleCondition: () => true,
    },
    {
      ...constants.BLOCK_FUNCTIONAL_BUTTONS[1],
      clickHandler: (index) => {
        copyBlock(index);
      },
      isAvalibleCondition: () => true,
    },
    {
      ...constants.BLOCK_FUNCTIONAL_BUTTONS[2],
      clickHandler: (index) => {
        if (blockMoveButton(index, (digitIndex) => digitIndex > 0)) {
          moveUp(index);
        }
      },
      isAvalibleCondition: (index) => index > 0,
    },
    {
      ...constants.BLOCK_FUNCTIONAL_BUTTONS[3],
      clickHandler: (index) => {
        if (
          blockMoveButton(
            index,
            (digitIndex) => digitIndex <= blockList.length - 1
          )
        ) {
          moveDown(index);
        }
      },
      isAvalibleCondition: (index) => index < blockList.length - 1,
    },
  ];

  const copyBlock = (index) => {
    let copiedBlock = getBlockByIndex(index);
    if (copiedBlock) {
      const currentBlockIndex = getBlockIndexInArray(
        (block) => block.index == index
      );
      switch (copiedBlock.type) {
        case "text":
          AddTextBlock(copiedBlock.content, true, currentBlockIndex);
          break;
        case "image":
          AddImageBlock(copiedBlock.content, true, currentBlockIndex);
          break;
        case "file":
          AddFileBlock(
            Array.isArray(copiedBlock.content) ? copiedBlock.content : "",
            true,
            currentBlockIndex
          );
          break;
        default:
          break;
      }
    }
    setError("");
  };

  const deleteBlock = (index) => {
    setLoadingInProcess(true);
    const newBlockList = blockList.filter((block) => block.index != index);
    updateBlockListFunctions(newBlockList);
    const deleted = getBlockByIndex(index);
    if (deleted && constants.MEDIAFILES_TYPES.includes(deleted.type)) {
      if (deleted.type) {
        deleteMediaFileFromServer(deleted);
      }
    }
    setError("");
  };

  const moveUp = (index) => {
    setLoadingInProcess(true);
    const findIndexCondition = (block) => block.index == index;
    const indexBlockList = getBlockIndexInArray(findIndexCondition);
    if (indexBlockList > 0) {
      let movingBlock = getBlockByIndex(index);
      if (movingBlock) {
        const newBlockList = blockList.map((block) => block);
        newBlockList[indexBlockList] = newBlockList[indexBlockList - 1];
        newBlockList[indexBlockList] = {
          ...newBlockList[indexBlockList],
          index: movingBlock.index,
        };
        movingBlock = {
          ...movingBlock,
          index: newBlockList[indexBlockList - 1].index,
        };
        newBlockList[indexBlockList - 1] = movingBlock;
        setTimeout(() => {
          updateBlockListFunctions(newBlockList);
          setLoadingInProcess(false);
        }, 300);
      }
    }
    setError("");
  };

  const moveDown = (index) => {
    setLoadingInProcess(true);
    const findIndexCondition = (block) => block.index == index;
    const indexBlockList = getBlockIndexInArray(findIndexCondition);
    if (indexBlockList < blockList.length) {
      let movingBlock = getBlockByIndex(index);
      if (movingBlock) {
        const newBlockList = blockList.map((block) => block);
        newBlockList[indexBlockList] = newBlockList[indexBlockList + 1];
        newBlockList[indexBlockList] = {
          ...newBlockList[indexBlockList],
          index: movingBlock.index,
        };
        movingBlock = {
          ...movingBlock,
          index: newBlockList[indexBlockList + 1].index,
        };
        newBlockList[indexBlockList + 1] = movingBlock;
        setTimeout(() => {
          updateBlockListFunctions(newBlockList);
          setLoadingInProcess(false);
        }, 300);
      }
    }
    setError("");
  };

  const blockMoveButton = (index, condition) => {
    const digitIndex = getBlockIndexInArray((block) => block.index == index);
    if (digitIndex != -1) {
      setError("");
      return condition(digitIndex);
    } else {
      setError(constants.CANT_SET_BLOCKER);
      return false;
    }
  };

  const blockClick = (e) => {
    switchToEditMode(e.currentTarget);
    blockContentButtons.forEach((item) => {
      const detectClickedButtonCondition =
        e.target &&
        e.target.classList.contains(item.title) &&
        e.currentTarget &&
        utils.elementHandlers.closestChild(e.currentTarget, `.${item.title}`);
      if (detectClickedButtonCondition) {
        item.clickHandler(e.currentTarget.getAttribute("index"));
      }
    });
    setError("");
  };

  /* end functional block */

  /* Helpers (some process functions) */
  const getBlockByIndex = (index) => {
    let searchingBlock = blockList.reduce(
      (res, block) => (block.index == index ? block : res),
      null
    );
    if (searchingBlock) {
      return searchingBlock;
    }
    return null;
  };

  const getBlockIndexInArray = (condition) => {
    return blockList.reduce((res, block, index) => {
      return condition(block) ? index : res;
    }, -1);
  };

  /* end Helpers (some process functional) */

  const switchToEditMode = (e) => {
    const blockId = e.getAttribute("index");
    const currentBlock = getBlockByIndex(blockId);
    if (currentBlock && !currentBlock.inEdit) {
      setBlockList(
        blockList.map((block) => {
          return { ...block, inEdit: block.index == blockId };
        })
      );
    }
    setError("");
  };

  const showAddBlockArea = (e) => {
    if (!e.currentTarget.classList.contains(styles.blocked)) {
      if (e.currentTarget) {
        const elementIndex = e.currentTarget.getAttribute("index");
        setShowContentTypes(
          elementIndex != showContentTypes ? elementIndex : null
        );
      }
      setError("");
    }
  };

  const updateContentField = (blockListArray, needExtraSave = false) => {
    const updatedContent = blockListArray
      .map((block) => {
        switch (block.type) {
          case "text":
            return `<div class="content-block" parent-block-index="${block.index}">${block.content}</div>`;
          case "image":
            const activeTypeIndex = block.imageContentsTypes.length
              ? block.imageContentsTypes.reduce(
                  (res, type, index) => (type.isActive ? index : res),
                  -1
                )
              : -1;
            const activeType =
              activeTypeIndex >= 0
                ? block.imageContentsTypes[activeTypeIndex]
                : null;
            if (activeType) {
              return `<div class="content-block"><div class="image-mediafile mediafile" parent-block-index="${
                block.index
              }" id="mediafile-${
                block.index
              }" data-type="${activeTypeIndex}"><div class="${
                activeType.containerClass
              }"><div class="${activeType.itemsClass}"><img data-id="${
                block.content?.dataId ? block.content.dataId : "none"
              }" loading="lazy" src="${
                block.content && typeof block.content.src == "string"
                  ? block.content.src
                  : "mediafile-" + block.index
              }" alt="${
                block.content.alt
                  ? utils.text.removeTags(block.content.alt).substr(0, 100)
                  : ""
              }"/></div><div class="${activeType.itemsClass} ${
                activeType.titleClass
              }"><span>${
                block.content.alt ? block.content.alt : ""
              }</span></div></div></div></div>`;
            }
            return "";
          case "file":
            const filesTypes = {
              image: global.FILE_TYPES_FOR_IMAGES,
              pdf: global.PDF_TYPES,
              excel: global.EXCEL_TYPES,
              word: global.WORD_TYPES,
              //NO ICONS IN DESIGN FOR THIS TYPES
              // global.POWER_POINT_TYPES,
              // global.VIDEO_TYPES,
              // global.AUDIO_TYPES
            };
            if (Array.isArray(block.content)) {
              return `<div class="content-block"><div class="fileSectionMenu"><div class="fileListSection_title">${
                global.ATTACHEMANTS
              }(${
                block.content.length
              }):</div></div><div class="fileListSection file-mediafile">${block.content.reduce(
                (res, file, index) => {
                  const shortTitle = utils.mediafiles.fileNameProccess(
                      file.alt && file.alt != "" ? file.alt : file.name,
                      constants.FILE_TITLE_LIMIT_LENGTH
                    ),
                    fileTypeGroup = utils.mediafiles.getTypeImage(
                      file.type,
                      filesTypes
                    );
                  return res.concat(
                    `<div class="file-mediafile mediafile" parent-block-index="${
                      block.index
                    }" id="mediafile-${
                      block.index
                    }-${index}"><div class="fileListSection_Item">
                    <div class="fileListSection_TitleContainer"><a class="fileListSection_ItemTitle"><img src="${
                      fileTypeGroup.src
                    }" alt="${
                      fileTypeGroup.alt
                    }"/><span>${shortTitle}</span></a></div><div class="fileListSection_Item_hoverBlock"><div class="fileListSection_ItemTitle"><img src="${
                      fileTypeGroup.src
                    }" alt="${
                      fileTypeGroup.alt
                    }"><span>${shortTitle}</span></div><div class="fileItemButtonsContainer"><div class="fileButtonItem"><a data-id="${
                      file.dataId ? file.dataId : "none"
                    }" data-type="${file.type}" class="file-link" href="${
                      typeof file.src == "string"
                        ? file.src
                        : `link-${block.index}-${index}`
                    }" download><img src="${
                      constants.FILE_ITEM_BUTTONS[1].icon.src
                    }" alt="${
                      constants.FILE_ITEM_BUTTONS[1].icon.alt
                    }"/></a></div></div></div></div></div>`
                  );
                },
                ""
              )}</div></div>`;
            }
            return "";
          default:
            break;
        }
      })
      .join("");
    setContent("content", updatedContent);
    if (needExtraSave) {
      saveDraftCallback(null, { content: updatedContent });
    }
  };

  const updateMediafiles = (BL) => {
    let blockListArray = BL;
    const blocksWithMediaFiles = blockListArray.filter(
      (block) => block.type && constants.MEDIAFILES_TYPES.includes(block.type)
    );
    const updateBlockList = (
      updateData,
      fieldName,
      fileData = 0,
      uploadImmidiate = false
    ) => {
      const newBlockList = blockListArray.map((item) => {
        if (item.index == fileData.index || !fileData) {
          const updatedItem = item;
          updatedItem[fieldName] = updateData;
          return updatedItem;
        }
        return item;
      });
      setBlockList(newBlockList);
      updateContentField(newBlockList, uploadImmidiate);
    };

    const changeStatusButton = (isBlocked) => {
      const addBlockButton = document.querySelectorAll(
        `.${styles.addBlockButtonContainer}`
      );
      Array.from(addBlockButton).forEach((addButton) => {
        if (isBlocked) {
          addButton.classList.add(styles.blocked);
        } else {
          addButton.classList.remove(styles.blocked);
        }
      });
    };

    const preprocessFileUploading = (fileData) => {
      changeStatusButton(true);
      switch (fileData.type) {
        case "image":
          updateBlockList(
            { ...fileData.content, loading: true },
            "content",
            fileData
          );
          // updateBlockList(true, "loading", fileData);
          setIsPaused(true);
          break;
        case "file":
          let updatedContent = [];
          if (Array.isArray(fileData.content)) {
            updatedContent = fileData.content?.map((fileItem) => {
              if (!fileItem.blockId && fileItem.src instanceof File) {
                setIsPaused(true);
                return { ...fileItem, loading: true };
              }
              return fileItem;
            });
          }
          updateBlockList(updatedContent, "content", fileData);
          break;
        default:
          break;
      }
    };

    const checkLoading = () => {
      let pause = false;
      blockListArray.forEach((block) => {
        if (block.type && constants.MEDIAFILES_TYPES.includes(block.type)) {
          if (Array.isArray(block.content)) {
            block.content.forEach((conentItem) => {
              if (conentItem.loading) {
                pause = true;
              }
            });
          } else {
            if (block.content.loading) {
              pause = true;
            }
          }
        }
      });
      setIsPaused(pause);
    };

    const updateContent = (data) => {
      const indexParts = data.title?.rendered.split("-");
      if (indexParts.length > 3) {
        //update final article layout
        const links = document
          .querySelector(`#${data.title?.rendered}`)
          ?.querySelectorAll("a");
        if (Array.from(links).length > 0) {
          Array.from(links).forEach((link) => {
            link.setAttribute("data-id", data.id);
            link.setAttribute("href", data.source_url);
          });
        }
        //update data in internal variables
        const parentBlockId = indexParts
          .filter((item, index) => index != indexParts.length - 1 && index != 0)
          .join("-");
        const parentBlock = getBlockByIndex(parentBlockId);
        let contentData = parentBlock.content;
        contentData[Number(indexParts[indexParts.length - 1])] = {
          ...contentData[Number(indexParts[indexParts.length - 1])],
          dataId: data.id,
          loading: false,
          error: "",
          src: data.source_url,
        };
        updateBlockList(contentData, "content", parentBlock);
      } else {
        //process of images
        const finalImage = document
          .querySelector(`#${data.title.rendered}`)
          ?.querySelector("img");
        const finalDescription = document
          .querySelector(`#${data.title.rendered}`)
          ?.querySelector("span");

        //updating a final layout
        const altText = data.alt_text ? data.alt_text : "";
        finalImage.setAttribute("src", data.source_url);
        finalImage.setAttribute("alt", altText);
        finalImage.setAttribute("data-id", data.id);
        finalDescription.innerHTML = altText;
        //updating internal variables
        const fileData = getBlockByIndex(
          data.title?.rendered?.split("mediafile-")[1]
        );
        updateBlockList(
          {
            ...fileData?.content,
            src: data.source_url,
            alt: altText,
            dataId: data.id,
            loading: false,
            error: "",
          },
          "content",
          fileData,
          true
        );
      }
      checkLoading();
      changeStatusButton(false);
    };

    const errorHandler = (error, blockId = 0) => {
      const item = getBlockByIndex(blockId);
      const indexParts = item?.title?.rendered.split("-");
      if (Array.isArray(indexParts) && indexParts.length > 2) {
        let contentData = item.content;
        contentData[Number(indexParts[indexParts.length - 1])] = {
          ...contentData[Number(indexParts[indexParts.length - 1])],
          loading: false,
          error:
            error == constants.MEMORY_LIMIT_ERROR
              ? constants.MEMORY_LIMIT_DE_ERROR
              : constants.FILE_LOAD_ERROR,
        };
        updateBlockList(contentData, "content", item);
      } else {
        updateBlockList(
          {
            ...item.content,
            loading: false,
            error: error,
          },
          "content",
          item
        );
      }
      checkLoading();
      changeStatusButton(false);
    };

    blocksWithMediaFiles.forEach((file) => {
      if (file.inEdit) {
        switch (file.type) {
          case "image":
            if (file.content?.src instanceof File) {
              preprocessFileUploading(file);
              utils.mediafiles.addMediafile(
                { ...file.content, blockId: file.index },
                updateContent,
                errorHandler
              );
            }
            break;
          case "file":
            if (Array.isArray(file.content)) {
              file.content.forEach((fileListItem, i) => {
                if (!fileListItem.blockId && fileListItem.src instanceof File) {
                  preprocessFileUploading(file);
                  utils.mediafiles.addMediafile(
                    { ...fileListItem, blockId: `${file.index}-${i}` },
                    updateContent,
                    errorHandler
                  );
                }
              });
            }
            break;
          default:
            break;
        }
      }
    });
  };

  function updateBlockListFunctions(newBlockList,
    changeIndex = -1,
    pause = 100,
    needFileLoad = true) {
    if (changeIndex > -1) {
      beforeUpdateBlockList(
        newBlockList.reduce((res, item, index) => {
          if (index <= changeIndex) {
            res.push(item);
          }
          return res;
        }, [])
      );
    }
    setTimeout(() => {
      setBlockList(newBlockList);
      updateContentField(newBlockList);
      if (needFileLoad) {
        updateMediafiles(newBlockList);
      }
      setLoadingInProcess(false);
    }, pause);
  }

  const deleteMediaFileFromServer = (fileData, fileId = -1) => {
    const callback = () => {
      if (fileId > -1) {
        const newBlockList = blockList.map((block) => {
          if (block.index != fileData.index) {
            return {
              ...block,
              content: block.content.filter((item) => item.dataId == fileId),
            };
          }
        });
        updateBlockListFunctions(newBlockList);
      }
    };

    const errorHandler = (error) => {};

    switch (fileData.type) {
      case "image":
        if (fileData?.content?.dataId) {
          utils.mediafiles.deleteMedifile(
            fileData?.content?.dataId,
            (data) => {
              console.log(data);
            },
            errorHandler
          );
        }
        break;
      case "file":
        if (Array.isArray(fileData.content)) {
          fileData.content.forEach((contentItem, i) => {
            if (fileId == -1) {
              if (contentItem.dataId) {
                utils.mediafiles.deleteMedifile(
                  contentItem?.dataId,
                  callback,
                  errorHandler
                );
              }
            } else {
              if (contentItem?.dataId == fileId) {
                utils.mediafiles.deleteMedifile(
                  contentItem?.dataId,
                  callback,
                  errorHandler
                );
              }
            }
          });
        }
        break;
      default:
        break;
    }
  };

  const changeContent = (e) => {
    const searchBlockCondition = (block) => block.inEdit;
    const inEditBlockIndex = getBlockIndexInArray(searchBlockCondition);
    let fileLoadCondition = false;
    if (inEditBlockIndex != -1) {
      const updatedBlock = blockList;
      let contentData = updatedBlock[inEditBlockIndex].content;
      if (Array.isArray(e)) {
        contentData = e.map((item, i) => {
          return typeof content[i] == "undefined"
            ? { ...contentData[i], ...item }
            : item;
        });
        fileLoadCondition = true;
      } else {
        switch (updatedBlock[inEditBlockIndex].type) {
          case "text":
            contentData = e;
            break;
          case "image":
            if (e.src instanceof File) {
              deleteMediaFileFromServer(updatedBlock[inEditBlockIndex]);
              contentData = { ...updatedBlock[inEditBlockIndex].content, ...e };
              fileLoadCondition = true;
            } else {
              contentData = {
                ...updatedBlock[inEditBlockIndex].content,
                ...e,
              };
            }
            break;
          default:
            break;
        }
      }

      updatedBlock[inEditBlockIndex] = {
        ...updatedBlock[inEditBlockIndex],
        content: contentData,
      };

      updateBlockListFunctions(updatedBlock, -1, 0, fileLoadCondition);
    }
    setError("");
  };

  const AddTextBlock = (blockContent = "", inEdit = true, invokeIndex = -1) => {
    const newEditBlock = {
      index: `block-${
        invokeIndex >= 0 ? invokeIndex + 2 : blockList.length + 1
      }`,
      inEdit: inEdit,
      type: "text",
      content: blockContent,
    };
    const newBlockArray =
      invokeIndex >= 0
        ? blockList.reduce((result, block, index) => {
            const res = result;
            res.push({
              ...block,
              inEdit: false,
              index: invokeIndex < index ? `block-${index + 2}` : block.index,
            });
            if (invokeIndex == index) {
              res.push(newEditBlock);
            }
            return res;
          }, [])
        : [newEditBlock];

    updateBlockListFunctions(
      newBlockArray,
      invokeIndex,
      constants.MINIMUM_PAUSE
    );
    setShowContentTypes(null);
    setError("");
  };

  const AddImageBlock = (
    blockContent = { src: null, alt: "" },
    inEdit = true,
    invokeIndex = -1
  ) => {
    dispatcher(setStatus(true));
    const newEditBlock = {
      index: `block-${
        invokeIndex >= 0 ? invokeIndex + 2 : blockList.length + 1
      }`,
      inEdit: inEdit,
      type: "image",
      content: blockContent,
      imageContentsTypes: constants.IMAGE_COMPONENT_TYPES,
    };
    const newBlockArray =
      invokeIndex >= 0
        ? blockList.reduce((result, block, index) => {
            const res = result;
            res.push({
              ...block,
              inEdit: false,
              index: invokeIndex < index ? `block-${index + 2}` : block.index,
            });
            if (invokeIndex == index) {
              res.push(newEditBlock);
            }
            return res;
          }, [])
        : [newEditBlock];
    updateBlockListFunctions(
      newBlockArray,
      invokeIndex,
      constants.MINIMUM_PAUSE
    );
    setShowContentTypes(null);
    setError("");
  };

  const AddFileBlock = (blockContent = [], inEdit = true, invokeIndex = -1) => {
    const newEditBlock = {
      index: `block-${
        invokeIndex >= 0 ? invokeIndex + 2 : blockList.length + 1
      }`,
      inEdit: inEdit,
      type: "file",
      content: blockContent,
    };
    const newBlockArray =
      invokeIndex >= 0
        ? blockList.reduce((result, block, index) => {
            const res = result;
            res.push({
              ...block,
              inEdit: false,
              index: invokeIndex < index ? `block-${index + 2}` : block.index,
            });
            if (invokeIndex == index) {
              res.push(newEditBlock);
            }
            return res;
          }, [])
        : [newEditBlock];  
    updateBlockListFunctions(
      newBlockArray,
      invokeIndex,
      constants.MINIMUM_PAUSE
    );
    setShowContentTypes(null);
    setError("");
  };

  const setActiveImageBlock = (typeId, blockId) => {
    const currentBlock = getBlockByIndex(blockId);
    if (currentBlock) {
      const updatedImageContentsTypes = currentBlock.imageContentsTypes.map(
        (type, index) => {
          return { ...type, isActive: typeId == index ? true : false };
        }
      );

      const newBlockList = blockList.map((block) =>
        currentBlock.id == block.id && block.inEdit
          ? { ...block, imageContentsTypes: updatedImageContentsTypes }
          : block
      );
      setBlockList(newBlockList);
      updateBlockListFunctions(newBlockList);
    }
  };

  const addBlockButtonClick = (e, type, startBlock = false) => {
    if (e.currentTarget && (showContentTypes || startBlock)) {
      let invokedDigitIndex = -1;
      if (startBlock) {
        invokedDigitIndex = 0;
      } else {
        const invokedTextIndex = showContentTypes.replace(/edit-/i, "");
        invokedDigitIndex = blockList.reduce(
          (res, block, index) =>
            block.index == invokedTextIndex ? index : res,
          -1
        );
      }
      if (invokedDigitIndex >= 0) {
        setError("");
        switch (type) {
          case "text": {
            if (startBlock) {
              AddTextBlock();
            } else {
              AddTextBlock("", true, invokedDigitIndex);
            }
            break;
          }
          case "image": {
            if (startBlock) {
              AddImageBlock();
            } else {
              AddImageBlock({ src: "", alt: "" }, true, invokedDigitIndex);
            }
            break;
          }
          case "file": {
            if (!startBlock) {
              AddFileBlock([], true, invokedDigitIndex);
            } else {
              AddFileBlock();
            }
            break;
          }
          default:
            break;
        }
      } else {
        setError(constants.CANT_ADD_BLOCK);
      }
    }
  };

  /* Hover processing */

  const hoverOverBlock = (e) => {
    const condition =
      e.currentTarget &&
      e.currentTarget.querySelector(`.${styles.addBlockButtonContainer}`);
    if (condition) {
      const addBlockButton = e.currentTarget.querySelector(
        `.${styles.addBlockButtonContainer}`
      );
      addBlockButton.classList.add(styles.visible);
    }
  };

  const mouseLeaveBlock = (e) => {
    const consdition =
      e.currentTarget &&
      e.currentTarget.querySelector(`.${styles.addBlockButtonContainer}`);

    if (consdition) {
      const addBlockButton = e.currentTarget.querySelector(
        `.${styles.addBlockButtonContainer}`
      );
      if (addBlockButton.getAttribute("index") != showContentTypes) {
        addBlockButton.classList.remove(styles.visible);
      }
    }
  };

  const hoverAddBlockButton = (e) => {
    if (e.currentTarget) {
      e.currentTarget.classList.add(styles.visible);
    }
  };

  /*  end hover processing */

  useEffect(() => {
    const clickOutSideAddButtonArea = (e) => {
      if (
        !e.target.classList.contains(styles.buttonContainer) &&
        !e.target.classList.contains(styles.addBlockButton)
      ) {
        setShowContentTypes(null);
      }
    };
    window.addEventListener("click", clickOutSideAddButtonArea);

    return () => {
      window.removeEventListener("click", clickOutSideAddButtonArea);
    };
  }, []);

  const contentDivider = (existingContent) => {
    const empyCondition =
      existingContent.length <= 5 && existingContent.indexOf("</p>") == 0;
    if (!empyCondition) {
      const parts = document.createElement("div");
      parts.innerHTML = existingContent;
      let blockListArray = Array.from(
        parts.getElementsByClassName("content-block")
      ).map((part, index) => {
        /*One Image Object*/
        const ImageContentFormer = (sourceNode) => {
          const image = sourceNode.querySelector("img"),
            altText = sourceNode.querySelector("span");
          return {
            src: image ? image.getAttribute("src") : "",
            alt: altText ? altText.innerHTML : "",
            dataId:
              image && image.getAttribute("data-id")
                ? image.getAttribute("data-id")
                : "none",
          };
        };
        const ImageExtraFields = (sourceNode) => {
          const activeTypeIndex = sourceNode.getAttribute("data-type");
          return {
            imageContentsTypes: constants.IMAGE_COMPONENT_TYPES.map(
              (item, index) => {
                return { ...item, isActive: index == activeTypeIndex };
              }
            ),
          };
        };
        const newImage = utils.mediafiles.createBlockObject({
          part,
          index,
          inEditCondition: index < parts.length,
          selector: ".image-mediafile",
          type: "image",
          contentFormer: ImageContentFormer,
          extraFields: ImageExtraFields,
        });
        if (newImage) {
          return newImage;
        }
        /*Files Block Object*/
        const FilesContentFormer = (sourceNode) => {
          const links = sourceNode.querySelectorAll(".file-link");
          const text = sourceNode.querySelectorAll(
            ".fileListSection_Item_hoverBlock>.fileListSection_ItemTitle"
          );
          return Array.from(links).map((link, linkIndex) => {
            return {
              src: link.getAttribute("href"),
              alt:
                text[linkIndex] && text[linkIndex].querySelector("span")
                  ? text[linkIndex].querySelector("span").innerHTML
                  : "",
              type: link.getAttribute("data-type"),
              dataId: link.getAttribute("data-id")
                ? link.getAttribute("data-id")
                : "none",
            };
          });
        };
        const newFiles = utils.mediafiles.createBlockObject({
          part,
          index,
          inEditCondition: index < parts.length,
          selector: ".file-mediafile",
          type: "file",
          contentFormer: FilesContentFormer,
        });
        if (newFiles) {
          return newFiles;
        }
        /*Text Object*/
        const newText = utils.mediafiles.createBlockObject({
          part,
          index,
          inEditCondition: index < parts.length,
          selector: false,
          type: "text",
        });
        if (newText) {
          return newText;
        }
      });
      /*All Text Object*/
      if (blockListArray.length == 0 && existingContent.length > 0) {
        const allContentElement = document.createElement("div");
        allContentElement.innerHTML = existingContent;
        const allText = utils.mediafiles.createBlockObject({
          part: allContentElement,
          index: 0,
          inEditCondition: false,
          type: "text",
        });
        if (allText) {
          blockListArray = [allText];
        }
      }
      setBlockList(blockListArray);
    }
  };

  useEffect(() => {
    contentDivider(content);
  }, []);

  useEffect(() => {
    if (blockList.length) {
      const containerEelemet = document.createElement("div");
      containerEelemet.innerHTML = content;
      const mediafileList = containerEelemet.querySelectorAll(".mediafile");
      mediafileList.forEach((media) => {
        const types = [
          {
            class: "image-mediafile",
            handler: (mediaElement) => {
              const img = mediaElement.querySelector("img"),
                parent = media.getAttribute("parent-block-index");
              if (img) {
                const mediafileIdOnServer = img.getAttribute("data-id");
                if (mediafileIdOnServer != "none") {
                  setBlockList(
                    blockList.map((block) => {
                      if (parent == block.index) {
                        const cont = block.content;
                        if (cont.dataId && cont.dataId != mediafileIdOnServer) {
                          cont.dataId = mediafileIdOnServer;
                        }
                        return {
                          ...block,
                          content: cont,
                        };
                      }
                      return block;
                    })
                  );
                }
              }
            },
          },
          {
            class: "file-mediafile",
            handler: () => {},
          },
        ];
        types.forEach((type) => {
          if (media.classList.contains(type.class)) {
            type.handler(media);
          }
        });
      });
    }
  }, [content]);

  const processContnetTypes = (imageContentsTypes) => {
    const filtered = imageContentsTypes.reduce(
      (res, item) => (item.isActive ? item : res),
      {}
    );
    return filtered;
  };

  const blockSection = (block, index, typeComponent = () => <></>) => (
    <motion.div
      className={styles.addBlockArea}
      onMouseOver={hoverOverBlock}
      onMouseLeave={mouseLeaveBlock}
      index={index}
    >
      <motion.div
        className={styles.blockContainer}
        onClick={blockClick}
        index={block.index}
        // span={24}
      >
        <motion.div className={styles.functionalButtonsContainer}>
          {blockContentButtons.map((button) => {
            return (
              typeof button.isAvalibleCondition == "undefined" ||
              (button.isAvalibleCondition(index, block) && (
                <FunctionalButton
                  title={button.title}
                  icon={button.src}
                  description={button.alt}
                />
              ))
            );
          })}
        </motion.div>
        {typeComponent(index, block)}
      </motion.div>
      <motion.div
        className={classNames(
          styles.addBlockButtonContainer,
          showContentTypes == `edit-${block.index}` ? styles.visible : ""
        )}
        index={`edit-${block.index}`}
        onMouseOver={hoverAddBlockButton}
        onClick={showAddBlockArea}
      >
        <motion.div className={styles.liner}>
          <motion.div className={styles.addBlockButton}></motion.div>
        </motion.div>
        <AnimatePresence>
          {showContentTypes == `edit-${block.index}` && (
            <motion.div
              className={classNames(styles.newBlockButtonsArea)}
              initial={constants.BLOCK_TYPES_AREA_ANIMATION.offscreen}
              animate={constants.BLOCK_TYPES_AREA_ANIMATION.onscreen}
              exit={constants.BLOCK_TYPES_AREA_ANIMATION.offscreen}
            >
              {Array.isArray(contentTypes) &&
                contentTypes.length &&
                contentTypes.map((type, buttonIndex) => (
                  <AddContentButton
                    type={type.type}
                    icon={type.icon}
                    title={type.title}
                    key={buttonIndex}
                    clickHandler={(e) => {
                      return addBlockButtonClick(e, type.type);
                    }}
                    startMode={false}
                  />
                ))}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );

  return (
    <>
      {blockList.length > 0 ? (
        <motion.div className={styles.notEmptyContentContainer}>
          {blockList.map((block, index) => {
            return blockSection(block, index, (index, block) => {
              switch (block.type) {
                case "text":
                  return (
                    
                    <SectionEditor
                      id={`edit-block-${index + 1}`}
                      defaultValue={block.content}
                      editMode={block.inEdit}
                      valueCallback={changeContent}
                    />
                  );
                case "image": {
                  return (
                    <ImageLoadForm
                      id={`block-${index + 1}`}
                      editMode={block.inEdit}
                      onChange={(value) => {
                        changeContent(value);
                      }}
                      contentData={processContnetTypes(
                        block.imageContentsTypes
                      )}
                      setActiveImageBlock={setActiveImageBlock}
                      imageContentsTypes={block.imageContentsTypes}
                      content={block.content}
                      loading={block.content.loading}
                      loadingError={block?.content?.error}
                    />
                  );
                }
                case "file": {
                  return (
                    <FileSection
                      blockId={index}
                      setFileList={changeContent}
                      content={content}
                      block={block}
                      extraDeleteFunction={deleteMediaFileFromServer}
                    />
                  );
                }
                default:
                  break;
              }
            });
          })}
        </motion.div>
      ) : (
        <>
          <motion.div
            className={classNames(
              styles.addBlockButtonContainer
              // showContentTypes == `edit-${block.index}` ? styles.visible : ""
            )}
            // index={`edit-${block.index}`}
            onMouseOver={hoverAddBlockButton}
            onClick={showAddBlockArea}
          >
            <motion.div className={styles.liner}>
              <motion.div className={styles.addBlockButton}></motion.div>
            </motion.div>
            <AnimatePresence>
              {/* {showContentTypes == `edit-${block.index}` && ( */}
              <motion.div
                className={classNames(styles.newBlockButtonsArea)}
                initial={constants.BLOCK_TYPES_AREA_ANIMATION.offscreen}
                animate={constants.BLOCK_TYPES_AREA_ANIMATION.onscreen}
                exit={constants.BLOCK_TYPES_AREA_ANIMATION.offscreen}
              >
                {Array.isArray(contentTypes) &&
                  contentTypes.length &&
                  contentTypes.map((type, buttonIndex) => (
                    <AddContentButton
                      type={type.type}
                      icon={type.icon}
                      title={type.title}
                      key={buttonIndex}
                      clickHandler={(e) => {
                        return addBlockButtonClick(e, type.type, true);
                      }}
                      startMode={false}
                    />
                  ))}
              </motion.div>
              {/* )}  */}
            </AnimatePresence>
          </motion.div>
        </>
      )}
    </>
  );
};
