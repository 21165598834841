import environment from "../../constants/environment"
import global from "../../constants/global";

const THEME_CLASS = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].THEME : 'default-theme';

const EXCLUDED_CATEGORIES = environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].EXCLUDED_CATEGORIES ? environment.VARIABLES[process.env.REACT_APP_API_WIKI_ENV][environment.HOST].EXCLUDED_CATEGORIES : []

const EDITOR_ROLES_LIST = global.EDITOR_ROLES

const POPUP_WARNING_WINDOW_BUTTON = "OK"

const POPUP_WARNING_WINDOW_TITLE = "Speichern einer Veröffentlichung"

const POPUP_WARNING_WINDOW_TEXT = 'Bitte beachten Sie: Wenn die Seite an der aktuellen Adresse neu geladen wird, wird der von Ihnen erstellte Artikel gespeichert und ist in der Liste der Entwürfe in Ihrem persönlichen <a href="/profile">Benutzerkonto</a> verfügbar.'

export default {
    THEME_CLASS,
    EXCLUDED_CATEGORIES,
    EDITOR_ROLES_LIST,
    POPUP_WARNING_WINDOW_BUTTON,
    POPUP_WARNING_WINDOW_TITLE,
    POPUP_WARNING_WINDOW_TEXT
}