import { adoptBreakets } from './textHandler'
import apiQueries from './apiQueryUtils'
import user from './userData'
import crud from '../../api/crud'
import api from '../../api/server.api'
import constants from '../../constants/global'
import { decodeEntities } from '@wordpress/html-entities'

export const divider = (text = '') => {
  const textHandeled = adoptBreakets(text)
  const dividedText = textHandeled.split('<h2>').reduce((res, part) => {
    const divideTitle = part.split('</h2>')
    const paragraph = {
      title: '<h2></h2>',
      text: '',
    }
    if (divideTitle.length > 1) {
      paragraph.title = divideTitle[0]
      paragraph.text = divideTitle[1]
    } else {
      paragraph.text = part
    }
    if (paragraph.text) {
      res.push(paragraph)
    }
    return res
  }, [])
  if (dividedText.length == 0) {
    const paragraph = {
      title: '<h2></h2>',
      text: textHandeled,
    }
    dividedText.push(paragraph)
  }
  return dividedText
}

const addingHandler = (
  goalArray,
  callback,
  formik,
  articleId,
  errorHandler = apiQueries.defaultErrorHandler,
) => {
  if (!(formik.errors.category && formik.errors.title)) {
    const data = {
      categories: [
        formik.values.subcategory && formik.values.subcategory != '0'
          ? formik.values.subcategory
          : formik.values.category,
      ],
      title: formik.values.title,
      content: formik.values.content + '<p></p>',
      excerpt: formik.values.excerpt,
      fields: {
        links: formik.values.links,
        preview_image: JSON.stringify(goalArray.image),
        progress: Number(formik.values.progress)
      },
      status: 'draft',
      ...goalArray,
    }
    apiQueries.postData(
      api.standart,
      `${crud.standart.POSTS}${articleId ? '/' + articleId : ''}`,
      callback,
      data,
      {},
      errorHandler,
    )
  } else {
    errorHandler(constants.SOME_FIELDS_HAVE_ERRORS)
  }
}

const postOutputInTubs = (postList, styledClass) => {
  const list = Array.from(postList)
  const userForm = document
    .querySelector('.userEditForm')
    .getBoundingClientRect()
    const tabs = document.querySelector('.tabs-container')
  const navigationHeight = tabs.getBoundingClientRect().height
  const marginBottomOfCard = (cardStyle) => {
    return Number(cardStyle.match(/\d/g).join(''))
  }
  let summary = navigationHeight + marginBottomOfCard((tabs.currentStyle || window.getComputedStyle(tabs)).marginBottom)
  console.log(summary)
  console.log(userForm.height)
  list.forEach((post, index) => {
    const card = post.getBoundingClientRect()
    const marginBottom = marginBottomOfCard(
      (post.currentStyle || window.getComputedStyle(post)).marginBottom,
    )
    const currentCardHieght = card.height + marginBottom
    if (summary > userForm.height && index > 0) {
      if (
        list &&
        !post.classList.contains(styledClass) &&
        userForm.height - summary + marginBottom > 0 &&
        list[index - 1] &&
        list[index - 1].classList &&
        list[index - 1].classList.contains(styledClass)
      ) {
        post.setAttribute(
          'style',
          `margin-top: ${userForm.height - summary + marginBottom + navigationHeight
          }px;`,
        )
        summary += userForm.height - summary + marginBottom + navigationHeight
      }
      post.classList.add(styledClass)
    } else {
      console.log(index)
      post.classList.remove(styledClass)
    }
    summary += currentCardHieght
    console.log(summary)
  })
}

const postDateFormer = (stringDate) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const dateTimeFormat = new Intl.DateTimeFormat('de-DE', options)
  try {
    const date = new Date(stringDate)
    return dateTimeFormat.format(date)
  } catch (e) {
    console.log(e)
    const date = new Date()
    return dateTimeFormat.format(date)
  }
}

const decodeHtmlCharCodes = (str) => {
  const newStr = str
  return newStr.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
    const res = String.fromCharCode(charCode)
    return res
    // return res.replace(/\“/g, "\"")
  })
}

const convertSymbolesForCode = (str) => {
  return decodeEntities(str.replace(/&#8220;/g, "\"").replace(/&#8243;/g, "\"").replace(/“/g, "\""))
  // const newStr = decodeEntities(str)
  // console.log(newStr)
  // const one =  newStr.replaceAll(/\“/g, "\"").replaceAll(/\„/g, "\"").replaceAll(/\″/g, "\"").replaceAll(/\"/g,  "\"").replaceAll(/\"/g, "\"")
  // console.log(one)
  // return one
}

const getExturnalLinkData = async (data) => {
  const { link, callback, errorHandler = () => { } } = data 
  const headerWithAuth = user.headerWithAuth();
  try {
    await apiQueries.postData(
      api.custom,
      crud.custom.CHECK_LINK,
      callback,
      { link: link },
      headerWithAuth,
      errorHandler,
    )
  } catch (error) {
    errorHandler(error)
  }
}

const germanToUppercase = (text) => {
  const textArray = text ? text.split('ß') : []
  const result = textArray.reduce((res, part, index) => {
    res = `${res}${part.toUpperCase()}`
    if (index < textArray.length - 1 || text[text.length - 1] == 'ß') {
      res = `${res}ß`
    }
    return res
  }, '')

  return result
}

const titleLengthValidation = (title, maxLength) => {
  return title.length < maxLength
    ? title
    : `${title.substring(0, maxLength)}...`
}

const isAuthorCheck = (post) => {
  return (
    post && post.author && user.getUserID() && user.getUserID() == post.author || user.getUserID() == constants.ADMIN_ID
  )
}

export default {
  divider,
  addingHandler,
  postOutputInTubs,
  postDateFormer,
  decodeHtmlCharCodes,
  getExturnalLinkData,
  germanToUppercase,
  titleLengthValidation,
  isAuthorCheck,
  convertSymbolesForCode
}
