import { motion } from "framer-motion";
import { Field, Formik, Form } from "formik";
import classNames from "classnames";
import { BaseSubmitButton } from "../../Buttons/BaseSubmitButton/BaseSubmitButton";
import { Row } from "../../_layouts/Row/Row";
import { Col } from "../../_layouts/Col/Col";
import { addCommentFormSchems } from "../../../../schemes";
import { SendingResult } from "../SendingResult/SendingResult";
import utils from "../../../utils";
import conatants from "./constants";
import styles from "./addcommentform.module.scss";

export const AddCommentForm = ({
  postId,
  submitFunction = () => {},
  sendingResult,
  isLoading = false,
}) => {
  const defaultValues = {
    post: postId,
    content: "",
  };

  const getAvatarFromStorage = ()=>{
    console.log(utils.user?.getAvatar()?.src)
    return utils.user?.getAvatar()?.src ? utils.user.getAvatar().src : utils.user?.getAvatar()
  }

  return (
    <>
      <Formik
        initialValues={defaultValues}
        validationSchema={addCommentFormSchems}
        onSubmit={submitFunction}
      >
        {(props) => (
          <Form className={styles.addCommentForm}>
            <Row>
              <motion.div
                className={styles.userAvatarContainer}
                style={{ backgroundImage: `url(${getAvatarFromStorage()})` }}
              ></motion.div>
              <motion.div className={styles.fieldsColumn}>
                <Field
                  type="textarea"
                  name="content"
                  id="content"
                  component="textarea"
                  placeholder={conatants.INPUT_PLACEHOLDER}
                />
                {props.errors.content && (
                  <motion.div className={styles.fieldError}>
                    <span>{props.errors.content}</span>
                  </motion.div>
                )}
                {props.errors.author && (
                  <motion.div className={styles.fieldError}>
                    <span>{props.errors.author}</span>
                  </motion.div>
                )}
                {props.errors.post && (
                  <motion.div className={styles.fieldError}>
                    <span>{props.errors.post}</span>
                  </motion.div>
                )}
                {props.errors.parent && (
                  <motion.div className={styles.fieldError}>
                    <span>{props.errors.parent}</span>
                  </motion.div>
                )}
                {!isLoading && (
                  <Col className={styles.ButtonCol}>
                    <BaseSubmitButton title={conatants.BUTTON_TITLE} mode={"outlined"}/>
                    {/* <BaseSubmitButton
                      value={conatants.BUTTON_TITLE}
                      className={classNames(
                        styles.sendComment,
                        props.values.content == "" ? styles.disable : ""
                      )}
                    /> */}
                  </Col>
                )}
              </motion.div>
            </Row>
          </Form>
        )}
      </Formik>
      <SendingResult
        sendingResult={sendingResult}
        className={styles.resultMessagePadding}
      />
    </>
  );
};
