export const FADEIN_INFLUX_ANIMATION = {
  opacity: 1,
  scale: 1,
}

export const FADEOUT_OUTFLUX_ANIMATION = {
  opacity: 0,
  scale: 0.9,
}

export const FADEIN_DOWN_ANIMATION = {
  opacity: 1,
  y: 0,
}

export const FADEOUT_LEFT_ANIMATION = {
  opacity: 0,
  x: -20,
}

export const FADEIN_LEFT_ANIMATION = {
  opacity: 1,
  x: 0,
}


export const FADEOUT_UP_ANIMATION = {
  opacity: 0,
  y: -20,
}

export const TRANSITION_SETTINGS = {
  delay: 0,
  duration: 0.5,
  ease: "linear",
}

export const FADEIN_ANIMATION = {
  opacity: 1,
}

export const FADEOUT_ANIMATION = {
  opacity: 0,
}

export const WIDTH_START_ANIMATION = {
  width: 0
}

export const WIDTH_FINISH_ANIMATION = {
  width: "auto"
}


export const FADE_IN_ANIMATION = (visible) => {
  return visible ? FADEIN_ANIMATION : FADEOUT_ANIMATION
}

const MOST_COMMON_TRANSITION = (duration, delay = 0) => {
  return {
    delay: delay,
    duration: duration,
    transform: {
      type: "inertia",
      velocity: 125,
      duration: duration,
    },
  };
};

const FADEIN_DOWN_APPEARING = (duration = 1, y = -20) => {
  return {
    variants: {
      start: {
        opacity: 0,
        y: `${y}px`,
      },
      end: {
        opacity: 1,
        y: `0`,
      },
    },
    transition: (delay = 0) => {
      return MOST_COMMON_TRANSITION(duration, delay);
    },
  };
};

const FADEIN_APPEARING = (duration = 1) => {
  return {
    variants: {
      start: {
        opacity: 0,
      },
      end: {
        opacity: 1,
      },
    },
    transition: (delay = 0) => {
      return {
        delay: delay,
        duration: duration,
      };
    },
  };
};

const FADEIN_LEFT_APPEARANCE = (duration = 1) => {
  return {
    variants: {
      start: {
        opacity: 0,
        y: "-200px",
      },
      end: {
        opacity: 1,
        y: "0px",
      },
    },
    transition: (delay = 0) => {
      return MOST_COMMON_TRANSITION(duration, delay);
    },
  };
};

const DOWN_APPEARING = (duration = 1, y = 0) => {
  return {
    variants: {
      start: {
        height: 0,
        overflow: "hidden",
        y: `${y}px`,
      },
      end: {
        height: "auto",
        overflow: "visible",
        y: `0px`,
      },
    },
    transition: (delay = 0) => {
      return MOST_COMMON_TRANSITION(duration, delay);
    },
  };
};

const LEFT_APPEARING = (duration = 1) => {
  return {
    variants: {
      start: {
        width: "0",
      },
      end: {
        width: "auto",
      },
    },
    transition: (delay = 0) => {
      return {
        type: "inertia",
        velocity: 125,
        delay: delay,
        duration: duration,
      };
    },
  };
};

const FADEIN_APPEARING_VIEWPORT = (x = 0, y = 0, duration = 1, delay = 0) => ({
  initial: { opacity: 0, x, y },
  whileInView: { opacity: 1, x: 0, y: 0 },
  viewport: { once: true },
  transition: { delay, duration },
});

const SCALE_ANIMATION = (
  initScale = 1,
  aniScale = 1.2,
  duration = 1,
  delay = 0
) => ({
  variants: { start: { scale: initScale }, end: { scale: aniScale } },
  transition: { delay, duration },
});

const ROTATE = (rotate, duration = 1) => {
  return {
    variants: {
      start: {
        rotate: 0,
      },
      end: {
        rotate,
      },
    },
    transition: (delay = 0) => {
      return {
        delay: delay,
        duration: duration,
      };
    },
  };
};



export const SHOW_COLLAPSE = {
  opacity: 1,
  height: 'auto',
}

export const HIDE_COLLAPSE = {
  opacity: 0,
  height: '0',
}


const CARD_VARIANTS = {
  offscreen: FADEOUT_ANIMATION,
  onscreen: {
    ...FADEIN_ANIMATION,
    transition: { ...TRANSITION_SETTINGS },
  },
};


export default {
  FADEIN_INFLUX_ANIMATION,
  FADEOUT_OUTFLUX_ANIMATION,
  FADEIN_DOWN_ANIMATION,
  FADEOUT_LEFT_ANIMATION,
  FADEIN_LEFT_ANIMATION,
  FADEOUT_UP_ANIMATION,
  TRANSITION_SETTINGS,
  FADEIN_ANIMATION,
  FADEOUT_ANIMATION,
  WIDTH_START_ANIMATION,
  WIDTH_FINISH_ANIMATION,
  FADE_IN_ANIMATION,
  FADEIN_DOWN_APPEARING,
  FADEIN_APPEARING,
  FADEIN_LEFT_APPEARANCE,
  DOWN_APPEARING,
  LEFT_APPEARING,
  FADEIN_APPEARING_VIEWPORT,
  SCALE_ANIMATION,
  ROTATE,
  SHOW_COLLAPSE,
  HIDE_COLLAPSE,
  CARD_VARIANTS
}