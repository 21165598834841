import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Header } from "../../shared/components/_sections/Header/Header";
import { Container } from "../../shared/components/_layouts/Container/Container";
import { Footer } from "../../shared/components/_sections/Footer/Footer";
import { Preloader } from "../../shared/components/Preloader/Preloader";
import { DefaultPage } from "../DefaultPage/DefaultPage";
import { NavSider } from "../../shared/components/_menus/NavSider/NavSider";
import { NoPageSection } from "../../shared/components/_sections/NoPageSection/NoPageSection";
import useFillHint from "../../shared/hooks/fillHint/fillHint";
import useRedirects from "../../shared/hooks/redirects/redirects";
import api from "../../api/server.api";
import crud from "../../api/crud";
import utils from "../../shared/utils";
import constants from "./constants";
import styles from "./notfounfpage.module.scss";

export const NotFoundPage = () => {
  const { isLoggedIn } = useSelector((state) => state.user);
  const [menuTree, setmenuTree] = useState([]);
  const [visible, setVisibility] = useState(true);
  const { toLoginpage } = useRedirects();
  const { mouseLeave } = useFillHint();

  const loadInformation = async () => {
    try {
      await Promise.all([
        utils.api.getData(api.custom, crud.custom.MULTY_LVL_MENU, setmenuTree),
      ]);
    } catch (e) {
    } finally {
      setVisibility(false);
    }
  };

  useEffect(() => {
    loadInformation();
    mouseLeave();
  }, []);

  if (!isLoggedIn) {
    return toLoginpage();
  }

  return (
      <DefaultPage>
        <Preloader visible={visible} />
        {!visible && (
          <>
            <NavSider menuTree={menuTree} setmenuTree={setmenuTree} />
            <Container className={classNames(styles.container)}>
              <Header />
              <NoPageSection
                text={constants.NOT_FOUND_TEXT}
                title={constants.NOT_FOUND_TITLE}
              />
              <Footer></Footer>
            </Container>
          </>
        )}
      </DefaultPage>
  );
};
