import animation from "../../../../../constants/animation";
import images from "../../../../../assets/images";

const ANIMATION = {items: animation.FADEIN_DOWN_APPEARING(0.3)}

const ANIMATION_DELAY_INDEX = 0.1

const DEFAULT_AVATAR = {
    src: images.guidline.default_user_form_image,
    alt: "avatar"
}

export default {
    ANIMATION,
    ANIMATION_DELAY_INDEX,
    DEFAULT_AVATAR
}