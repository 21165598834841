import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserForm } from "../../shared/components/_forms/UserForm/UserForm";
import { register, login } from "../../features/auth.slice";
import { setMessage } from "../../features/message";
import { userRegisterFormSchems } from "../../schemes/index";
import useFillHint from "../../shared/hooks/fillHint/fillHint";
import useRedirects from "../../shared/hooks/redirects/redirects";
import constanst from "./constants";

export const RegisterPage = () => {
  const [succesResult, setSuccesResult] = useState(false);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const dispatch = useDispatch();
  const { toHomepage, toLastVisitedPage } = useRedirects();
  const { mouseLeave } = useFillHint();
  const [passwordEyeVisibility, setPasswordEyeVisibility] = useState(false);
  const [resetpasswordEyeVisibility, setRestePasswordEyeVisibility] =
    useState(false);

  const fields = constanst.FIELDS.map((item) => {
    switch (item.name) {
      case "password":
        return {
          ...item,
          eyeVisibility: passwordEyeVisibility,
          setEyeVisibility: setPasswordEyeVisibility,
        };
      case "repeatpassword":
        return {
          ...item,
          eyeVisibility: resetpasswordEyeVisibility,
          setEyeVisibility: setRestePasswordEyeVisibility,
        };
      default: {
        return item;
      }
    }
  });

  const handleRegister = (formValue) => {
    setLoadingInProcess(true);
    const { username, email, password } = formValue;

    dispatch(register({ username : username?.trim(), email : email?.trim(), password : password?.trim() }))
      .unwrap()
      .then(
        (data) => {
          console.log(data);
          if (data && data.code && data.code == "registration_successful") {
            formValue.username = constanst.DEFAULT_VALUE;
            formValue.email = constanst.DEFAULT_VALUE;
            formValue.password = constanst.DEFAULT_VALUE;
            formValue.repeatpassword = constanst.DEFAULT_VALUE;
            setSuccesResult(true);
            dispatch(setMessage(constanst.SUCCESS_MESSAGE));
            dispatch(login({ username: email, password }))
              .unwrap()
              .then(
                (loginData) => {
                  console.log(loginData);
                  if (
                    loginData &&
                    loginData.meta &&
                    loginData.meta.requestStatus &&
                    loginData.meta.requestStatus != "rejected"
                  ) {
                    setSuccesResult(true);
                    const result = toLastVisitedPage();
                    if (result) return result;
                  }
                  setLoadingInProcess(false);
                },
                (loginReason) => {
                  console.log(loginReason);
                  setSuccesResult(false);
                }
              );
          } else {
            setLoadingInProcess(false);
          }
        },
        (reason) => {
          console.log(reason);
          setSuccesResult(false);
        }
      );
  };
  useEffect(() => {
    mouseLeave();
  }, []);

  if (toHomepage()) {
    return toHomepage();
  }

  return (
    <UserForm
      handler={handleRegister}
      fields={fields}
      buttonName={constanst.BUTTON_NAME}
      verificationSheme={userRegisterFormSchems}
      succesResult={succesResult}
      loadingInProcess={loadingInProcess}
      setLoadingInProcess={setLoadingInProcess}
      subtitle={constanst.SUBTITLE}
    >
      {constanst.LINKS &&
        constanst.LINKS.map((link) => (
          <motion.div dangerouslySetInnerHTML={{ __html: link }} />
        ))}
    </UserForm>
  );
};
