import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ListItem } from "../ListItem/ListItem";
import constants from "./constants";
import styles from "./list.module.scss";

const List = ({ items, submenuVisible, startLevel, selectionString }) => {
  const [visibleSubmenu, setVisibleSubmenu] = useState(-1);

  return (
    <motion.ul
      className={classNames(styles.submenu, startLevel ? styles.startLvl : "")}
      variants={constants.ANIMATION.menu.variants}
      initial={"start"}
      animate={submenuVisible ? "end" : "none"}
      exit={"start"}
      transition={constants.ANIMATION.menu.transition(0)}
    >
      <AnimatePresence>
        {items.map(
          (item, key) =>
            submenuVisible && (
              <motion.li
                key={`${key}-container`}
                variants={constants.ANIMATION.menuItem.variants}
                initial={"start"}
                exit={"start"}
                animate={submenuVisible ? "end" : "none"}
                className={styles.listItem}
                transition={constants.ANIMATION.menuItem.transition(
                  (submenuVisible ? key : items.length - key) *
                    constants.MENU_ITEM_DELAY
                )}
              >
                <ListItem
                  title={item.title}
                  link={item.link}
                  descriptiveText={item.text}
                  selectionString={selectionString}
                  submenu={item.submenu}
                  index={key}
                  onClick={setVisibleSubmenu}
                  visibleSubmenu={visibleSubmenu}
                />
              </motion.li>
            )
        )}
      </AnimatePresence>
    </motion.ul>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  submenuVisible: PropTypes.bool,
  startLevel: PropTypes.bool,
  selectionString: PropTypes.string
};

List.defaultProps = {
  submenuVisible: false,
  startLevel: false,
  selectionString: ''
};

export { List };
