import { motion } from "framer-motion"
import classNames from "classnames"
import styles from "./row.module.scss"

export const Row = ({children, className})=>{
    return (
    <motion.div className={classNames(styles.row, className)}>
        {children}
    </motion.div>
    )
}