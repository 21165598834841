import images from '../../../../assets/images'

const DEFAULT_AVATAR = {
  src: images.imageExumple,
  alt: '<p>default image</p>',
}

const LOCAL_IMAGE_LOAD_ERROR = 'Das Bild konnte nicht geladen werden'

const ADD_IMAGE_SIGN = 'Das Bild konnte nicht auf den Server hochgeladen werden. Ändern Sie den Namen und versuchen Sie es erneut oder wenden Sie sich an Ihren Administrator.'

export default {
  DEFAULT_AVATAR,
  ADD_IMAGE_SIGN,
  LOCAL_IMAGE_LOAD_ERROR
}
