import { motion } from "framer-motion";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./cardimage.module.scss";

const CardImageArea = ({ image, maxSize }) => {
  return (
    <motion.div className={classNames(styles.imageContainer, maxSize ? styles.maxSize : '')}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    </motion.div>
  );
};

CardImageArea.propTypes = {
  image: PropTypes.string.isRequired,
  maxSize: PropTypes.bool
};

CardImageArea.defaultProps = {
    maxSize: false
}

export { CardImageArea };
