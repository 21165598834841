const HOME = '/'
const QESTIONS = '/faq'
const MOTIVATION_CARDS = '/motivation-cards'
const MAIN_PAGE_POSTS = '/posts?categories=6'
const MULTY_LVL_MENU = '/wp-json/posts-tree/v1/posts-tree'
const CATEGORIES = '/categories'
const PARENT_CATEGORIES ='/categories?parent=0'
const POSTS = '/posts'
const LOGIN = '/wp-json/jwt-auth/v1/token'
const MEDIA = '/media'
const REGISTER = '/wp-json/accaunt/v1/register'
const RESET_PASWORD = '/wp-json/accaunt/v1/reset-password'
const FEEDBACKS = '/feedbacks'
const USERS_ID = '/wp-json/account/v1/loggedinuser'
const USER_DATA = '/wp-json/account/v1/userdata'
const USER = '/user'
const USER_NEW_PASSWORD = '/wp-json/account/v1/chagepassword'
const USER_CHANGE = '/wp-json/account/v1/chageuserdata'
const TOCKEN_VALIDATE = '/wp-json/jwt-auth/v1/token/validate'
const CHECK_LINK = '/wp-json/check/v1/check-external-link'
const COMMENTS = '/comments'
const CUSTOM_COMMENTS = '/wp-json/comments/v1/comments'
const COMMENT_EDIT = '/wp-json/jwt-auth/v1/comment'
const LIKES = '/wp-json/jwt-auth/v1/comment-likes'
const PREVIEWS = '/wp-json/jwt-auth/v1/post/preview'

const custom = {
    MULTY_LVL_MENU,
    LOGIN,
    REGISTER,
    RESET_PASWORD,
    USERS_ID,
    USER_DATA,
    USER_NEW_PASSWORD,
    USER_CHANGE,
    TOCKEN_VALIDATE,
    CHECK_LINK,
    CUSTOM_COMMENTS,
    COMMENT_EDIT,
    LIKES,
    PREVIEWS
}

const standart = {
    QESTIONS,
    MOTIVATION_CARDS,
    MAIN_PAGE_POSTS,
    POSTS,
    HOME,
    CATEGORIES,
    PARENT_CATEGORIES,
    MEDIA,
    FEEDBACKS,
    USER,
    COMMENTS
}

export default {
    custom,
    standart
}