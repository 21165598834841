import { motion } from "framer-motion";
import classNames from "classnames";
import { useNavigate, Navigate } from "react-router-dom";
import { HorizontalMenu } from "../../_menus/HorisontalMenu/HorisontalMenu";
import { Container } from "../../_layouts/Container/Container";
import { MENU } from "./constants";
import { PopupHint } from "../../Popup/PopupHint/PopupHint";
import constants from "./constants";
import { FooterImage } from "./FooterImage/FooterImage";
import { MainPopupContainer } from "../../Popup/MainPopupContainer/MainPopupContainer";
import styles from "./footer.module.scss";

export const Footer = ({ visible }) => {
  const menu = MENU;
  const navigation = useNavigate();

  const menuContainer = (link) => {
    navigation(link);
    return <Navigate to={link} />;
  };

  menu[0] = {
    ...menu[0],
    onClick: () => {
      return menuContainer(menu[0].link);
    },
  };

  menu[1] = {
    ...menu[1],
    onClick: () => {
      return menuContainer(menu[1].link);
    },
  };


  const chooseLeftSideFooter = () => {
    switch (constants.CURRENT_ENV) {
      case "ukhd":
        return (
          <div>
            <FooterImage image={constants.FOOTER_LOGO_BOTTOM} />
            <FooterImage image={constants.FOOTER_LOGO_TOP} />
          </div>
        );
      case "department":
        return <FooterImage image={constants.ONTRON_LOGO} />;
      case "egk":
        return <FooterImage image={constants.EGK_LOGO} />;
      default:
        return (
          <div>
            <FooterImage image={constants.FOOTER_LOGO_BOTTOM} />
            <FooterImage image={constants.FOOTER_LOGO_TOP} />
          </div>
        );
    }
  };

  const chooseRightSideFooter = () => {
    switch (constants.CURRENT_ENV) {
      case "ukhd":
        return "";
      case "department":
        return <FooterImage image={constants.PLOT_A_LOGO} />;
      default:
        return "";
    }
  };

  return (
    <Container>
      <motion.footer>
        <MainPopupContainer />
        <PopupHint />
        {chooseLeftSideFooter()}
        <HorizontalMenu
          items={MENU}
          classes={classNames(
            styles.menu,
            ["egk", "department"].includes(constants.CURRENT_ENV)
              ? styles.smallMenu
              : ""
          )}
        >
          <motion.li
            variants={constants.CARD_VARIANTS}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <motion.span variants={constants.CARD_VARIANTS}>© 2023</motion.span>
          </motion.li>
        </HorizontalMenu>
        {chooseRightSideFooter()}
      </motion.footer>
    </Container>
  );
};
