export const STEP_INPOGRESS = {
    width: '0%'
}

export const STEP_DONE = {
    width: '100%'
}

const DONE_TITLE = 'Fertig'

export default {
    STEP_INPOGRESS,
    STEP_DONE,
    DONE_TITLE
}