import images from '../../../../assets/images'

const USER_ICON = {
    src: images.guidline.user_icon,
    alt : 'user'
}

const MENU = [
    {
        type: 'Text',
        link : '/faq',
        title: 'FAQ'
    },
    {
        type: 'UserBtn',
        icon: USER_ICON,
        description: 'Benutzerprofil'
    },
    {
        type: 'FilledText',
        link : '/posts/edit',
        title: 'Artikel erstellen',
        className: ''
    },
    {
        type: 'Overlined',
        title: 'Hilfe'
    }
]

const SUPPORT_POPUP_ITILE = 'Bitte schreiben<br>Sie uns Ihre Fragen oder Anregungen zur Verbesserung der Arbeit'

const SUPPORT_BUTTON_NAME = "Hilfe"
//support

export default {
   USER_ICON,
   MENU,
   SUPPORT_BUTTON_NAME,
   SUPPORT_POPUP_ITILE,
//    SUPPORT_SUBTITLE
}



