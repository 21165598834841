const FIELDS_TEXT = {
    name: 'Ihr Name',
    email: 'Ihre E-Mail-Adresse',
    message: 'Sehr nützlicher Artikel, vielen Dank!'
}

const SECCESS_FEEDBACK_SENT = 'Ihre Anfrage wurde erfolgreich abgeschickt. Eine Antwort wird an die von Ihnen angegebene E-Mail-Adresse gesendet'
//Your request has been successfully sent. A reply will be sent to the email you specified"

const FAIL_FEEDBACK_SENT = 'Das Senden einer E-Mail über das Feedback-Formular ist fehlgeschlagen'

const DEFAULT_BUTTON_TEXT = 'Senden'
//Send

const ROLES_LIST = [
    {
        id: 'wiki_moderator',
        name: 'Moderator',
        description: 'Moderatoren sind Benutzer, die eine erweiterte Rolle in der Verwaltung des Wikis spielen. Sie haben die Aufgabe, die Aktivitäten und Beiträge im Wiki zu überwachen und sicherzustellen, dass sie den Richtlinien und Standards entsprechen.'
    }, {
        id: 'wiki_gardener',
        name: 'Wiki-Gärtner',
        description: 'Die Hauptaufgabe eines Wiki-Gärtners besteht darin, die Qualität und Konsistenz der Inhalte sicherzustellen. Dies kann die Aktualisierung veralteter Informationen, die Kategorisierung von Artikeln, die Verbesserung der Formatierung und die Förderung einer besseren Benutzererfahrung umfassen.'
    }, {
        id: 'administrator',
        name: 'Technische Unterstützung',
        description: 'SAdministratoren sind für die technische Infrastruktur des Wikis verantwortlich und können Benutzern bei technischen Fragen oder Problemen helfen.'
    }
]

export default {
    FIELDS_TEXT,
    DEFAULT_BUTTON_TEXT,
    SECCESS_FEEDBACK_SENT,
    FAIL_FEEDBACK_SENT,
    ROLES_LIST
}