import { motion } from "framer-motion";
import constants from "./constants";
import styles from "./logobunner.module.scss";

export const LogoBunner = () => {
  return (
    !constants.LOGO_EXISTS && (
      <motion.div className={styles.mainBunnerContainer}>
        <motion.div className={styles.logoBunerContainer}>
          <img src={constants.BIG_LOGO.src} alt={constants.BIG_LOGO.alt} />
          <img src={constants.BIG_SIGN.src} alt={constants.BIG_SIGN.alt} />
        </motion.div>
        <div className={styles.description}>{constants.BANNER_TEXT}</div>
      </motion.div>
    )
  );
};
