import backgroundImage from './background.png';
import wikiSign from './bigSign.svg';
import bigLogo from './bigLogo.svg';
import userIcon from './userIcon.svg';
import pancil from './icon_edit_active.svg'
import searchIcon from './search_icon.svg';
import footerLogoTop from './footerLogoTop.svg';
import footerLogoBottom from './footerLogoBottom.svg';
import arrowBack from './arrow_back.svg'
import arrowNext from './arrow_next.svg'
import basket from './basket.svg';
import hint from './hint_icon.svg';
import imagePlug from './plug.png'
import doneIconukhd from './Uploading_3-01_Check.gif';
import doneIconegk from './Uploading_3-02_Check.gif';
import failIcon from './not_ok_result.svg'
import loadingIconukhd from './loading_gif.gif'
import loadingIconegk from './Uploading_3-02_Circle.gif'
import errorIcon from './errorIcon.svg'
import defaultUserAvatar from './default_user_avatar.svg'
import pencilBalckIcon from "./pencil_black.svg"
import redErrorIcon from "./error_icon.svg"
import pdfIcon from "./pdf.png"
import printIconukhd from "./printerukhd.svg"
import printIconegk from "./printegk.svg"
import printIcondepartment from "./printerdepartment.svg"
import blueBusketukhd from "./blue_bisket.svg"
import blueBusketegk from "./basket_green.svg"
import minimalBlackPancilukhd from "./icon_edit_active.svg"
import minimalBlackPancilegk from "./pancilGreen.svg"
import downloadArrow from "./download_arrow.svg"
import downloadArrowBlue from "./download_arrow_blue.svg"
import sacSign from "./SAC.svg"
import eyeClosed from "./eye_closed.svg"
import eyeOpened from "./eye_opened.svg"
import plotALogo from "./plot_a_logo.svg"
import ontron from "./ontron_logo.svg"
import faviconukhd from "./favicon_ukhd.ico"
import favicondepartment from './favicon_department.ico'
import shereByImail from "./shere.svg"
import addTextIcon from "./add_text_icon.svg"
import basketBlack from "./busket_black.svg"
import copyIcon from "./copy_icon.svg"
import arrowDown from "./arrow_down.svg"
import arrowUp from "./arrow_up.svg"
import likeBtnBlue from "./iconamoon_like (1).svg"
import likeBtn from "./iconamoon_like.svg"
import commentCross from "./cross_comments.svg"
import commentOk from "./cross_ok.svg"
import addImageIcon from "./add_image.svg"
import imageExumple from "./Frame 1438.png"
import smallImageExumple from "./small_image_template.png"
import settingIcon from "./settings.webp"
import fileIcon from "./datai.svg"
import plusIcon from "./plus.svg"
import excelFileIcon from "./excel.svg"
import imagesFileIcon from "./image.svg"
import pdfFileIcon from "./PDF.svg"
import wordFileIcon from "./word.svg"
import downloadFileIcon from "./download_file.svg"
import egkLogo from "./egk_logo.png"
import noPageImgDepartment from "./NoPage_Department.png";
import noPageImgEGK from "./NoPage_EGK.png";
import noPageImgUKHD from "./NoPage_UKHD.png";
import faviconegk from "./favicon_egk.ico"
import pancilBlue from "./blue_pancil.svg"
import guidline from "./guideline"


export default {
    backgroundImage,
    wikiSign,
    bigLogo,
    userIcon,
    pancil,
    searchIcon,
    footerLogoTop,
    footerLogoBottom,
    arrowBack,
    arrowNext,
    basket,
    hint,
    imagePlug,
    doneIconukhd,
    doneIconegk,
    failIcon,
    loadingIconukhd,
    loadingIconegk,
    errorIcon,
    defaultUserAvatar,
    pencilBalckIcon,
    redErrorIcon,
    pdfIcon,
    printIconukhd,
    printIconegk,
    printIcondepartment,
    blueBusketukhd,
    blueBusketegk,
    minimalBlackPancilukhd,
    minimalBlackPancilegk,
    downloadArrow,
    downloadArrowBlue,
    sacSign,
    eyeClosed,
    eyeOpened,
    plotALogo,
    ontron,
    faviconukhd,
    favicondepartment,
    shereByImail,
    addTextIcon,
    basketBlack,
    copyIcon,
    arrowDown,
    arrowUp,
    likeBtnBlue,
    likeBtn,
    commentCross,
    commentOk,
    addImageIcon,
    imageExumple,
    smallImageExumple,
    settingIcon,
    fileIcon,
    plusIcon,
    excelFileIcon,
    imagesFileIcon,
    pdfFileIcon,
    wordFileIcon,
    downloadFileIcon,
    egkLogo,
    noPageImgDepartment,
    noPageImgEGK,
    noPageImgUKHD,
    faviconegk,
    pancilBlue,
    guidline
}