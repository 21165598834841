import {motion} from "framer-motion"
import classNames from "classnames"
import { useEffect, useState } from "react"
import constants from "./constants"
import styles from "./style.module.scss"

export const Hint = ({title, text, type="", className})=>{
    const [settings, setSettings] = useState({ classes: '', image : {src: '', alt: ''}})

    useEffect(()=>{
        switch(type){
            case "error":
                setSettings({...settings, classes: styles.error, image: constants.ERROR})
                break;
            case "warning":
                break;
            default:
                setSettings({...settings, image: constants.HINT})
                break;
        }
    }, [])

    return (
        <motion.div className={classNames(styles.hintContainer, settings.classes, className)}>
            <motion.div className={styles.hintTitle}>
                <img src={settings.image.src} alt={settings.image.alt}/>
                <span>{title}</span>
            </motion.div>
            <div className={styles.hintText}  dangerouslySetInnerHTML={{
                __html: text
              }}/>
                {/* {text} */}
            {/* </div> */}
        </motion.div>
    )
}