import { motion } from "framer-motion";
import { useState } from "react";
import utils from "../../../../../utils";
import { SectionTitle } from "../SectionsTitle/SectionTitle";
import styles from "../../editarticle.module.scss";

export const SectionTextArea = ({
  title = "",
  name,
  placeholder,
  changeValue,
  errors = "",
  className = "",
  defaultValue = "",
}) => {
  const [value, setValue] = useState(defaultValue);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    changeValue(name, e.target.value);
  };
  return (
    <motion.div className={className}>
      {title && <SectionTitle title={title} />}
      <motion.div>
        <textarea
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={onChangeHandler}
          value={utils.text.removeTags(value)}
          className={styles.textareAW}
        >{value}</textarea>
        {errors && (
          <motion.div className={styles.error}>
            <span>{errors}</span>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
};
