import { motion } from "framer-motion";
import ReactQuill, { Quill } from "react-quill";
import { useEffect } from "react";
// import ImageResize from "quill-image-resize-module-react";
import constants from "./constants";
import utils from "../../utils";
import useFillHint from "../../hooks/fillHint/fillHint";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

export const SectionEditor = ({
  valueCallback,
  defaultValue = "",
  id = "",
  editMode = true,
}) => {
  const Link = Quill.import("formats/link");
  Link.sanitize = function (url) {
    const noProtocol = constants.PROLOCAL_LIST.reduce((res, protocol) => {
      return !url.startsWith(protocol) && res;
    }, true);
    if (noProtocol) {
      return `http://${url}`;
    }
    return url;
  };
  Quill.register(Link, true);

  const { modules, formats } = constants.EDITOR_SECTION_SETTINGS;
  const { mouseOver: mouseOverBold, mouseLeave: mouseLeaveBold } = useFillHint(
    constants.HINTS.boldText
  );
  const { mouseOver: mouseOverItalic, mouseLeave: mouseLeaveItalic } =
    useFillHint(constants.HINTS.italicText);
  const { mouseOver: mouseOverUnderLine, mouseLeave: mouseLeaveUnderline } =
    useFillHint(constants.HINTS.underlineText);
  const { mouseOver: mouseOverStrike, mouseLeave: mouseLeaveStrike } =
    useFillHint(constants.HINTS.strikeText);
  const { mouseOver: mouseOverOrderedList, mouseLeave: mouseLeaveOrderedList } =
    useFillHint(constants.HINTS.orderedList);
  const { mouseOver: mouseOverBulletList, mouseLeave: mouseLeaveBulletList } =
    useFillHint(constants.HINTS.bulletList);
  const {
    mouseOver: mouseOverReduceIndent,
    mouseLeave: mouseLeaveReduceIndent,
  } = useFillHint(constants.HINTS.reduceIndent);
  const {
    mouseOver: mouseOverIncreaseIndent,
    mouseLeave: mouseLeaveIncreaseIndent,
  } = useFillHint(constants.HINTS.increaseIndent);
  const { mouseOver: mouseOverLink, mouseLeave: mouseLeaveLink } = useFillHint(
    constants.HINTS.link
  );
  const { mouseOver: mouseOverImage, mouseLeave: mouseLeaveImage } =
    useFillHint(constants.HINTS.image);
  const { mouseOver: mouseOverClear, mouseLeave: mouseLeaveClear } =
    useFillHint(constants.HINTS.clear);

  const setContent = (e) => {
    //valueCallback(e);
    valueCallback(
      keepWhitespacesMekanism(e)
        .replaceAll(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
        .replaceAll(/\“/g, '"')
        .replaceAll(/\„/g, '"')
        .replaceAll(/\″/g, '"')
        .replaceAll(/\"/g, '"')
        .replaceAll(/\"/g, '"')
    );
  };

const keepWhitespacesMekanism = (val)=>{
  return val.replaceAll("<p></p>", "<p><br/></p>")
}

  return (
    <motion.div id={id}>
      {editMode ? (
        <motion.div>
          <div id="toolbar">
            <span className="ql-formats">
              <select className="ql-header" defaultValue="4">
                <option value="2">Heading</option>
                <option value="3">Subheading</option>
                <option value="4">Normal</option>
              </select>
            </span>
            <span className="ql-formats">
              <button
                onMouseEnter={mouseOverBold}
                onMouseLeave={mouseLeaveBold}
                className="ql-bold"
              />
              <button
                onMouseEnter={mouseOverItalic}
                onMouseLeave={mouseLeaveItalic}
                className="ql-italic"
              />
              <button
                onMouseEnter={mouseOverUnderLine}
                onMouseLeave={mouseLeaveUnderline}
                className="ql-underline"
              />
              <button
                onMouseEnter={mouseOverStrike}
                onMouseLeave={mouseLeaveStrike}
                className="ql-strike"
              />
            </span>
            <span className="ql-formats">
              <button
                onMouseEnter={mouseOverOrderedList}
                onMouseLeave={mouseLeaveOrderedList}
                className="ql-list"
                value="ordered"
              />
              <button
                onMouseEnter={mouseOverBulletList}
                onMouseLeave={mouseLeaveBulletList}
                className="ql-list"
                value="bullet"
              />
              <button
                onMouseEnter={mouseOverReduceIndent}
                onMouseLeave={mouseLeaveReduceIndent}
                className="ql-indent"
                value="-1"
              />
              <button
                onMouseEnter={mouseOverIncreaseIndent}
                onMouseLeave={mouseLeaveIncreaseIndent}
                className="ql-indent"
                value="+1"
              />
            </span>
            <span className="ql-formats">
              <button
                onMouseEnter={mouseOverLink}
                onMouseLeave={mouseLeaveLink}
                className="ql-link"
              />
            </span>
            <span className="ql-formats">
              <button
                onMouseEnter={mouseOverClear}
                onMouseLeave={mouseLeaveClear}
                className="ql-clean"
              />
            </span>
          </div>
          <ReactQuill
            preserveWhitespace={true}
            theme="snow"
            modules={{
              ...modules,
            }}
            formats={formats}
            onChange={setContent}
            placeholder={constants.EDIT_FIELD_PLACEHOLDER}
            value={utils.post.decodeHtmlCharCodes(keepWhitespacesMekanism(defaultValue))}
            selection={{ start: 0, end: 0 }}
            bounds={`#${id}`}
          ></ReactQuill>
        </motion.div>
      ) : (
        <motion.div
          className={"text-editor_usualT-text-mode_article-wizard"}
          dangerouslySetInnerHTML={{
            __html: `<div class="text-editor-container_usualT-text-mode_article-wizard">${utils.post.decodeHtmlCharCodes(
              keepWhitespacesMekanism(defaultValue)
            )}</div>`,
          }}
        />
      )}
    </motion.div>
  );
};
