import images from '../../../../../../assets/images'
import animation from '../../../../../../constants/animation'

const CONTENT_TYPES = [
  {
    type: 'text',
    icon: { src: images.addTextIcon, alt: 'add paragraph' },
    title: 'Text',
    description: 'Absatz hinzufügen',
  },
  {
    type: 'image',
    icon: { src: images.addImageIcon, alt: 'add image' },
    title: 'Bild',
    description: 'Bild hinzufügen',
  },
  {
    type: 'file',
    icon: { src: images.fileIcon, alt: 'add file' },
    title: 'Datei',
    description: 'Datei hinzufügen',
  },
]

const MEDIAFILES_TYPES = ['image', 'file']

const BLOCK_FUNCTIONAL_BUTTONS = [
  {
    title: 'delete',
    alt: 'Löschen',
    src: images.basketBlack,
  },
  {
    title: 'copy',
    alt: 'Kopieren',
    src: images.copyIcon,
  },
  {
    title: 'arrow-up',
    alt: 'Nachrücken',
    src: images.arrowUp,
  },
  {
    title: 'arrow-down',
    alt: 'Nach unten gehen',
    src: images.arrowDown,
  },
  {
    title: 'settings',
    alt: 'Menü Konfiguration',
    src: images.settingIcon,
  },
]

const START_CONTENT_FIELD_SIGN = 'Inhalt der Publikation hinzufügen'

const CANT_ADD_BLOCK =
  'Ein Block konnte nicht hinzugefügt werden, bitte versuche eine Seite neu zu laden oder verbinde dich mit einem technischen Support'

const ANIMATION_TIME_CONATANT = 0.2

const BLOCK_TYPES_AREA_ANIMATION = {
  offscreen: {
    ...animation.FADEOUT_UP_ANIMATION,
    transition: {
      ...animation.TRANSITION_SETTINGS,
      duration: ANIMATION_TIME_CONATANT,
    },
  },
  onscreen: (i) => {
    return {
      ...animation.FADEIN_DOWN_ANIMATION,
      transition: {
        ...animation.TRANSITION_SETTINGS,
        duration: ANIMATION_TIME_CONATANT,
        delay: i + ANIMATION_TIME_CONATANT,
      },
    }
  },
}
const TITLES_LIST = [
  'Bild ohne Beschriftung über die gesamte Breite des Bereichs',
  'Das Bild mit der Unterschrift ist in zwei Spalten unterteilt',
  'Das Bild wird in voller Breite mit einer Unterschrift versehen',
]

const IMAGE_COMPONENT_TYPES = [
  {
    containerClass: 'BlockImageContainer',
    itemsClass: 'FullSizeItems',
    titleClass: 'invisibleTitleImageContainer',
    title: TITLES_LIST[0],
    isActive: true,
  },
  {
    containerClass: 'FlexImageContainer',
    itemsClass: 'HalfSizeItems',
    titleClass: '',
    title: TITLES_LIST[1],
    isActive: false,
  },
  {
    containerClass: 'BlockImageContainer',
    itemsClass: 'FullSizeItems',
    titleClass: '',
    title: TITLES_LIST[2],
    isActive: false,
  },
]

const CANT_SET_BLOCKER =
  'Ich kann keinen Bloker für eine Aufwärts-Funktionstaste setzen, bitte laden Sie die Seite neu oder verbinden Sie sich mit einem technischen Support'

const FILES_BLOCK_BUTTONS = [
  {
    title: 'Add_title',
    description: 'Datei hinzufügen',
    icon: {
      src: images.plusIcon,
      alt: 'add file',
    },
  },
]

const MINIMUM_PAUSE = 100

const FILE_TITLE_LIMIT_LENGTH = 10000


const FILE_ITEM_BUTTONS = [
  {
    icon: {
      src: images.basketBlack,
      alt: 'delete file'
    },
    description: 'Datei löschen',
    isLink: false
  },
  {
    icon: {
      src: images.downloadFileIcon,
      alt: 'download file'
    },
    description: 'Download der Datei',
    isLink: true
  },
]

const TOO_BIG_FILES = 'Eine oder mehrere Dateien konnten nicht hinzugefügt werden, da sie zu groß waren'

const NOT_ALL_FILES_UPLOADED = 'Die Mediendateien werden gerade heruntergeladen. Bitte warten Sie, bis der Download abgeschlossen ist. Oder laden Sie die Seite neu, wenn Sie den Vorgang unterbrechen möchten.'

const FILE_LOAD_ERROR = 'Fehler beim Laden'

const MEMORY_LIMIT_ERROR = 'The uploaded file exceeds the upload_max_filesize directive in php.ini.'

const MEMORY_LIMIT_DE_ERROR = 'Die Dateigröße überschreitet das Limit für eine Datei auf dem Server'

export default {
  CONTENT_TYPES,
  START_CONTENT_FIELD_SIGN,
  BLOCK_TYPES_AREA_ANIMATION,
  BLOCK_FUNCTIONAL_BUTTONS,
  CANT_ADD_BLOCK,
  IMAGE_COMPONENT_TYPES,
  MEDIAFILES_TYPES,
  CANT_SET_BLOCKER,
  FILES_BLOCK_BUTTONS,
  MINIMUM_PAUSE,
  FILE_TITLE_LIMIT_LENGTH,
  FILE_ITEM_BUTTONS,
  TOO_BIG_FILES,
  NOT_ALL_FILES_UPLOADED,
  FILE_LOAD_ERROR,
  MEMORY_LIMIT_ERROR,
  MEMORY_LIMIT_DE_ERROR
}
