import images from "../../../../assets/images"

const DEFAULT_ICON = {
    src: images.guidline.search,
    alt: 'search'
}

export default {
    DEFAULT_ICON
}

