import classNames from "classnames";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { UserForm } from "../../shared/components/_forms/UserForm/UserForm.jsx";
import { login } from "../../features/auth.slice";
import { userLoginFormSchems } from "../../schemes/index";
import useFillHint from "../../shared/hooks/fillHint/fillHint";
import useRedirects from "../../shared/hooks/redirects/redirects.jsx";
import styles from "./loginpage.module.scss"
import utils from "../../shared/utils/index.js";
import constants from "./constants.js";

export const LoginPage = () => {
  const [succesResult, setSuccesResult] = useState(false);
  const [loadingInProcess, setLoadingInProcess] = useState(false);
  const { toLastVisitedPage, toHomepage } = useRedirects();
  const dispatch = useDispatch();
  const { mouseLeave } = useFillHint();
  const [passwordEyeVisibility, setPasswordEyeVisibility] = useState(false);

  const fields = constants.FIELDS.map((item) =>
    item.name == "password"
      ? {
          ...item,
          eyeVisibility: passwordEyeVisibility,
          setEyeVisibility: setPasswordEyeVisibility,
        }
      : item
  );

  const handleLogin = (formValue) => {
    setLoadingInProcess(true);
    const { username, password, rememberme } = formValue;
    if (rememberme && Array.isArray(rememberme) && rememberme.length) {
      utils.user.setEnterData(username?.trim(), password?.trim());
    }

    dispatch(login({ username, password })).then(
      (data) => {
        console.log(data);
        if (
          data &&
          data.meta &&
          data.meta.requestStatus &&
          data.meta.requestStatus != "rejected"
        ) {
          setSuccesResult(true);
          const result = toLastVisitedPage();
          if (result) return result;
        }
        setLoadingInProcess(false);
      },
      (reason) => {
        console.log(reason);
        setSuccesResult(false);
        setLoadingInProcess(false);
      }
    );
  };

  useEffect(() => {
    mouseLeave();
  }, []);

  if (toHomepage()) {
    return toHomepage();
  }

  return (
    <UserForm
      handler={handleLogin}
      fields={fields}
      buttonName={constants.BUTTON_NAME}
      subtitle={constants.SUBTITLE}
      verificationSheme={userLoginFormSchems}
      succesResult={succesResult}
      className={constants.THEME_CLASS}
      formClass={styles.loginForm}
      loadingInProcess={loadingInProcess}
      titleClasses={styles.titleClass}
      setLoadingInProcess={setLoadingInProcess}
    >
      {constants.LINKS &&
        constants.LINKS.map((link, index) => (
          <motion.div key={index} dangerouslySetInnerHTML={{ __html: link }} />
        ))}
    </UserForm>
  );
};
