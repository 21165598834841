import classNames from "classnames";
import { motion } from "framer-motion";
import { Children } from "react";
import { StandartButton } from "../Buttons/StandartButton/StandartButton";
import constants from "./constants";
import styles from "./carousel.module.scss";


export const Carousel = ({ children, curent, setCurent, isPaused }) => {
  const childrenArray = Children.toArray(children);
  const nextHandler = () => {
    if (!isPaused) {
      setTimeout(() => {
        setCurent(curent + 1);
      }, 500);
    }
    return;
  };

  const privHandler = () => {
    if (!isPaused) {
      setTimeout(() => {
        setCurent(curent - 1);
      }, 500);
    }
  };

  return (
    <>
      <motion.div className={styles.carousel}>
        <motion.div
          initial={{ x: "0%" }}
          animate={{ x: `-${curent * 100}%` }}
          transition={{ vartype: "spring", stiffness: 300, damping: 30 }}
          className={styles.carouselInner}
        >
          {childrenArray.length &&
            childrenArray.map((child, index) => {
              return (
                <motion.div
                  key={`carousel-item-${index}`}
                  className={classNames(
                    styles.item,
                    curent == index ? styles.active : ""
                  )}
                >
                  {child}
                </motion.div>
              );
            })}
        </motion.div>
      </motion.div>
      <motion.div className={styles.buttonContainer}>
        {curent > 0 && curent <= childrenArray.length && (
          <StandartButton
            title={constants.BACK.text}
            imageBefore={constants.BACK}
            mode="grayOutlined"
            onClick={privHandler}
            classes={styles.button}
          />
          // <BaseButton className={classNames(styles.gray, isPaused ? styles.isPaused : '')} onClick={privHandler}>
          //   <img
          //     src={constants.BACK.src}
          //     alt={constants.BACK.alt}
          //     className={styles.marginRight}
          //   />
          //   <span>{constants.BACK.text}</span>
          // </BaseButton>
        )}

        {curent < childrenArray.length - 1 && (
          <StandartButton
            title={
              curent < childrenArray.length - 2
                ? constants.NEXT.text
                : constants.FINAL_STEP.text
            }
            imageAfter={constants.NEXT}
            mode="filled"
            onClick={curent < childrenArray.length ? nextHandler : (e) => {}}
            classes={styles.button}
          />
          // <BaseButton
          // className={isPaused ? styles.isPaused : ''}
          //   onClick={curent < childrenArray.length ? nextHandler : (e) => {}}
          // >
          //   <span>
          //     {curent < childrenArray.length - 2
          //       ? constants.NEXT.text
          //       : constants.FINAL_STEP.text}
          //   </span>
          //   <img
          //     src={constants.NEXT.src}
          //     alt={constants.NEXT.alt}
          //     className={styles.marginLeft}
          //   />
          // </BaseButton>
        )}
      </motion.div>
    </>
  );
};
