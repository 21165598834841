import { motion } from "framer-motion";
import { FeedbackForm } from "../../_forms/FeedbackForm/FeedbackForm";
import { Container } from "../../_layouts/Container/Container";
import { Row } from "../../_layouts/Row/Row";
import { Col } from "../../_layouts/Col/Col";
import useSendQuery from "../../../hooks/sendQuery/sendQuery";
import styles from "./style.module.scss";
import constants from "./conatants";

export const FeedbackFormSection = () => {
  const { sendingResult, submitFunction } = useSendQuery();

  return (
    <Container>
      <Row>
        <Col span={24}>
          <FeedbackForm
            submitFunction={submitFunction}
            buttonText={constants.BUTTON_TEXT}
            sendingResult={sendingResult}
            widthMode={true}
          />
        </Col>
      </Row>
    </Container>
  );
};
