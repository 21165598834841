import images from "../../../../assets/images";

const PROFILE_BUTTON = 'Einstellungen'

const LOGOUT = 'Logout'

const DEFAULT_USER_AVATAR = {
    src: images.guidline.userImage,
    alt: 'user'
}

const PROFILE_PAGE_REDIRECT = '/profile'

export default {
    DEFAULT_USER_AVATAR,
    PROFILE_BUTTON,
    LOGOUT,
    PROFILE_PAGE_REDIRECT
}