import { motion } from "framer-motion";
import classNames from "classnames";
import useFillHint from "../../../../../../../hooks/fillHint/fillHint";
import styles from "../../blockcontentsection.module.scss";

export const FunctionalButton = ({
  title,
  icon,
  description,
  clickHandler = () => {},
}) => {
  const { mouseLeave, mouseOver } = useFillHint(description);

  const clickHandlerItem = (e)=>{
    mouseLeave()
    clickHandler(e);
  }

  return (
    <motion.div
      className={classNames(
        styles.functionalButtons,
        title,
      )}
      onClick={clickHandlerItem}
      onMouseOver={mouseOver}
      onMouseLeave={mouseLeave}
    >
      <img src={icon} alt={description} className={title} />
    </motion.div>
  );
};
