import { useState } from "react";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import { motion } from "framer-motion";
import constants from "./constants";
import {
  TRANSITION_SETTINGS,
  FADEIN_ANIMATION,
  FADEOUT_ANIMATION,
} from "../../../constants/animation";
import styles from "./collapse.module.scss";


const idTemp = uuid().slice(0, 8);

export const Collapse = ({
  children,
  title,
  className,
  animationDelay = 0,
  clickExtraHandler = () => {},
  id = idTemp,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const transition = {
    ...TRANSITION_SETTINGS,
    delay: animationDelay,
    ease: "linear",
    duration: 0.8,
  };
  const cardVariants = {
    offscreen: FADEOUT_ANIMATION,
    onscreen: {
      ...FADEIN_ANIMATION,
      transition: transition,
    },
  };

  const clickHandler = () => {
    setIsVisible(!isVisible);
    clickExtraHandler();
  };

  return (
    <motion.div
      id={id}
      collapsed={String(isVisible)}
      className={className}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      // onClick={clickHandler}
    >
      <motion.div
        className={classNames("openCollapse", styles.collapseTitle)}
        role="collapse-title"
        variants={cardVariants}
        onClick={clickHandler}
      >
        <span
          dangerouslySetInnerHTML={{
            // __html: utils.post.germanToUppercase(title),
            __html: title,
          }}
        />
      </motion.div>
      <motion.div
        animate={isVisible ? constants.SHOW_COLLAPSE : constants.HIDE_COLLAPSE}
        style={{ overflowY: "hidden" }}
      >
        <motion.div className={styles.contentContainer}>{children}</motion.div>
      </motion.div>
    </motion.div>
  );
};
