import images from "../../../../assets/images"

const EXUMPLE_IMAGE_TEXT = 'Beispiel für eine Bildbeschreibung'

const EXUMPLE_IMAGE = {
    src: images.imageExumple,
    alt: 'image'
}

const SMALL_EXUMLE_IMAGE = {
    src: images.smallImageExumple,
    alt: 'image'
}

export default {
  EXUMPLE_IMAGE_TEXT,
  EXUMPLE_IMAGE,
  SMALL_EXUMLE_IMAGE
}
