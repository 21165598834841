import  { motion} from "framer-motion"
import classNames from "classnames"
import styles from "./style.module.scss"

export const SendingResult = ({sendingResult, className})=>{
    return sendingResult.message != "" && (
        <motion.div
          className={classNames(
            "input-error",
            styles.input_error,
            !sendingResult.error ? "seccess" : ""
          , className)}
        >
          <span>{sendingResult.message}</span>
        </motion.div>
      )
}