import { configureStore } from '@reduxjs/toolkit'
import userResucer from '../features/auth.slice'
import messageReducer from "../features/message"
import popup from "../features/popup.window"
import hint from "../features/popup.hint"
import asideMenu from "../features/asidemenu.status"

export const store = configureStore({
  reducer: {
    user: userResucer,
    message: messageReducer,
    popup: popup,
    hint: hint,
    asideMenu: asideMenu
  },
})

