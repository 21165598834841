import global from "../../../../constants/global"
import images from "../../../../assets/images"

export const WIZARD_TITLE = 'Artikel-Assistent'

export const TURNING_ERROR =
  'Das Feld *** enthält einen Fehler'

export const SAVE_DRAT = 'Entwurf, gespeichert im <a href="/profile">Benutzerprofil</a>'

export const STEPS = [
  {
    title: 'Schritt 1',
    progress: 33.3,
    done: false,
  },
  {
    title: 'Schritt 2',
    progress: 33.3,
    done: false,
  },
  {
    title: 'Schritt 3',
    progress: 33.4,
    done: false,
  },
]

export const HINTS = [
  {
    title: 'Informationen',
    text:
      'Wählen Sie eine Kategorie für Ihren Artikel. Wenn keine passende Kategorie vorhanden ist, klicken Sie auf die Schaltfläche „Neue Kategorie“, um eine neue Kategorie zu erstellen.',
  },
  {
    title: 'Informationen',
    text:
      'Wählen Sie eine Unterkategorie für Ihren Artikel. Wenn keine passende Unterkategorie vorhanden ist, klicken Sie auf die Schaltfläche „Neue Unterkategorie“, um eine neue Unterkategorie zu erstellen.',
  },
  {
    title: 'Informationen',
    text:
      'Geben Sie Ihrem Artikel einen Titel. Um die Suche zu vereinfachen, wählen Sie einen Titel, der den Inhalt des Artikels möglichst genau beschreibt.',
  },
  {
    title: 'Informationen',
    text:
      'Laden Sie ein Bild hoch, das den Inhalt Ihres Artikels repräsentiert. Verwenden Sie kein Bild, das größer als 2 MB ist.',
  },
  {
    title: 'Informationen',
    text:
      'Schreiben Sie eine kurze Vorschau für Ihren Artiekel. Bei der Vorschau handelt es sich um einen Text aus mehreren Sätzen, der den Leser mit dem Material bekannt macht und ihn motiviert, die Seite mit der Vollversion des Artikels aufzurufen.',
  },
  {
    title: 'Informationen',
    text:
      'Erstellen und bearbeiten Sie den Inhalt Ihres Artikels. Im Feld unten können Sie Text, Bilder, Links und andere Informationen hinzufügen.<br><br>Um eine leichte Lektüre zu ermöglichen, denken Sie daran, den Text der Veröffentlichung in Absätze zu unterteilen, die durch einen speziellen Streifen getrennt werden. Um einen solchen Absatz hinzuzufügen, fügen Sie am Anfang eine Kopfzeile mit dem Stil „Kopfzeile 2“ hinzu (die Kopfzeile kann leer sein). Mit der Formatierung „Header 3“ können reguläre Header hinzugefügt werden.<br><br>Vor dem Hinzufügen von Bildern empfiehlt es sich, diese zu „komprimieren“, um das Laden zu beschleunigen.',
  },
  {
    title: 'Informationen',
    text:
      'Fügen Sie Links zu zusätzlichen Quellen hinzu, die für die Leser von Interesse sein könnten.',
  },
]

export const ARTICLE_TEMPLATE = {
  categories: [],
  title: {
    rendered: '',
  },
  slug: '',
  content: {
    rendered: [],
  },
  excerpt: {
    rendered: '',
  },
  acf: {
    main_image: undefined,
  },
  status: 'draft',
}

export const NEW_CATEGORY_TEMPLATE = {
  parent: 0,
  name: '',
  slug: '',
}



export const ADDING_POST_STATUSES = {
  notLoaded: 0,
  inprosses: 1,
  loaded: 2,
}

const DEFAULT_VALUES = {
  category: 0,
  subcategory: 0,
  title: '',
  image: '',
  content: '',
  excerpt: '',
  links: '',
  status: 'draft',
  mediafiles: [],
  progress: 0,
}

const SECTION_TITLES = [
  '1. Kategorie auswählen',
  '2. Unterkategorie auswählen',
  '1. Titel der Veröffentlichung',
  '2. URL-Adresse',
  '2. Hauptbild für die Vorschau hochladen',
  '3. Text zur Vorschau hinzufügen',
  '1. Inhalt der Veröffentlichung hinzufügen',
  '2. Externe Links hinzufügen',
]

const IMAGE_BTN_TEXT = 'Bild hochladen'


const TITLE_LINK_AREA = '2. Externe Links hinzufügen'

const ERROR_HINT_TITLE = 'Fehler'

const SAVE_DRAFT_BUTTON_TEXT = 'Entwurf speichern'

const LOADING_DRAFT = "Der Entwurf wird geladen..."

const INCORRECT_SYMBOLS = 'Der Beschreibungstext und/oder Titel in einer der hochgeladenen Dateien enthält falsche Zeichen'

const CANT_SAVE_DATA = 'Die hinzugefügten Daten können nicht gespeichert werden. Wenden Sie sich an den Administrator.'

const ALLOW_PUBLISH = global.EDITOR_ROLES[0]

const PUBLISH_STATUSES = 'publish'

const PENDING_STATUSES = 'pending'

const DEFAULT_LOAD_IMAGE = images.guidline.load_images

export default {
  WIZARD_TITLE,
  TURNING_ERROR,
  SAVE_DRAT,
  STEPS,
  HINTS,
  ARTICLE_TEMPLATE,
  NEW_CATEGORY_TEMPLATE,
  ADDING_POST_STATUSES,
  DEFAULT_VALUES,
  SECTION_TITLES,
  IMAGE_BTN_TEXT,
  TITLE_LINK_AREA,
  ERROR_HINT_TITLE,
  SAVE_DRAFT_BUTTON_TEXT,
  LOADING_DRAFT,
  CANT_SAVE_DATA,
  INCORRECT_SYMBOLS,
  ALLOW_PUBLISH,
  PUBLISH_STATUSES,
  PENDING_STATUSES,
  DEFAULT_LOAD_IMAGE
}
