import { motion } from "framer-motion";
import PropTypes from "prop-types";
import classNames from "classnames";
import constants from "./constants";
import styles from "./infocard.module.scss";

const InfoCard = ({ text, classes, infoIcon }) => {
  return (
    <motion.div
      className={classNames(classes, styles.container)}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      {infoIcon && (
        <motion.div
          className={styles.descriptorContainer}
          variants={constants.CARD_VARIANTS}
        >
          <img src={constants.INFO_ICON.src} alt={constants.INFO_ICON.alt} />
          {constants.INFORMATION}
        </motion.div>
      )}
      <motion.div>{text}</motion.div>
    </motion.div>
  );
};

InfoCard.propTypes = {
  text: PropTypes.string,
  infoIcon: PropTypes.bool,
};

InfoCard.defaultProps = {
  text: "",
  infoIcon: false,
};

export { InfoCard };
