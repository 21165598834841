import { motion } from "framer-motion";
import { useState } from "react";
import { AddCommentForm } from "../../_forms/AddCommentForm/AddCommentForm";
import { Container } from "../../_layouts/Container/Container";
import { Comment } from "./components/Сomment/Comment";
import api from "../../../../api/server.api";
import crud from "../../../../api/crud";
import useSendQuery from "../../../hooks/sendQuery/sendQuery";
import utils from "../../../utils";
import constants from "./constants";
import styles from "./commentssection.module.scss";

export const CommentsSection = ({ postId, comments, setComments }) => {
  const { sendingResult, submitFunction } = useSendQuery();
  const [generalError, setGeneralError] = useState("");
  const [listLoading, setListLoading] = useState(false);

  const updateCommentState = (comment) => {
    const listUpdate = (currentCommentList) => {
      return currentCommentList.map((commentItem) => {
        if (commentItem.id == comment.id) {
          return comment;
        }
        return {
          ...commentItem,
          children: listUpdate(commentItem.children),
        };
      });
    };
    if (comment.id) {
      setComments(listUpdate(comments));
    }
  };

  const addNewCommentInCommentState = (comment, setLoadingFuntion = setListLoading) => {
    setLoadingFuntion(true)
    const listUpdate = (currentCommentList) => {
      return currentCommentList.map((commentItem) => {
        if (commentItem.id == comment.parent) {
          return {
            ...commentItem,
            children: [comment].concat(commentItem.children),
          };
        }
        return {
          ...commentItem,
          children: listUpdate(commentItem.children),
        };
      });
    };
    if (comment.id) {
      if (comment.parent == 0) {
        setComments([comment].concat(comments));
      } else {
        setComments(listUpdate(comments));
      }
    }
    setLoadingFuntion(false)
  };

  const addNewComment = (values, { resetForm }, setLoadingFuntion = null ) => {
    if(setLoadingFuntion){
      setLoadingFuntion(true)
    }
    if (values.content) {
      submitFunction(
        { ...values, content: utils.text.removeTags(values.content) },
        api.standart,
        crud.standart.COMMENTS,
        (result) => {
          addNewCommentInCommentState(result, setLoadingFuntion ? setLoadingFuntion : setListLoading);
          resetForm({ values: { post: values.post, content: "" } });
        },
        (error) => {
          console.log(error);
          resetForm({ values: values });
          if(setLoadingFuntion){
            setLoadingFuntion(false)
          }
        },
        {
          success: constants.COMMENT_IS_ADDED,
          error: constants.COMMENT_COULD_NOT_BE_ADDED,
        }
      );
    }
  };

  const commentsOutputs = (commentsArray) =>
    commentsArray.map((comment, index) => (
      <Comment
        key={index}
        comment={comment}
        updateComment={updateCommentState}
        addComment={addNewComment}
        deleteComment={deleteCommentFormState}
      >
        {commentsOutputs(comment.children)}
      </Comment>
    ));

  const deleteCommentFormState = (comment, setLoadingFuntion) => {
    setLoadingFuntion(true)
    const listUpdate = (currentCommentList) => {
      return currentCommentList.reduce((res, commentItem) => {
        if (commentItem.id != comment.id) {
          res.push({...commentItem, children: listUpdate(commentItem.children)});
        }
        return res;
      }, []);
    };
    if (comment.id) {
      setComments(listUpdate(comments));
    }
    setLoadingFuntion(false)
  };

  return (
    <Container className="no-print">
      <motion.div className={styles.commentPadding}>
        <AddCommentForm
          postId={postId}
          submitFunction={addNewComment}
          sendingResult={sendingResult}
          parent={0}
          isLoading={listLoading}
        />
        {generalError ? (
          <motion.div className={""}>{generalError}</motion.div>
        ) : listLoading ? (
          <motion.div className={styles.loadingCommentList}>
            {constants.LOADING_TEXT}
          </motion.div>
        ) : (
          <motion.div className={styles.commentList}>
            {commentsOutputs(comments)}
          </motion.div>
        )}
      </motion.div>
    </Container>
  );
};
