import localStorageVariables from '../../constants/local.storage'



const setLastPage = (lastVisitedPage) => {
  if (lastVisitedPage) {
    localStorage.setItem(localStorageVariables.LAST_VISITED_PAGE, lastVisitedPage)
  }
}

const resetLastPage = () => {
  localStorage.removeItem(localStorageVariables.LAST_VISITED_PAGE)
}

const getLastPage = ()=>{
  return localStorage.getItem(localStorageVariables.LAST_VISITED_PAGE)
}

const setToken = (token) => {
  if (token) {
    localStorage.setItem(localStorageVariables.USER_TOKEN_VALIABLE, token)
  }
}

const setAvatar = (avatar) => {
  if (avatar) {
    localStorage.setItem(localStorageVariables.USER_AVATAR_VARIABLE,  JSON.stringify(avatar))
  }
}

const getToken = () => {
  return localStorage.getItem(localStorageVariables.USER_TOKEN_VALIABLE)
}

const getAvatar = () => {
  try {
    return JSON.parse(localStorage.getItem(localStorageVariables.USER_AVATAR_VARIABLE))
  }catch(e){
    console.log(e)
    return localStorage.getItem(localStorageVariables.USER_AVATAR_VARIABLE)  
  }
}



const resetToken = () => {
  localStorage.removeItem(localStorageVariables.USER_TOKEN_VALIABLE)
}

const setEnterData = (login, password) => {
  localStorage.setItem(localStorageVariables.USER_LOGIN_VARIABLE, login)
  localStorage.setItem(localStorageVariables.USER_PASSWORD_VARIABLE, password)
}

const getSavedEmail = () => {
  return localStorage.getItem(localStorageVariables.USER_LOGIN_VARIABLE)
}

const getSavedPassword = () => {
  return localStorage.getItem(localStorageVariables.USER_PASSWORD_VARIABLE)
}

const headerWithAuth = (headers) => {
  const token = getToken()
  return token
    ? {
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...headers,
        },
      }
    : null
}

const setID = (id) => {
  if (id) {
    localStorage.setItem(localStorageVariables.USER_ID_VARIABLE, id)
  }
}

const resetID = () => {
  localStorage.removeItem(localStorageVariables.USER_ID_VARIABLE)
}

const resetAvatar = () => {
  localStorage.removeItem(localStorageVariables.USER_AVATAR_VARIABLE)
}

const getUserID = () => {
  return localStorage.getItem(localStorageVariables.USER_ID_VARIABLE)
}

const setUserData = (user) => {
  if (user.user_email) {
    localStorage.setItem(
      localStorageVariables.CURRENT_USER,
      JSON.stringify(user),
    )
  }
}

const getUserData = () => {
  return JSON.parse(localStorage.getItem(localStorageVariables.CURRENT_USER))
}
const resetUserData = ()=>{
  localStorage.removeItem(localStorageVariables.CURRENT_USER)
}

const resetAllData = ()=>{ 
  resetAvatar();
  resetID();
  resetToken();
  resetUserData();
}

export default {
  setToken,
  getToken,
  resetToken,
  setEnterData,
  getSavedEmail,
  getSavedPassword,
  headerWithAuth,
  setID,
  resetID,
  getUserID,
  setAvatar,
  resetAvatar,
  getAvatar,
  setUserData,
  getUserData,
  resetUserData,
  resetAllData,
  setLastPage,
  resetLastPage,
  getLastPage
}
