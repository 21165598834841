import classNames from "classnames";
import styles from "../preloader.module.scss";

export const LoaderIcon = () => {
  return (
    <div className={classNames(styles.Loader, styles.Loader - 1)}>
      <div className={styles.LoaderOutter}></div>
      <div className={styles.LoaderInner}></div>
    </div>
  );
};
