import apiQueryUtils from './apiQueryUtils'
import api from '../../api/server.api'
import crud from '../../api/crud'
import constants from '../../constants/global'

const addMediafile = (
  fileData,
  callback,
  errorHandler = apiQueryUtils.defaultErrorHandler,
) => {
  if (fileData.src) {
    let formData = new FormData()
    formData.append('file', fileData.src)
    formData.append('title', `mediafile-${fileData.blockId}`)
    formData.append('alt_text', fileData.alt)
    apiQueryUtils.postData(
      api.standart,
      crud.standart.MEDIA,
      (data) => {
        callback(data)
      },
      formData,
      {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': `attachment; filename=${fileData.src.name}`,
      },
      (e) => {
        console.log(e);
        errorHandler(e, fileData.blockId)
      },
    )
  } else {
    errorHandler(constants.INCORRECT_FORMAT, fileData.blockId)
  }
}

const deleteMedifile = (
  id,
  callback,
  errorHandler = apiQueryUtils.defaultErrorHandler,
) => {
  if (id) {
    apiQueryUtils.deleteData(
      api.standart,
      `${crud.standart.MEDIA}/${id}?force=true`,
      callback,
      {},
      errorHandler,
    )
  }
}

const getTypeImage = (currentFileType, filesTypes) => {
  return Object.keys(filesTypes).reduce((res, item) => {
    if (filesTypes[item].includes(currentFileType)) {
      return constants.FILE_TYPES_IMAGES[item]
    }
    return res
  }, constants.FILE_TYPES_IMAGES['unknownType'])
}

const fileNameProccess = (name, nameLimitLength = 0) => {
  if (name.length < nameLimitLength) {
    return name
  }
  const devidedName = name.split('.')
  if (devidedName.length > 1) {
    const typeSign = devidedName[devidedName.length - 1]
    return devidedName.reduce((res, item, index) => {
      if (index < devidedName.length - 1 && res < nameLimitLength) {
        if (`${res}${item}${typeSign}`.length > nameLimitLength) {
          return `${res}${item.substring(
            0,
            nameLimitLength - (res.length + typeSign.length + 3),
          )}...${typeSign}`
        }
        res = `${res}${item}`
      }
      return res
    }, '')
  }
  return `${name.substring(0, nameLimitLength - 3)}...`
}

const createBlockObject = (data) => {
  const {
    part,
    index,
    inEditCondition,
    selector = false,
    type,
    contentFormer = false,
    extraFields = () => {
      return {}
    },
  } = data
  const sourceNode = selector ? part.querySelector(selector) : false
  if (sourceNode || sourceNode === false) {
    return {
      index: `block-${index + 1}`,
      inEdit: inEditCondition,
      type: type,
      content: contentFormer ? contentFormer(sourceNode) : part.innerHTML,
      ...extraFields(sourceNode),
    }
  }
  return false
}

const checkImageLoading = (address, emptyImage, callback, errorFunction = ()=>{} )=>{
  // console.log(typeof address)
  if(typeof address == "string"){
    var img = new Image();
    img.src = address;

    const functionOnSuccess = () => {
      // console.log("has a picture");
      callback(address);
    };

    const errorFunction = (error) => {
      console.log("noImage");
      console.log(error)
      callback(emptyImage);
    };
    img.onload = functionOnSuccess;
    img.onerror = errorFunction;
  }
}

export default {
  addMediafile,
  getTypeImage,
  fileNameProccess,
  deleteMedifile,
  createBlockObject,
  checkImageLoading
}
